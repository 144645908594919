.plain-button {
  background:transparent;
  border:0px solid transparent;
  padding:0;
  
}

/////////////////////////////////// 
/* Tooltips */
$TIPPYCONTENTBG:#444;
$TIPPYBG:#444;
.dark-theme .tippy-arrow,
.dark-theme .tippy-content {
  background:$TIPPYCONTENTBG !important;
  border-color:$TIPPYCONTENTBG !important;
}
.tippy-tooltip {
  background:red; //$TIPPYBG !important;
}
.laydown-popover-detail{
      margin: 10px;
      padding:0;
  h2 {
      padding:0;
      text-align:left;
    color:$CORPORATE_WHITE;
    font-size:16px;
    margin:0;
    line-height: 1.2em;
  }
  h4 {
      text-align:left;
      padding:0;
    color:$CORPORATE_GREY_LIGHT;
    font-size:16px;
    margin:0;
    line-height: 1.1em;
  }
  .entry-tooltip-table {
    margin-top:10px;
    tr {
      text-align:left;
      td { 
        text-align:left;
        font-size:16px !important;
        color:$CORPORATE_GREY_LIGHT;
        b {
          color:$CORPORATE_WHITE;
        }


      }
      td.left {
      }
      td.right {
      }
    }
  }
}



.dark-theme .tippy-arrow,
.dark-theme .tippy-content,
.tippy-content {
  background:$TIPPYCONTENTBG !important;
  border-color:$TIPPYCONTENTBG !important;
}
.tippy-tooltip {
  background:$TIPPYARROWBG !important;
}

.tippy-tooltip {
    border-radius: 7px;
    background:$TIPPYBG;
}
.tippy-arrow {
  //border-left:8px solid $TIPPYBG !important;
  //border-top:8px solid $TIPPYBG !important;
  //border-right:8px solid $TIPPYBG !important;
  //border-bottom:8px solid $TIPPYBG !important;
  //display: none !important;
  //visibility: hidden !important;
  //opacity: 0 !important;
  top: 6px !important;
}
.tippy-content {
  padding:5px;
  p {
    font-size: 14px;
    margin:0 !important;
  }
  .static-tooltip {
    word-break: break-word;
    margin-bottom: 0;
  }
}

.tooltip {
  z-index:90900;
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.4;
  opacity: 0;
  filter: alpha(opacity=0);
}
.tooltip.in {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}
.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}
.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}
.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}
.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background-color: $TIPPYBG;
  border-radius: 4px;
  word-wrap: break-word !important;
  
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: $TIPPYARROWBG;
}
.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  left: 5px;
  border-width: 5px 5px 0;
  border-top-color: $TIPPYARROWBG;
}
.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  right: 5px;
  border-width: 5px 5px 0;
  border-top-color: $TIPPYARROWBG;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: $TIPPYARROWBG;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: $TIPPYARROWBG;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: $TIPPYARROWBG;
}
.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  left: 5px;
  border-width: 0 5px 5px;
  border-bottom-color: $TIPPYARROWBG;
}
.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  right: 5px;
  border-width: 0 5px 5px;
  border-bottom-color: $TIPPYARROWBG;
}