.content-view_animate.ng-enter, .content-view_animate.ng-leave {
  // transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.5s;
  // transition:all ease-in 1s;
  transition:all .5s;
  display:block;
  width:100%;
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
}

.content-view_animate.ng-enter {
}
.content-view_animate.ng-enter.ng-enter-active {
  @include fadeInUp();
}

.content-view_animate.ng-leave {
  @include fadeOutDown();
}
.view-animate.ng-leave.ng-leave-active {
}
.animate-if-wrapper {
  position: relative;
  min-height:400px;
}
.animate-if {
  background:url('images/patterns/hexellence/hexellence/hexellence.png');;
  border:1px solid black;
  padding:10px;
  min-height:400px;
}

.animate-if.ng-enter, .animate-if.ng-leave {
  transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
}

.animate-if.ng-enter,
.animate-if.ng-leave.ng-leave-active {
  // opacity:0;
  
  // @include slideOutDown();
  @include fadeOutDown();

}

.animate-if.ng-leave,
.animate-if.ng-enter.ng-enter-active {
  @include slideInUp();
  // opacity:1;/
}






// .topnav-view_animate > nav.ng-enter, 
// .topnav-view_animate > nav.ng-leave {
//   // transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.5s;
//   transition:all 3s;
//   display:block;
//   width:100%;
//   position:absolute;
//   top:0;
//   left:0;
//   right:0;
//   bottom:0;
// }


.topnav-view_animate.ng-enter {
}
.topnav-view_animate.ng-enter.ng-enter-active {
  @include fadeInDown();
}

.topnav-view_animate.ng-leave {
  // @include fadeOutDown();
}
.topnav-view_animate.ng-leave.ng-leave-active {
}



.navbar-default.fixed-top.ng-hide-add {
  transform: rotateZ(0);
  transform-origin: right;
  transition: all 0.5s ease-in-out;
}

.navbar-default.fixed-top.ng-hide-add.ng-hide-add-active {
  transform: rotateZ(-135deg);
}

// /* when hiding the picture */
// .ng-hide-add         { animation:0.5s lightSpeedOut ease; }

// /* when showing the picture */
// .ng-hide-remove      { animation:0.5s flipInX ease; }

/* ANIMATIONS (FROM ANIMATE.CSS) ======================== */
/* flip in */
@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  100% {
    transform: perspective(400px);
    transform: perspective(400px);
    transform: perspective(400px);
  }
}

/* light speed out */
@keyframes lightSpeedOut {
  0% {
    opacity: 1;
  }

  100% {
    transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}

@keyframes lightSpeedOut {
  0% {
    opacity: 1;
  }

  100% {
    transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}

// .animated-class{
//   &.ng-enter {
//     -webkit-animation: bounceIn 1s;
//     -moz-animation: bounceIn 1s;
//     -ms-animation: bounceIn 1s;
//     animation: bounceIn 1s;
//   }
//   &.ng-leave {
//     -webkit-animation: lightSpeedOut 1s;
//     -moz-animation: lightSpeedOut 1s;
//     -ms-animation: lightSpeedOut 1s;
//     animation: lightSpeedOut 1s;
//   }
// }










/* start 'enter' transition on main view */
.engineengine.ng-enter {
    /* transition on enter for .5s */
    transition: .5s;
    /* start with opacity 0 (invisible) */
    opacity: 0;
}

/* end 'enter' transition on .engineengine view */
.engineengine.ng-enter-active {
    /* end with opacity 1 (fade in) */
    opacity: 1;
}


/* start 'enter' transition on main view */
#page.ng-leave {
    /* transition on enter for .5s */
    transition: .5s;
    // transform:scale(1);
    /* start with opacity 0 (invisible) */
    opacity: 1;
}

/* end 'enter' transition on .engineengine view */
#page.ng-leave-active {
    /* end with opacity 1 (fade in) */
    // transform:scale(.7);
    opacity: 1;
}


/* start 'enter' transition on main view */
#page.ng-enter {
    /* transition on enter for .5s */
    transition: .5s;
    /* start with opacity 0 (invisible) */
    // transform:scale(.7);
    opacity: 0;
}

/* end 'enter' transition on .engineengine view */
#page.ng-enter-active {
    /* end with opacity 1 (fade in) */
    // transform:scale(1);
    opacity: 1;
}

// https://ngmilk.rocks/2015/12/18/animate-elements-when-a-model-changes-in-angularjs/



// main {
//     /* start 'enter' transition */
//     &.ng-enter {
//         /* transition on enter for .5s */
//         transition: 3.5s;

//         /* start with opacity 0 (invisible) */
//         opacity: 0;
//     }

//     /* end 'enter' transition */
//     &.ng-enter-active {
//         /* end with opacity 1 (fade in) */
//         opacity: 1;
//     }
// }

// #page { //very important!
//   position:relative;
//   background:red;
//   min-height:500px;
//     transition: all 1s; 
// }
// #page.ng-enter {
//   // (animate any div in your child ui-views)
//   #main {
//     @include fadeInUp;

    
//     // translate:-@sidebarSize,0,0); 
//   }
//    #topBar { 
//     // .translate3d(0,-65px,0); 
//   }
// }
// #mainView.ng-enter-active {
//    // #sidebar-wrapper{ .translate3d(0,0,0); }
//    // #topBar { .translate3d(0,0,0); }
// }

.tab-content {
}

.swap-view {
    animation: 2s departing;
    -webkit-animation: 2s departing;
}



.tab-content {
  &.ng-enter {

  }
  &.ng-enter-active {

  }
  &.ng-leave {

  }
  &.ng-leave-active {

  }
}

/* start 'enter' transition on main view */
.engineengine.ng-enter {
    /* transition on enter for .5s */
    transition: .5s;
    /* start with opacity 0 (invisible) */
    opacity: 0;
}

/* end 'enter' transition on .engineengine view */
.engineengine.ng-enter-active {
    /* end with opacity 1 (fade in) */
    opacity: 1;
}


/* start 'enter' transition on main view */
#page.ng-leave {
    /* transition on enter for .5s */
    transition: .5s;
    // transform:scale(1);
    /* start with opacity 0 (invisible) */
    opacity: 1;
}

/* end 'enter' transition on .engineengine view */
#page.ng-leave-active {
    /* end with opacity 1 (fade in) */
    // transform:scale(.7);
    opacity: 1;
}



> div {
  width:100%; 
  background:darkgreen; 
  min-height:100px; 
  padding:5px;  
  .image-lock {
    display:none;
  }
  > img {
    background:red;
    button.warning {
      background:red;
    }
  }
}

@keyframes departing {
  0% {
    opacity:1;
    display:block;
  }
  50% {
    opacity:0;
    display: none;
  }
}

@keyframes arriving {
  0% {
    display:none;
  }
  50% {
    opacity:0;
  }
  51% {
    display:block;
  }
  100% {
    opacity:1;
  }
}


@keyframes grow {
  0%,
  100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
}