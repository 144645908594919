@charset "utf-8";
@function icon($character){
    @return unquote('\"') + unquote(str-insert($character,'\\', 1)) + unquote('\"');
}
$EURO:'f153';
$DOLLAR:'f155';
$POUND:'f154';
$YEN:'f157';
$BASE_GREY:#444;
$BASE_HEADER_COLOR:#444;
$BASE_HEADER_BG:#444;
$BASE_ACTION_BG:#444;
$BASE_BLACK:#444;
$BASE_WHITE:#444;
$POPOVER_BG:#444;
$DEFAULT_WHITE:#fefefe;
$CORPORATE_GREY:#ddd;
$LIGHTER_GREY:#e3e7eb;

$BASE_CURRENCY_CODE:icon('f157') !important;

$TIMELINE_MARKER_COLOR: #44464466;

$BLACK:black;
$WHITE:white;

$SEARCH_CHOICE_CLOSE_BG:red;
$SEARCH_CHOICE_CLOSE_COLOR:green;
$SEARCH_CHOICE_CLOSE_HOVER_BG:red;
$SEARCH_CHOICE_CLOSE_HOVER_COLOR:green;

$CHOSEN_DROP_RESULT_SELECTED_COLOR:green;


$DEFAULT_FONT:'montserrat';
// $DEFAULT_FONT:'Arial';

$TABLE_PAGINATION_ACTIVE_BG:$CORPORATE_GREY;
$TABLE_PAGINATION_ACTIVE_COLOR:$DEFAULT_WHITE;

$NGTABLECOUNTS_BTN_BG:$CORPORATE_GREY;
$NGTABLECOUNTS_BTN_COLOR:$DEFAULT_WHITE;
$NGTABLECOUNTS_BTN_ACTIVE_BG:$LIGHTER_GREY;
$NGTABLECOUNTS_BTN_ACTIVE_COLOR:$DEFAULT_WHITE;



 .sum-of-key {

            }
            .sum-of-key.currency {
                position: relative;
                box-sizing: border-box;
            }
            .sum-of-key.currency::before {
                position: absolute;
                z-index: 99;
                content: "\f155";
                width: 32px;
                left: 0px;
                min-height: 32px;
                height: 35px;
                padding: 4px 10px 4px 13px;
                border-radius: 16px 0 0 16px;
                font-size: 12px;
                font-family: 'Font Awesome 5 Pro';
                border-right: 1px solid;
            }

.form-control {
    font-size:13px !important;
}
.lost-wrench {
    line-height: 1em;
    display: block;
    font-size: 13px;
    padding-top: 10px;
}

.source-of-funds > div > b {
    font-size: 12px;
    line-height: 1em;
    display: block;
    height: 27px;
}

 .sum-of-key {
    margin-bottom: 10px;
    width: 100%;
    text-align: right;
    display: block;
    color: #3d3a36;
    padding-bottom: 10px;
    padding-right: 10px;
    font-size:13px;
}

// .sum-of-key-currency {
//     font-size:0;
//     position: relative;
//     box-sizing: border-box;
//     &::before {
//         z-index: 99;
//         content: "\f155";
//         width: 0px;
//         left: 0px;
//         min-height: 32px;
//         height: 35px;
//         padding: 4px 1px 4px 13px;
//         border-radius: 16px 0 0 16px;
//         font-size: 12px;
//         font-family: 'Font Awesome 5 Pro';
//         color:$JJDARKGREY;
//         // $PIRITEGOLD:#8f7e66;
//     }
// }


// <!-- ENGINE MODULE | RoiReport -->
// @import '../../modules/roi_report/RoiReport.scss';


.duplicator-row-tl {
    padding-top:10px;
    &:nth-child(odd){
        background:white;

    }
    &:nth-child(even){
        background:#8483801f;
        // background:red;
    }
    .form-section.light {
        background: transparent !important;
    }
}

.overspend-style {
    color:red !important;
}


.repeating-form-field {
    opacity: 1;
    &.ng-enter {
        -webkit-transition: 1s;
        transition: 2s;
        opacity: 0;
    }
    &.ng-enter-stagger {
      transition-delay: 0.7s;
      animation-delay: 0.8s;
    }
    &.ng-enter-active {
        opacity: 1;
    }
}

.slide {
  overflow: hidden;
  transition: 0.3s;
  height: 30px;
}

.slide.ng-enter {
  height: 0;
}

.slide.ng-enter.ng-enter-active {
  height: 30px;
}

.sub-repeating-form-field {
    opacity: 1;
    &.ng-enter {
        -webkit-transition: 1s;
        transition: 2s;
        opacity: 0;
    }
    &.ng-enter-stagger {
      transition-delay: 1.7s;
      animation-delay: 0.8s;
    }
    &.ng-enter-active {
        opacity: 1;
    }
}

