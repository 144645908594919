
.ng-table-filters [data-title-text="Title"] > div {
  position: relative;
  &::after {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    color: $CORPORATE_BLACK;
    font-size: 18px;
    content: '\f002 ';
    height: 20px;
    width: 20px;
    position: absolute;
    right: 10px;
    top: 3px;
  }
}

.table-striped.tab-gantt-table {
  tr th {
    padding-left:0;
    padding-right:0;
  }
}

td .laydown-activity-pulse.inventory-cell {
  position:relative;
  max-width: 35px;
  float:right;
  padding: 0 14px;
  display:block;
}

td:last-child .laydown-activity-pulse.inventory-cell {
    float:left;
}

.im-popover-detail .ng-table-pagination li:last-of-type a{
          margin-right: 0px !important;
}


.laydown-activity-pulse {
    display: block;
    width:100%;
    height:25px;
    margin:0;
    border-radius:0 0 0 0;
    background:$pulse_background;
    // &.committed { DEPRICATED 
    //     background:$pulse-committed_background;
    // // #e89c20;
    // }
    // &.planned { DEPRICATED 
    //     background:$pulse-planned_background;
    // // :white;
    // }
    &.pulse-start{
        border-radius:16px 0 0 16px;
    }
    &.pulse-end{
        border-radius:0 16px 16px 0;
    }
    &.pulse-start.pulse-end{
        border-radius:16px 16px 16px 16px;
    }
  &:hover {
    text-decoration: none;
  }
}
// .gantt-datatable-row,
.gantt-chart-row {
    tbody tr td {
      &:nth-of-type(even){
        background:red;
      }
      &:nth-of-type(odd){
        background:green;
      }
    }
}
//     style="height:40px;"
// }
//   padding:5px 5px !important;
// }
//   // padding:0 !important;
//   span {
//     // height:20px;
//   }
// }


///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
// https://ux.stackexchange.com/questions/127263/based-on-what-criteria-do-you-add-not-add-icons-to-labels-within-a-toolbar //
///////////////////////////////////////////////////////////

.laydown-entry-form {
  .nav {
    text-align: center;
    height:80px;
  }
  .nav-tabs > li > a {

    &:hover, 
    &:focus {
      background:blue;
    } 
    &:before {
      content:'';
      position: absolute;
      width:20px;
      right:-20px;
      top:20px;
      background:white;
      height:4px;
    }
      position: relative;
      padding-top: 8px;
      padding-bottom: 8px;
      margin-top: 0;
      color:white;
      color: #555555;
      background-color: transparent;
      border: 1px solid #dddddd;
      // border-bottom-color: transparent;
      cursor: default;
      outline: none;
      -webkit-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      transition: all 0.2s ease;
      width: auto;
      display: inline;
      margin: 0 auto;
          margin-right: auto;
          margin-bottom: 0px;
      float: initial;
      border-radius:4px;

  }
  .nav-tabs > li.active > a, 
  .nav-tabs > li.active > a:hover, 
  .nav-tabs > li.active > a:focus {
    &:before {
      content:'';
      position: absolute;
      width:20px;
      right:-20px;
      top:20px;
      background:white;
      height:4px;
    }
    position: relative;
      padding-top: 8px;
      padding-bottom: 8px;
      margin-top: 0;
      color: #555555;
      background-color: transparent;
      border: 1px solid #dddddd;
      border-bottom-color: transparent;
      cursor: default;
      outline: none;
      -webkit-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      transition: all 0.2s ease;
      width: auto;
      display: inline;
      margin: 0 auto;
          margin-right: auto;
          margin-bottom: 0px;
      float: initial;
      border-radius:4px;

  }
}

.gantt-chart-row td {
  padding-left:0 !important;
  padding-right:0 !important;
  > span {

     padding: 0 !important;
  } 
}


.gantt-table-wrapper table {
  // .table-striped tbody tr td {
  //   padding-left:0 !important;
  //   padding-right:0 !important;
  // }
  thead {
    tr:nth-of-type(1) {
    }
    tr:nth-child(2) {
      th {
      }
      th span {
        display: block;
        width: 100%;
        text-align: center;
        // background: $CORPORATE_GREY_LIGHT;
        border-radius: 8px;
        color: $CORPORATE_GREY_DARK;
        font-weight: 700;
        text-transform: uppercase;
            min-width: 50px;
      }
    }
    tr:nth-child(3) {
    }
    tr{
      th {
        color:$CORPORATE_GREY_DARK;

      }
    }
  }
  tbody {
    tr {
      td {  
         padding-left:0 !important;
    padding-right:0 !important;
        &.active-period {
          background:$TIMELINE_MARKER_COLOR !important;
        }
        &:nth-child(even){
          // background:#00000012;
          background:#b7b3b314;
        }
        &:nth-child(odd){

        }
        // height:50px !important;
      }
    }
  }
    // {# position: absolute;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // top: 0;
    // overflow-y: auto; #}
}


.td-actions-span {
  padding:0;
}
.datatable-actions-list {
  list-style: none;
  padding:0;
  display:block;
  &:hover li a {
    color:white;  
  }
  li {
    float:left;
    display:inline-block;
        margin-right: 10px;
    a {
        color:$CORPORATE_BRAND_COLOR;
        float: left;
        font-size: 23px;
        padding: 3px;
        text-align: center;
        &:hover {
          color:$CORPORATE_WHITE;
        }
    }
  }
}

.laydown-activity-pulse.pulse-entry-active {
  // background:$CORPORATE_BRAND_COLOR;
}



// display: block;
//     height: 54px;
//     top: 34px;
//     position: absolute;
//     opacity: 0.8;



////////// GANTT -- classes

.has-border {
  border-color:$CORPORATE_BLACK;
  &.hb-t {
    border-top:1px solid black;
  }
  &.hb-r {
    border-right:1px solid black;
  }
  &.hb-b {
    border-bottom:1px solid black;
  }
  &.hb-l {
    border-left:1px solid black;
  }
}



@mixin borderCell($color: black, $width: 1px) {
    &.hb-t > span{
      border-top:$width solid $color;
    }
    &.hb-r > span{
      border-right:$width solid $color;
    }
    &.hb-b > span{
      border-bottom:$width solid $color;
    }
    &.hb-l > span{
      border-left:$width solid $color;
    }  
}

@mixin backgroundCell($red: red, $gold: gold, $green: green) {
  &.gc-red >span {
    background-color:$red;
  }
  &.gc-gold >span {
    background-color:$gold;
  }
  &.gc-green >span {
    // background-color:$green;
    font-family: 'Sedgwick Ave Display', cursive;
    color:black;
  }
  &.gc-white >span {
    background-color:$CORPORATE_WHITE;
  }
}


// https://lukyvj.github.io/family.scss/
// https://bootstrapshuffle.com/#purchase https://tailwind.build/#how-it-works
.gantt-datatable-row td  {
  // background:purple;
  @include borderCell($CORPORATE_BLACK, 1px);
  @include backgroundCell(red, gold, orange);
}


/*
every six lines create a bottom border
the first column is green
the sixth row is green
the second column is gold
the sixth row is green
the first column in the fith row is green
the second column in the fith row is gold
the third column is red
the third column fith row is gold
the third column sixth row is green

first // 
second // 
third // 
forth // 
*/



.activity-forms {
  #objectives-section {
    h5 {
      color: #818181;
      font-weight: bold;
    }
    .btn.btn-secondary {
      margin: 10px 0;
    }
    .objectives.row {
      .btn-delete {
        background-color: #d43f3a;
        color: #ffffff;
        border: none;
        margin-top: 30px;
      }
      .form-group {
        width: auto !important;
        margin-bottom: 0 !important;
      }
    }
  }
}

// .gantt-pagination-footer {
//   float:left;
//   width:100%;
//   text-align:center;
//   margin-top:0;
//   > nav {
//     margin:0 auto;
//     display:inline-block;
//     ul {
//       list-style:none;
//       background:purple;
//       margin:0;
//       padding:0;
//       li {
//         float:left;
//         display:inline-block;
//         float:left;
//         a {
//           display:inline-block;
//           float:left;
//           padding:3px;
//           // display:block;
//           float:left;
//           color:$CORPORATE_BLACK;
//           font-size:16px;
//           cursor: pointer;
//           &:hover {
//             color:$CORPORATE_BLACK;
//             text-decoration: underline;
//           }
//           &.active {
//             font-weight: bold;
//             background:gold;
//           }
//           i {
//           }
//         }
//       }
//     }
//   }
// }












// variables
.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.accordion-menu li {
  width: 100%; }

.accordion-menu a {
  padding: 0.7rem 1rem; }

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem; }

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem; }

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0; }

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative; }
  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #2ba6cb transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  right: 1rem;
  left: auto; }

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem; }

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%; }

.is-accordion-submenu-parent {
  position: relative; }

.has-submenu-toggle > a {
  margin-right: 40px; }

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  cursor: pointer; }
  .submenu-toggle::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #2ba6cb transparent transparent;
    top: 0;
    bottom: 0;
    margin: auto; }

.submenu-toggle[aria-expanded='true']::after {
  -webkit-transform: scaleY(-1);
      -ms-transform: scaleY(-1);
          transform: scaleY(-1);
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%; }

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.accordion {
  background:transparent;
  border: 0px solid transparent;
  padding:0;
  margin:0;
  padding: 10px;
  list-style-type: none;
}
  .accordion[disabled] .accordion-title {
    cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 3px 3px 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 3px 3px; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #2ba6cb; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 3px 3px; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

$white:$CORPORATE_WHITE;
$multilevel-accordion-bg: #4d5158;
$multilevel-accordion-sub-bg: #35383d;
$multilevel-accordion-separator: #41444a;

.multilevel-accordion-menu {
  // background for main accordion items
  .is-accordion-submenu-parent a {
    background: $multilevel-accordion-bg;
  }

  // background for sub accordion items
  .is-accordion-submenu a {
    background: $multilevel-accordion-sub-bg;
  }

  // creates classes for nested elements, sub-item-*
  @for $i from 1 through 6 {
    .sublevel-#{$i} {
      text-indent: 0rem + $i;
    }
  }

  a {
    color: $white;
    // sets accodion item separator color
    box-shadow: inset 0 -1px $multilevel-accordion-separator;

    &::after {
      // sets accodion arrow color and direction
      border-color: $white transparent transparent;
    }
  }

  // padding for each list item
  .menu > li:not(.menu-text) > a {
    padding: 1.2rem 1rem;
  }
}

// style for folder icon on subitem anchors without a submenu
.multilevel-accordion-menu .is-accordion-submenu-parent[aria-expanded="true"] a.subitem::before {
  content: "\f016";
  font-family: FontAwesome;
  margin-right: 1rem;
}

// changes folder icon to open then accordion is open
.multilevel-accordion-menu .is-accordion-submenu-parent[aria-expanded="true"] a {
  &::before {
    content: "\f07c";
    font-family: FontAwesome;
    margin-right: 1rem;
  }
}

// changes folder icon to closed then accordion is closed
.multilevel-accordion-menu .is-accordion-submenu-parent[aria-expanded="false"] a {
  &::before {
    content: "\f07b";
    font-family: FontAwesome;
    margin-right: 1rem;
  }
}

.panel-body p:last-child {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.3em;
}

.gantt-table .table-striped thead tr th:first-of-type {
	padding-left:0 !important;
}

.inventory-badge-inside {
    display: block;
    float: left;
    height: 20px;
    width: 20px;
    position:relative;
    .btn.btn-minimal {
        padding: 3px 0 4px 1px;color: white; display:block;
        height: 20px;
        width: 20px;
        margin-top: -1px;
    }
}
td .laydown-activity-pulse.inventory-cell {
  cursor:pointer !important;
  padding:0 !important;
  overflow: hidden;
}

.tooltip-launcher {
  // background:blue;
  padding:0 14px !important;
  display: block;
  float: left;
  height: 23px;
  min-width: 30px;
  margin-left: 5px;
  margin-top: 0px;
  border-radius: 0 !important;
  position:relative;
  .inventory-badge {
    padding: 4px 0 0 6px;
    min-width:auto;
    max-width:19px;
    display:block;
    float:left;
    box-sizing: border-box;
        min-width: auto !important;
  }
}
.inventory-badge-inside .btn.btn-minimal {
    // background: orange;
    margin-top: 0px;
    margin-left: 10px;
}

.laydown-activity-pulse {
  cursor:pointer; 
    // cursor:default !important;
}
.inventory-badge {
    width: 19px;
    height: 19px;
    box-sizing: border-box;
    position: absolute;
    top: 2px;
    left: 8px;
    border-radius:10px;
    background:white;
    &.hide-badge {
    	display: none;
    }
}


.gantt-table table tbody tr td:nth-child(even){
    background: #b7b3b314;
}

.gantt-table table thead tr:nth-child(2) th span {
    display: block;
    width: 100%;
    text-align: center;
    background: #f3f4f4;
    border-radius: 8px;
    color: #737171;
    font-weight: 700;
    text-transform: uppercase;
    min-width: 50px;
}

.laydown-activity-pulse:not(.pulse-start) {
    border-left: 0px solid transparent !important;
}
.laydown-activity-pulse:not(.pulse-end) {
    border-right: 0px solid transparent !important;
}

    // border-right: 0px solid #9f464640 !important;
    // border-left: 0px solid transparent !important;

#ui-container .pageType-campaign .full-width-panel .panel-info .panel-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.pageType-campaign .panel-splitter-control .ui-slider-handle.ui-state-default {
    background:#f1f1f1 !important;
}
.pageType-campaign .panel-splitter-control .ui-slider-handle.ui-state-default:hover {
    background:$ACTUAL_HEADER_BG !important;
}
.we-need-to-wrap-this {
    background: white;
}

.gantt-table-wrapper table thead tr:nth-child(1) th:nth-child(even) span {
    background: #333333;
    border-radius:16px;
    // color: #737171;
    color: white;

}

.gantt-table-wrapper table thead tr:nth-child(1) th:nth-child(odd) span {
    min-width: 100px;
    border-radius:16px;
    background:#333333 !important;
    color:#ffffff;
}


.gantt-table-wrapper table thead tr:nth-child(2) th:nth-child(odd) span, 
.gantt-table-wrapper table thead tr:nth-child(2) th:nth-child(even) span {
    background:#666666 !important;
    color:#ffffff;
}

.gantt-table-wrapper span {
    // background:#eeeeee !important; // removed to fix bug in IM, not sure what it was doing before
    color:#282828;
    // padding: 0 5px !important;
    border-radius: 16px;
}


.take-measurements .text-center > div {
    text-align: center;
    min-width: 30px;
}

.im-fliter-buddy-section {
    width:100%;
    height:auto;
    float: left;
    min-height:230px;
    &.hide-filter-buddy {
      margin-top:30px;
        height:0 !important;
    }
}

.inventory-badge span {
    position: relative;
}
.inventory-badge .avatar-preview {
    // height: 35px;
    // width: 35px;
    left: -10px !important;
    top: 1px !important;
    position: absolute !important;
}

.panel-waaagh { // fix for chosen overflow
  .im-fliter-buddy-section {
    z-index: 11;
  }
  .panel-body {
    z-index: 10;

  }
}

.panel-heading-dash-tabbed {

}

.panel-dash-has-tabs {

}
.panel-body-has-tabs {
}
.panel-has-tabs {
        
    .panel-heading {
        background:transparent;

    }
}
.dash-widget-beta {

}

#ui-container .pageType-campaign .panel-info .panel-dash-has-tabs {
    padding-top:5px !important;
        margin-bottom: 30px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    .tab-content {
        overflow:visible;
        .animationIf {

            position: relative !important;
        }
    }
}


#ui-container .pageType-campaign .panel-info .panel-body {
    // padding:0 !important;d
}

.panel-waaagh {
    margin-right: 20px;
    margin-left: 20px;
}

.panel-waaagh .panel-heading {
    background:transparent !important; 
}

.panel-waaagh .hellowell .panel {
  margin:15px;
}
.panel-waaagh .hellowell .panel-heading {
  background:$PANEL_HEADER_BACKGROUND !important;
  border-color:$PANEL_HEADER_BACKGROUND;
}

.panel-waaagh .file-grid .panel-heading {
    background: $ACTUAL_HEADER_BG !important;
}

.panel-waaagh .file-grid .panel-body {
  padding:15px !important;
}

.panel-waaagh .file-grid .level2 .panel-body {
  background:#ddd !important;
}

.panel-waaagh .file-grid .level3 .panel-body {
  background:#fff !important;
}
 .main.main-waaagh > .panel > .panel-heading {
    margin-top:20px;
    min-height: 23px;
    border-radius: 8px 8px 0 0;
    background: $ACTUAL_HEADER_BG !important;
}
.main.main-waaagh > .panel > .panel-heading .panel-title {
    font-weight: 700;
    min-height: auto !important;
    // color: #e93cac !important;
    text-transform: initial;
    font-size: 16px !important;
    color: white !important;
}


.btn-sword.file-grid-sword {

  min-height: 385px !important;
  max-height: 385px !important;
  margin-bottom:20px;
  span {
    padding: 0 !important;
    margin-bottom: 5px !important;
    // background: #a7c5a7;
    margin-left: 0 !important;
   
  }
  .data-field {

    position: initial !important;
    float: left;
    display: block;
    height: 200px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    margin: 0;
    text-align: center;
    display: flex;
  justify-content: center;
        position: relative;
        align-items: center;
    .data-field-icon {
        // background:magenta;
        width: 100% !important;
        height: 100% !important;
        position: relative;
      i {
        // background: red;
        font-size: 70px;
        position: absolute;
        top: calc(50% - 40px);
        left: calc(50% - 35px);
        width: 70px;
      }
    }

    img {
      // position: absolute;
        // top: calc(50% - 40px);
        // left: calc(50% - 35px);
      margin-top:0 !important;
       max-width: 100% !important;
       min-width: initial !important;
       max-height: 100% !important;
       min-height: initial !important;
        height: auto !important;
       object-fit: contain;
       background-image: url('/rmi-loader.gif') no-repeat;
    }
  }
  .file-controls {
    height:35px;
    // background:blue;
    float: left;
    width:100%;
  }
  .file-display {
    float:left;
    // background:lightblue;
    width:100%;
    height: 130px;
    span {
      text-align: left;
      width:100%;
      float:left;
      display:green;
      width: 100%;
      display: block;
       &.inc-download {
        > div {
          float: right;
          display: block;
        }
      }
    }
  }
}

.avatar-preview.size-120 {

    display:inline-block;
}

.avatar-preview .layer.layer-1 {
    z-index: 100;
    display: none;
}

/* Additional styles to display a spinner image while options are loading */
.localytics-chosen.loading+.chosen-container-multi .chosen-choices {
  background-image: url('/rmi-loader.gif');
  background-repeat: no-repeat;
  background-position: 95%;
}
.localytics-chosen.loading+.chosen-container-single .chosen-single span {
  background: url('/rmi-loader.gif') no-repeat right;
}
.localytics-chosen.loading+.chosen-container-single .chosen-single .search-choice-close {
  display: none;
}

.panel.laydown-datatable.waaagh {
    margin-left: 0;
    margin-right: 0;
}

.main-waaagh .panel-info .panel-body {
      width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    float: left;
}
.main-waaagh .inventory-filter-panel {

    position:relative;
    padding-bottom:70px;
    margin-bottom: 0px;
}


.main-waaagh .panel.laydown-datatable {
    padding-top: 20px;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
}

.main-waaagh .im-fliter-buddy-section  .panel-body.inset-panel-body {
    padding-left:30px !important;
    padding-right:30px !important;
    width: 100%;
}




.tippy-box[data-theme~='tomato'] {
  background-color: tomato;
  color: yellow;
}



/* #################################################
#\\\\\\   RoiReport CSS   \\\\\\\\\\\
The css developed for the roi report
###################################################*/



// .RoiReport-container {
//   background:darkgreen;
//   position:relative;
//   z-index:600;
//   &.has-child::before {
//     z-index:700;
//     background:darkred;
//     height:100%;
//     position: absolute;
//     top:0;
//     left:0;
//     content:'';
//     width:100%;
    
//   }
// }

.tab-content {
  position: relative;
}

// .animationIf {
//   position: absolute;
//   background:$CORPORATE_WHITE;
//   width:100%;
//   // height:400px;
//   // overflow-y:scroll;
//   min-height: 590px;
// }
// .animationIf.ng-enter,
// .animationIf.ng-leave {
//   -webkit-transition: opacity ease-in-out 1s;
//   -moz-transition: opacity ease-in-out 1s;
//   -ms-transition: opacity ease-in-out 1s;
//   -o-transition: opacity ease-in-out 1s;
//   transition: opacity ease-in-out 1s;
// }
// .animationIf.ng-enter,
// .animationIf.ng-leave.ng-leave-active {
//   opacity: 0;
// }
// .animationIf.ng-leave,
// .animationIf.ng-enter.ng-enter-active {
//   opacity: 1;
// }

////////// FLOATING TABS ///////////////
.laydown-datatable.RoiReport-container {

  // .panel-heading-controls .nav-tabs > li {
  //     padding: 0;
  //     margin: 0;
  //        margin-bottom: 30px;
  //     a {
  //      border-radius:8px !important;
  //      margin-top: 0px;
  //      padding-bottom: 0px;
  //      padding-top: 0px;
  //     }
  //  a:hover {
  //      margin-top: 0px;
  //      padding-top: 0px;
  //      padding-bottom: 0px;
  //  }
  // }
  // .panel-heading-controls .nav-tabs > li.active a {
  //     border-radius: 8px 8px 0 0;
  //     border: 1px solid #444644;
  //     // color: $HEINEKENGREENLIGHT;
  //     color:$CORPORATE_WHITE;
  //     // color: #444644;
  //     border:1px solid$CORPORATE_BRAND_COLOR;
  //     background:$CORPORATE_BRAND_COLOR;
  //     transition:all .4;
  //     &:hover {
  //      cursor:pointer;
  //      background: $ACTUAL_HEADER_BG;
  //     }
  // }
}


.page-item.page-item-control {
  .btn-group-has-toggle {
    margin-top: -10px;
  }
  .toggle-switchq + label {
    zoom:65%
  }
}

input[type=number]::-webkit-inner-spin-button {
    opacity: 1
} 

.panel-splitter-control .ui-slider-handle.ui-state-default {
    top: 15px;
    border-left: 11px solid white;
    border: 0 solid #fefefe !important;
    background-color: #041c2c !important;
    background-image: url(/divider-bar.png);
    background-repeat: no-repeat;
    background-attachment: initial;
    background-position: center;
    background-size: 100%;
    overflow: visible;
    min-height: 100px;
    background-position: 95% 46%;
    background-origin: content-box;
    background-size: 40px;
}


.panel-splitter-control .ui-widget-content {
    margin-top: -15px !important; 
    // margin-top: -29px !important; 
    /* !important often means backlog */
}

.doubleScroll-scroll-wrapper {
    margin-top: 0px;
}
.doubleScroll-scroll-wrapper {
  height:13px !important;
}

.dropdown-menu {

    border: 0px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px !important;
}

.datepicker-days {
  tbody tr td:first-child {
    background:#88888b38;
  }
}


.datepicker-days {
  .day {
    border-radius: 8px;
    text-align: center;
  }
  .day.focused {
    text-align: center;
    background: #c3c3c3;
  }
  .day.active {
    text-align: center;
    background: $ACTUAL_HEADER_BG;
    color: white;
  }
}


.panel-heading-controls {
    position: absolute;
    bottom: -1px;
    top: initial;
    right: 10px;
    width: 50% !important;
}

.panel-waaagh > .panel-heading {
    height: 70px;
    .panel-heading-controls {
      // top: 30px; // width tabs
      top: 25px;
    }
}


// fix for overlap heading
.panel-waaagh {
  .panel-body {
    padding:0px 30px 0px !important
  }
} 

.wrap-laydown { 
  background: var(--engine-page-background);
  position:relative;
  float: left;
  width:100%;
  min-height: 800px;
  .tab-content {
    background: var(--engine-page-background);
  }
  .pattern-loader { 
    min-height: 800px;
  }
  &::before { /* This is a fix for some ticket somewhere */
    content:'';
    height:10px;
    width:100%;
    box-size:border-box;
    border-left:1px solid $CORPORATE_GREY;
    border-right:1px solid $CORPORATE_GREY;
    top: 0;
    left: 0;
    position: absolute;
  }
}

.activity-filter-section > footer {
  width:100%;
}


.panel-waaagh {
  
      /*.box{
        background-color:black;
        color:white;
        border:1px solid red;
      }*/
      .fade {
          transition: all linear 1s;
          opacity: 1;
      }

      .fade.ng-hide {
          opacity: 0;
      }
      .ng-hide {
          opacity: 0;
          transition: none 0;
      }
}


// <style>
// .datepicker { border-radius:8px; }
// .datepicker-days tbody tr:nth-of-type(odd){ background:#8080801a; }
// .datepicker-days tbody tr:nth-of-type(odd) .cw{ background:#444444eb;color:#818181; }
// .datepicker-days tbody tr:nth-of-type(even){}
// .datepicker-days tbody tr:nth-of-type(even) .cw{ background:#444;color:#818181; }
// </style>


.tippy-box {
  .tippy-content {
    float: left;
    border:3px solid #c3c3c3;
    background-color:#666666 !important;
    // max-height: 73.5vh;

  }
}

// .tippy-box[data-theme~='tomato'] {
.tippy-box[data-theme~='im-tooltip'] {
  .tippy-content {
    padding:0;
    background-color:#666666 !important;
    .laydown-popover-detail {
      float:left;
      width: 100%;
      margin:0
    }
  }
  
  tbody tr td:first-child {
    // background:#88888b38;
  }
  thead tr:last-of-type{

    .cw {
      background: #88888b38;
      // position: relative;
      // :before{ // this doesnt work - requires inserting span into td
      //   content:'\f784';
      //   background: red;
      //   color:gold;
      //   height:100%;
      //   width:100%;
      // }
    }
  }
}


.not-blem {
  width:auto !important;
}
.blem {
  // padding-right:75px !important;
  // padding-right:75px !important;
  // width:505px;
      // padding-right: 77px !important;
      padding-right: 50px !important;
    width: 520px;

}

.datepicker-days {
  .day {
    border-radius: 8px;
    text-align: center;
  }
  .day.focused {
    text-align: center;
    background: #c3c3c3;
  }
  .day.active {
    text-align: center;
    background: $ACTUAL_HEADER_BG;
    color: white;
  }
}


.inventory-loader .pattern-loader > div {
  background:#c3c3c3bf;
  height:100%;
}
.navigator.dakka-nav {
  overflow: hidden;
  // right: 24px !important;
  right: 0px !important;

  .btn-group {
    padding:0 0 0 5px;
    .btn:hover {
      background: #d6cbcb26;
      color: #fefefe;
      border-radius: 3px 0 0 3px;
    }
    .btn:focus, .btn:focus {
      border:0px solid transparent !important;
    }
  }
}

.inventory-badge {
  cursor:pointer; 
}

.laydown-popover-detail {
  .pagination {
    margin-top: 0;
    margin-bottom: 0;
  }
  .ng-table-pagination li {
    // background:#c3c3c3 !important;
    a {
        border-radius: 25px;
        height: 28px;
        background: white!important;
        color: #000000 !important;
        padding-top: 7px;
        cursor: pointer;
        &.active {
          background: $CORPORATE_ACTION_COLOR !important;
          color:white !important;
        }
    }
  }
}

#ui-container .form-group .input-group .chosen-drop {
    // border-left: 1px solid white !important;
    border-left: 1px solid #444 !important;
    // border-right: 1px solid white !important;
    border-right: 1px solid #444 !important;
    z-index: 5699;
}
#ui-container .form-group .input-group .chosen-drop::before {
  content:'';
  height:100%;
  position:absolute;
  width:1px;
  background:#444;
  top:0;
  left:-2px;
}
#ui-container .form-group .input-group .chosen-drop::after {
  content:'';
  top:0;
  height:100%;

  position:absolute;
  width:1px;
  background:#444;
  right:-2px;
}

.btn-mini-nav {

    background: #d6cbcb26;
    color: white;
    border: 0px solid #d6cbcb26;
    margin-bottom: 5px;
    border-radius: 5px;
}




.equalise-images {
    img  {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    // .helper {
    //     // display: inline-block;
    //     display: none;
    //     height: 100%;
    //     vertical-align: middle;
    // }
}



.tp-final-artwork {
  border-radius:8px;
  width:100%;    
  float: left;
  img {
    // border:1px solid #c3c3c3; 
    max-height:150px;
    max-width: 100%;
    margin: 5px auto 5px;
    display: block;
  }
}

.laydown-popover-detail .entry-tooltip-table {
    width:100%;
    max-width: 100%;
    table-layout: fixed;
    .im-tooltip-image {
      max-width: 90%;
      max-height: 100px;
    }
    .im-tooltip-image-wrapper {
        height: 100px;
        white-space: nowrap; /* This is required unless you put the helper span closely near the img */
        text-align: center;
        margin: 1em 0;
    }
      
    &.align-table-left {
        .tp-final-artwork img {
          float:left;
        }
        tr {
          width:100% !important;
          td:first-of-type {
            color:#f3f4f46b !important;
            text-align: left;
            width:auto !important;
          }
          td:last-of-type {

            text-align: left;
            width:auto;
          }
        }
        tr td.im-tooltip-image-wrapper {
          width:50% !important;
        }
    }
    &.align-table-center {
        tr {
          width:100% !important;
          td:first-of-type {
            color:#f3f4f46b !important;
            text-align: right !important;
            width:50% !important;
          }
          td:last-of-type {
            text-align: left;
            width:50% !important;
          }
        }
    }
    tr {

      td {
        // width:50% !important;
        display: block;
        overflow: hidden;
        float: left;
        display: block;
        word-break: break-word;
        &::first-child {
        }
        &::last-child {
        }
      }
    }
}

.navigator.dakka-nav {
  overflow: hidden;

  .btn-group {
    padding:0 0 0 5px;
    .btn:hover {
      background: #d6cbcb26;
      color: #fefefe;
      border-radius: 3px 0 0 3px;
    }
    .btn:focus, .btn:focus {
      border:0px solid transparent !important;
    }
  }
}

.inventory-badge {
  cursor:pointer;
}



// > .panel > .panel-heading {
  // position: relative;
      // z-index: 5690;
    // }
// }

  $cr:$ACTUAL_HEADER_BG;
  $co:$CORPORATE_HEADER_BG;
  $cy:yellow;
  $cg:green;
  $cb:blue;
  $ci:indigo;
  $cv:violet;
  $dimdark:ab;
  $dim:c4;

.row-1 {
  
  background-color:$cr+$dimdark !important;
  // &:nth-of-type(odd){
  //   background-color:$cr+$dim !important;
  // }
  span {
    color:white !important;
  }
}

.row-3 {
  
  background-color:$co !important;
  // &:nth-of-type(odd){
    // background-color:$cr+$dim !important;
  // }
  span {
    color:white !important;
  }
}

.row-7 {
  
  background-color:$co+$dim !important;
  span {
    color:white !important;
  }
}

.row-15 {

  background-color:$cg !important;
}

.row-31 {
  
  background-color:$cg !important;
}

.row-127 {

  background-color:$cb !important;
}

.row-511 {

  background-color:$ci !important;
}

.row-1023 {

  background-color:$cv !important;
}



// Button style tabs style overrid
.panel-heading-controls {
  .nav-tabs {
    border-bottom:0px solid transparent !important;
    li a,
    li a.active {
      border-radius: 8px !important;
    }
    li a:hover {
    padding-bottom: 0 !important;
    }
  }
}

#modalworkz-inside .modal-content {
  border-radius:16px 16px 16px 16px;
  overflow: hidden;
  .modal-body {
    border-left:1px solid #c3c3c3 !important;
    border-right:1px solid #c3c3c3 !important;
    overflow-x: hidden !important; // prevents bug, does it cause one?

  }
  .modal-footer {
    background:white;
    border-radius:0px 0px 16px 16px;
    border-left:1px solid #c3c3c3 !important;
    border-right:1px solid #c3c3c3 !important;
    border-bottom:1px solid #c3c3c3 !important;
  }
}

.contains-context.buildbuild .modal-content
{
  border-radius:16px 16px 16px 16px;
  overflow: hidden;
  .modal-body {
    border-left:1px solid #c3c3c3 !important;
    border-right:1px solid #c3c3c3 !important;
    border-bottom: 1px solid #c3c3c3 !important;
    border-radius: 0 0 16px 16px;
    overflow-x: hidden !important; // prevents bug, does it cause one?
    &.modal-body-has-footer {
      border-bottom: 0px solid #c3c3c3 !important;
          max-height: 55vh;
      border-radius: 0 0 0 0;
    }
  }
  .modal-footer {
    background:white;
    border-radius:0px 0px 16px 16px;
    border-left:1px solid #c3c3c3 !important;
    border-right:1px solid #c3c3c3 !important;
    border-bottom:1px solid #c3c3c3 !important;
  }
}


.insight-iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  //padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.table-striped tbody tr:hover {
  cursor:pointer;
}


.dashboard-widget.quick-options {}
.dashboard-widget.quick-options .panel{background:transparent !important;}
.dashboard-widget.quick-options .panel-body{background:transparent !important;padding:0 !important;}


.super-block .lost-wrench {
  color: #a3a1a1 !important;
}

img[src=""] {
    display: none;
}


// <!-- ENGINE MODULE | RoiReport -->
// @import '../../modules/roi_report/RoiReport.scss';


.duplicator-row-tl {
  padding-top:10px;
  &:nth-child(odd){
    background:white;

  }
  &:nth-child(even){
    background:#8483801f;
    // background:red;
  }
  .form-section.light {
    background: transparent !important;
  }
}

.overspend-style {
  color:red !important;
}


.repeating-form-field {
    opacity: 1;
    &.ng-enter {
        -webkit-transition: 1s;
        transition: 2s;
        opacity: 0;
    }
    &.ng-enter-stagger {
      transition-delay: 0.7s;
      animation-delay: 0.8s;
    }
    &.ng-enter-active {
        opacity: 1;
    }
}

.slide {
  overflow: hidden;
  transition: 0.3s;
  height: 30px;
}

.slide.ng-enter {
  height: 0;
}

.slide.ng-enter.ng-enter-active {
  height: 30px;
}

.sub-repeating-form-field {
    opacity: 1;
    &.ng-enter {
        -webkit-transition: 1s;
        transition: 2s;
        opacity: 0;
    }
    &.ng-enter-stagger {
      transition-delay: 1.7s;
      animation-delay: 0.8s;
    }
    &.ng-enter-active {
        opacity: 1;
    }
}



// .main.main-waaagh > .panel > .panel-heading {
//   margin-top:20px;
//   min-height: 23px;
// }

.dashboard-tab-style {
  margin:20px;
}

.main-waaagh .panel-info .panel-body {
   float: initial;
}

.toolPaginationLeft {
  display: block; 
  margin-bottom:20px;
}

.toolPaginationCenter {
  display: inline-block;
}


.media-revenue-report .table-header-sort-group{ // div
      left:-6px !important;
      width: calc(100% + 17px) !important;
    }

.check-group-control {
  position: relative;
  margin-left: -6px;
  margin-top: 21px;
  .btn-check {
    bottom:3px;
    left:0px;
    position: absolute;
    float: left;
    padding: 0px 4px;
    border-radius: 3px;
    border: 1px solid transparent;
  }
  .btn-check:hover {
    border: 1px solid #c3c3c3;
  }

  .btn-square {
    bottom:3px;
    left:20px;
    position: absolute;
    float: left;
    padding: 0px 4px;
    border-radius: 3px;
    border: 1px solid transparent;
  }
  .btn-square:hover {
  border: 1px solid #c3c3c3;
}
}

.table-header-sort-group { // div
    background: block;
    position:absolute;
    left:-18px;
    padding:3px;
    float: left;
    height: initial !important;
    overflow: hidden;
    padding: 0px !important;
    float: left;
    border-radius: 8px!important;
    top:0px;
    bottom:0px;
    display: block;
    border: 1px solid transparent !important;
      width: calc(100% + 18px) !important;

    // &.media-revenue-report { // div
    //   left:-6px !important;
    //   width: calc(100% + 22px) !important;
    // }

    &:hover {
      // background: gold;
      // background: #888;
      border: 1px solid #c3c3c3 !important;
    }

  .btn-sorting { // a
    width: 100%;
    padding:0 3px;
    // height:20px;
    height:100%;
    color:#888;
    display: block;
    float: left;
    cursor: pointer;
    position: relative;
    // width:20px;
        // margin-left: 11px;

    .fa-caret-up {
      bottom:6px;
      left:6px;
      position: absolute;
    }
    
    .fa-caret-down {
      bottom:0px;
      left:6px;
      position: absolute;
    }
      &.sort-up {
        .fa-caret-down {
          color:transparent;
        }
      }
      &.sort-down {
        .fa-caret-up {
          color:transparent;
        }
      }

    &.btn-filter {
      background:red;
    }
    &.btn-up {
      background:transparent;
    }
    &.btn-down {
      background:transparent;
    }
    &:hover {
      // background:#c3c3c3;
      background:#c3c3c373;
      // color:#c3c3c3;
    }
    transition:all .5s;
  }
}
.table-header-sort-group {
    background: block;
    padding: 3px;
    float: left;
}

table.table-striped {
  
  .chosen-container .chosen-results {
    max-height: 100px;
  }
}

#flightcheck-subthings-datatable {
  .table-striped.table {
    margin:0 auto 0 !important;
  }
  .table-striped thead tr:hover {
    background:initial;
    th {

      color:red;
    }
  } 
}

.slide-row {
    float: left;
    width: 100%;
    min-height: 200px;
    .take-measurements {
      height:0 !important;
      background: initial !important;
    }
    .spacer-row-for-gantt {
      height:0 !important;
      visibility:hidden;
    }
    .gantt-pagination-footer {
      height:0 !important;
      visibility: hidden;
    }
}



.table-striped tbody tr td > span.toggle-row-control {
  text-align: center !important;
  .toggle-row-open {
  margin-top: -7px;
    background: var(--engine-tab-background);
    border-color: var(--engine-tab-background);
    border-radius: 5px;
    padding:5px;
    i {
      color: var(--engine-tab-color);
    }
  }
  .toggle-row-close {
    display:none;
  }
}


.table-striped tbody tr.active-row td > span.toggle-row-control {
  text-align: center !important;
  .toggle-row-close {
    margin-top: -7px;
    border-radius: 5px;
    padding:5px;
    background: var(--engine-tab-active-background);
    border-color: var(--engine-tab-active-background);
    display:initial !important;
    i {
      color: var(--engine-tab-active-color) !important;
    }
  }
  .toggle-row-open {
    display:none;
  }
}

.table-striped tbody tr.active-row .spacer-row-utility-search { // hides the extra row for the utiltiy search
  visibility: hidden;
}