
// .lost_budget-viewAnimation { 
//   overflow: hidden; // this breaks dropdowns!!
// }



// .pie-col-1 {
//   color:#444;
//   display:block;
//   float:left;
//   width:33%;
//   width:87px; //33%;
//   i {
//     margin-right:10px;
//   }
// }
// .pie-col-2 {
//   color:#818181;
//   display:block;
//   float:left;
//   text-align:right;
//   padding-right:10px;
//   // box-sixzin;
//   width: 114px;
//   text-align: right;
//   span {
//     float:left;
//     display: block;
//     color:#444;
//   }
// }
// .pie-col-3 {
//   color:#645d5d; 
//   display:block; 
//   float:left;
//   width:33%;
// }


// .chart-col-1 {
//   color:#818181;
//   display:block;
//   float:left;
//   text-align:right;
//   width:137px; //33%;
//   i {
//     margin-right:10px;
//   }
// }
// .chart-col-2 {
//   color:#444;
//   padding-left:10px;
//   display:block;
//   float:left;
//   width:112px;
//   padding-right:10px;
// }
// .chart-col-3 {
//   color:#444;
//   display:block;
//   float:left;
//   width: 70px;
// }
// .chart-col-4 {
//   color:#00b050;
//   display:block;
//   float:left;
//   width: 113px;
// }

// .jbp-tool {
//   .form-section .form-label {
//     width: 195px;
//     text-align:right;
//     padding-right:10px;
//   }

// }

// .jbp-pie .pie-chart{
//   margin-top:0px;
//   float: left;
// }

.gotoreport-btn {

  background: white;
  border: 2px solid #cc092f;
  border-radius: 8px;
  width: 268px;
  padding: 13px;
  margin: 20px auto;
  display: block;
  float: left;
  margin-left: 42px;
  i {
    color:#cc092f;
    float: left;
    font-size: 42px;
  }
  span {
    font-size: 22px;
    color: #cc092f;
    padding-top: 9px;
    display: block;
    margin-left: 57px;
  }
}

/* #################################################
\\\\\\   LicenseTracker SASSYCSS   \\\\\\
##################################################*/
.license_tracker-container {
  background:#444;

}
.chosen-container-multi .chosen-drop .result-selected { // fix for https://leocaseiro.github.io/angular-chosen/ dropdowns not having disabled styles
    background: gray;
    color: white;
}

.special-wrapper {

  .table-striped.double-scroll-table.datatable-table {
    // thead tr:nth-child(1),
    // thead tr:nth-child() {
      // display: none;
    // }
  } 
  .main-datatable-section .doubleScroll-scroll-wrapper {
    display: none;
  }

}

.special-header {
    background: #5a3f3f;
    width: 100%;
    padding: 5px;
    color: #f2a312;
    text-transform: uppercase;
    font-size: 16px;
}

/* #################################################
\\\\\\   LicenseTracker SASSYCSS   \\\\\\
##################################################*/
.license_tracker-container {
  background:#444;

}
.chosen-container-multi .chosen-drop .result-selected { // fix for https://leocaseiro.github.io/angular-chosen/ dropdowns not having disabled styles
    background: gray;
    color: white;
}

.special-wrapper {


  .table-striped.double-scroll-table.datatable-table {
    thead tr:nth-child(1),
    thead tr:nth-child(2) {
      display: none;
    }
  } 
  .main-datatable-section .doubleScroll-scroll-wrapper {
    display: none;
  }

}
.special-header {
    background: #5a3f3f;
    width: 100%;
    padding: 5px;
    color: #f2a312;
    text-transform: uppercase;
    font-size: 16px;
}

.minify-reports.hide-totals-row tr#tableRow0 td span {
  visibility: initial !important;
}



// <<<<<<< HEAD
// =======

.chosen-container-single .chosen-single abbr { // this is the deselct x
  top:11px;
  right: 33px; 
}
.supplier-spend-tracker-report {
  .minify-reports {
    .table-striped tbody tr:nth-child(even) td:nth-of-type(5),
    .table-striped tbody tr:nth-child(even) td:nth-of-type(6),
    .table-striped tbody tr:nth-child(even) td:nth-of-type(7),
    .table-striped tbody tr:nth-child(even) td:nth-of-type(8){
      background:#D1E8F0;
    }
    .table-striped tbody tr td:nth-of-type(9) span,
    .table-striped tbody tr td:nth-of-type(10) span,
    .table-striped tbody tr td:nth-of-type(11) span {
      font-weight: bold;
    }
  }
}
.media-revenue-report {
  .minify-reports {
    .table-striped tbody tr:nth-child(even) td:nth-of-type(6),
    .table-striped tbody tr:nth-child(even) td:nth-of-type(7),
    .table-striped tbody tr:nth-child(even) td:nth-of-type(8),
    .table-striped tbody tr:nth-child(even) td:nth-of-type(9) {
      background:#D1E8F0;
    }
    .table-striped tbody tr td:nth-of-type(10) span,
    .table-striped tbody tr td:nth-of-type(11) span,
    .table-striped tbody tr td:nth-of-type(12) span {
      font-weight: bold;
    }
  }
}

.total-revenue-report {
  .minify-reports {
    .table-striped tbody tr:nth-child(even) td:nth-of-type(8) {
      // background:#D1E8F0;
    }
    .table-striped tbody tr td:nth-of-type(11) span {
      // font-weight: bold;
    }
  }
}


.jbp-tool-tables {

  .minify-reports {
    .cutchar-data-field {
      &.double-line {
      }
    }
    .table-striped tbody tr td span {
      font-size: 13px;
      // color:orange !important;
    }
  }

  .datatable-double-scroll-section {
    overflow-x: scroll;
  }

  .spacer-row-utility-search {
    display: none;
  }

  #datatable-page #ds-left {
    // overflow: initial !important;
  }
  .main-datatable-section > div > section {
    // overflow: initial !important;
  }
  .datatable-double-scroll-section {
    // overflow: initial !important;
    // overflow-x:initial !important;
  }
}

.jbp-tool-tables .gantt-pagination-footer {
  display:none !important;
}

.total-graph-targets {
  width: 300px !important;
  .pie-col-2 {
    width:83px;
  }
  .pie-col-3 {
    width:109px;
  }
}
.total-graph-live {
  width:297px;
      width: 330px !important;
  margin-left:15px;
  .pie-col-light {
    display: none;
  }
  .pie-col-2 {
    width:119px;
  }
  .pie-col-3 {
    width:100px;
  }
}

.jbp-tool-tables {
  .table-header-sort-group {

    width:calc(100% + 19px) !important;
  }
}


.panel-splitter-control .ui-slider-handle.ui-state-default {
  background-color:#00000014 !important 
}

.jbp-tool-tables {
  margin-top:-15px;
}

