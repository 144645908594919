// @import "../../node_modules/bootstrap/scss/bootstrap";

// <!-- Techie Starter Theme -->
//	RECOMMEND DELETING /assets/app/engine/scss/techie.scss.
//	THE DIFFERENCES BETWEEN THEM ARE NOT SUFFICIENT ENOUGH TO WARRANT IMPORTING A THEMED VERSION
@import '../techie.scss';

@import './scss/_media_queries.scss';

// <!-- Animate.css -->
@import '../animate.scss-master/animate.scss';

// <!-- Tippy -->
@import '../../../node_modules/tippy.js/dist/tippy.css';
@import '../../../node_modules/tippy.js/dist/svg-arrow.css';
// @import '../../../node_modules/chosen-js/chosen.css';
// import 'tippy.js/themes/light.css';

// <!-- Flexboxgrid.scss -->
// @import '../../../node_modules/flexboxgrid/dist/flexboxgrid.css';
// @import '../../../node_modules/flexboxgrid/dist/flexboxgrid.min.css';
@import './scss/base-flexgrid.scss';
@import './scss/fonts/montserrat/stylesheet.scss';

// <!-- Angular Bootstrap Colorpicker -->
@import '../../../node_modules/angular-bootstrap-colorpicker/css/colorpicker.min.css';


// <!-- Font Awesome -->
// @import '~font-awesome/scss/font-awesome.scss';

// $fa-font-path: "../../../node_modules/@fortawesome/fontawesome-pro/webfonts" !default;
@import '../../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss';
@import "node_modules/@fortawesome/fontawesome-pro/scss/light.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/solid.scss";

// @import '../../../node_modules/font-awesome/css/font-awesome.scss';
// @import '../fontawesome-5.0.8/css/fontawesome-all.css';
//$fa-font-path: "fontawesome-5.0.8/webfonts";

//////////// mixin libraries
@import '../lukyvj.family.scss';

@import '../../third-party/Image-Select-master/src/ImageSelect.css';

@import './engine-theme.scss';
// <!-- ENGINE MODULE | RoiReport -->
// @import '../../modules/roi_report/RoiReport.scss';

$ENGINE_HEADER_COLOUR:#f2f2f2;
$ENGINE_HEADER_BACKGROUND:#282828;
$ENGINE_TAB_COLOUR:#c3c3c3;
$ENGINE_TAB_BACKGROUND:#282828;
$ENGINE_TAB_ACTIVE_COLOUR:#282828;
$ENGINE_TAB_ACTIVE_BACKGROUND:#c3c3c3;
$ENGINE_PANEL_HEADER_BACKGROUND:#282828;
$ENGINE_PANEL_HEADER_COLOR:#f2f2f2;
$ENGINE_PANEL_BACKGROUND:blue;
$ENGINE_PANEL_COLOR:red;
$ENGINE_ACTION_BUTTON_BACKGROUND:#282828;
$ENGINE_ACTION_BUTTON_COLOR:#f2f2f2;
$ENGINE_DEFAULT_BUTTON_BACKGROUND:#c3c3c3;
$ENGINE_DEFAULT_BUTTON_COLOR:#282828;
$ENGINE_CURRENCY_UNICODE:'f157';
$ENGINE_PAGE_BACKGROUND:red;
$ENGINE_TEXT_COLOR_DEFAULT:black;

.login-screen::before {
  display:none !important;
}


// $ACTUAL_HEADER_BG:$icelandWhite;
// $ACTUAL_HEADER_COLOR:$icelandRed;
// $CORPORATE_HEADER_BG:$icelandRed;
// $CORPORATE_BRAND_COLOR:$icelandRed;
// $CORPORATE_ACTION_COLOR:$icelandRed;
// $SEARCH_CHOICE_CLOSE_HOVER_COLOR:$icelandRed;
// $CHOSEN_SEARCH_BEFORE_COLOR:$CORPORATE_GREY;
// $CHOSEN_DROP_RESULT_SELECTED_COLOR:$icelandRed;
// $TABLE_PAGINATION_ACTIVE_BG:$icelandRed;
// $TABLE_PAGINATION_ACTIVE_COLOR:$icelandWhite;
// $NGTABLECOUNTS_BTN_BG:$icelandRed;
// $NGTABLECOUNTS_BTN_COLOR:$icelandWhite;
// $NGTABLECOUNTS_BTN_ACTIVE_COLOR:$icelandRed;
// $NGTABLECOUNTS_BTN_ACTIVE_BG:$icelandWhite;
// $SEARCH_CHOICE_CLOSE_HOVER_COLOR:$icelandRed;
// $CHOSEN_SEARCH_BEFORE_COLOR:$CORPORATE_GREY;
// $CHOSEN_DROP_RESULT_SELECTED_COLOR:$icelandRed;
// $TABLE_PAGINATION_ACTIVE_BG:$icelandRed;
// $TABLE_PAGINATION_ACTIVE_COLOR:$icelandWhite;
// $NGTABLECOUNTS_BTN_BG:$icelandRed;
// $NGTABLECOUNTS_BTN_COLOR:$icelandWhite;
// $NGTABLECOUNTS_BTN_ACTIVE_COLOR:$icelandRed;
// $NGTABLECOUNTS_BTN_ACTIVE_BG:$icelandWhite;
// $SEARCH_CHOICE_CLOSE_BG:transparent;
// $SEARCH_CHOICE_CLOSE_COLOR:$BASE_GREY;
// $SEARCH_CHOICE_CLOSE_HOVER_BG:transparent;
// $SEARCH_CHOICE_CLOSE_HOVER_COLOR:$BASE_HEADER_BG;
// $CHOSEN_SEARCH_BEFORE_COLOR:$BASE_BLACK;
// $CHOSEN_DROP_RESULT_SELECTED_COLOR:$BASE_BLACK;
// $TIMELINE_MARKER_COLOR : #FFFF6676;
::root {
  --engine-header-color:green; //$ENGINE_HEADER_COLOUR;
  --engine-header-background:$ENGINE_HEADER_BACKGROUND;
  --engine-tab-color:$ENGINE_TAB_COLOUR;
  --engine-tab-background:ENGINE_TAB_BACKGROUND;
  --engine-tab-active-color:ENGINE_TAB_ACTIVE_COLOUR;
  --engine-tab-active-background:ENGINE_TAB_ACTIVE_BACKGROUND;
  --engine-panel-header-background:ENGINE_PANEL_HEADER_BACKGROUND;
  --engine-panel-header-color:ENGINE_PANEL_HEADER_COLOR;
  --engine-action-button-background:ENGINE_ACTION_BUTTON_BACKGROUND;
  --engine-action-button-color:ENGINE_ACTION_BUTTON_COLOR;
  --engine-default-button-background:ENGINE_DEFAULT_BUTTON_BACKGROUND;
  --engine-default-button-color:ENGINE_DEFAULT_BUTTON_COLOR;
  --engine-page-background:$ENGINE_PAGE_BACKGROUND;
  --engine-text-color-default:$ENGINE_TEXT_COLOR_DEFAULT;
  --engine-panel-background:--ENGINE_PANEL_COLOR_BACKGROUND;
  --engine-currency-unicode:--ENGINE_CURRENCY_UNICODE;

}
.toggle-icon i::before {
  color:var(--engine-header-color);
}
// <!-- Dragula -->
@import '../../../node_modules/dragula/dist/dragula.css';

body{
  color:var(--engine-text-color-default);
  background:var(--engine-page-background);
  visibility: visible !important;
}

// <!-- RPG Awesome -->
// @import 'Rpg-Awesome-master/scss/rpg-awesome';

// <!-- Theme -->

// <!-- Structure -->
@import '../structure';
// <!-- Button -->
@import '../button';
// <!-- Form -->
@import '../form';
// <!-- Animation -->
@import '../animation';
// <!-- Typography -->
@import '../typography';

// <!-- ENGINE CASING -->
@import '../../engine/casing/header.scss';
@import '../../engine/casing/sidebar.scss';
// @import '.../../engine/casing/bookcase.scss';
// @import '.../../engine/casing/extra.scss';

// <!-- ENGINE MODULES -->
@import '../../engine/login/login';
// @import '.../../engine/mail/mail';
// @import '.../../engine/mail/tzu-pdf';

// <!-- PARTS -->
@import '../../parts/avatari/avatari';
@import '../../parts/alert/alert';
@import '../../parts/audit_trail/audit-trail';
// <!-- MODULES -->
// @import '../../modules/activity/activity';
@import '../../modules/activity_asset/activity-asset';
// @import '../modules/campaign/campaign';
@import '../../modules/dashboard/dashboard';
// @import '../../modules/reports/maintenance';
@import '../../modules/crm_lite/crm-lite.scss';
// @import '../../modules/settings/settings';
// @import '../../modules/touchpoint/touchpoint';
// @import './third-party/smallvan/smallvan';
@import '../../modules/workflow/workflow';
@import '../../modules/campaign_insights_library/CampaignInsightsLibrary.scss';
@import '../../modules/sales_focus_by_trading/SalesFocusByTrading';

// <!-- ENGINE MODULE | Budget -->
// @import '../../modules/budget/Budget';
// <!-- ENGINE MODULE | Hacktivation -->
// @import '../../modules/activation/Activation';
@import '../../parts/base3/inventory';
@import '../../parts/base3/drill-report';
@import '../../parts/base3/avatari';
@import '../../parts/minis/minis.scss';


// <!-- FORM PARTS -->
@import '../../components/input-checkbox/input-checkbox.scss';
@import '../../components/input-image-upload/input-image-upload.scss';
@import '../../components/input-profile-file-type/profile-file-type.scss';
@import '../../components/input-select/input-select.scss';
@import '../../components/input-toggle-switch/input-toggle-switch.scss';
@import '../../components/input-date/input-date.scss';
// @import '.../../components/input-radio/input-radio.scss';

// <!-- COMPONENTS -->
@import '../../components/pattern-button/pattern-button.scss';
@import '../../components/pattern-datatable/pattern-datatable.scss';
@import '../../components/pattern-filter-buddy/pattern-filter-buddy.scss';
@import '../../components/pattern-jumbotron/pattern-jumbotron.scss';
@import '../../components/pattern-loader/pattern-loader.scss';
@import '../../components/pattern-modal/modal.scss';
@import '../../components/pattern-navigator/pattern-navigator.scss';
@import '../../components/pattern-panel/pattern-panel.scss';
@import '../../components/pattern-nav-tabs/pattern-nav-tabs.scss';
@import '../../components/pattern-panel-control/pattern-panel-control.scss';
// @import '.../../components/pattern-popover/pattern-popover.scss';
@import '../../components/pattern-popover/pattern-popover.scss';
@import '../../components/pattern-sticky-header/pattern-sticky-header.scss';
@import '../../components/pattern-split-panels/pattern-split-panels.scss';
@import '../../components/pattern-tooltip-dynamic/pattern-tooltip-dynamic.scss';
// <!-- inputs.css -->
@import "../app/scss/inputs.scss";




.duplicator-row-tl {
	padding-top:10px;
	&:nth-child(odd){
		background:white;

	}
	&:nth-child(even){
		background:#8483801f;
		// background:red;
	}
	.form-section.light {
		background: transparent !important;
	}
}

.overspend-style {
	color:red !important;
}


.repeating-form-field {
    opacity: 1;
    &.ng-enter {
        -webkit-transition: 1s;
        transition: 2s;
        opacity: 0;
    }
    &.ng-enter-stagger {
      transition-delay: 0.7s;
      animation-delay: 0.8s;
    }
    &.ng-enter-active {
        opacity: 1;
    }
}

.slide {
  overflow: hidden;
  transition: 0.3s;
  height: 30px;
}

.slide.ng-enter {
  height: 0;
}

.slide.ng-enter.ng-enter-active {
  height: 30px;
}

.sub-repeating-form-field {
    opacity: 1;
    &.ng-enter {
        -webkit-transition: 1s;
        transition: 2s;
        opacity: 0;
    }
    &.ng-enter-stagger {
      transition-delay: 1.7s;
      animation-delay: 0.8s;
    }
    &.ng-enter-active {
        opacity: 1;
    }
}

.drill-row {
  opacity: 1;
        -webkit-transition: .3s;
    &.ng-enter {
        -webkit-transition: .3s;
        transition: .3s;
        opacity: 0;
    }
    // &.ng-enter-stagger {
      // transition-delay: 1.7s;
      // animation-delay: 0.8s;
    // }
    &.ng-enter-active {
        opacity: 1;
    }
}
// sub-repeating-form-field


.pageType-campaign .form-section.light {
	background: transparent;
}


#brief-page .input-group textarea.form-control
 {

	width:100%; 
	height:250px !important;
 }


// <<<<<<< woof-and-brew
// ////////////////////////////////////////////////////////////////////
// // Login
// .cls-content .panel-body {
//   text-align:center;
//   padding: 15px !important;
//   color: #444;
//   margin-left: -73px;
// }

// .cls-content .pad-btm {
//   padding:10px !important;
// }

// .login-screen {
//   position:relative;
// }
// .contact100-form {
//   width: 100%;
// }
// .input-new{
//   padding:100px;
//   border-radius:20px

// }

// .wrap-input100 {
//   width: 100%;
//   position: relative;
//   background-color: #fff;
//   border-radius: 20px;
//   margin-bottom: 30px;
// }

// .landing-form-box {
//   //width: 850px;
//   background: #fff;
//   border-radius: 26px;
//   overflow: hidden;
//   padding: 10px 80px 25px 80px;

//   box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
//   -moz-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
//   -webkit-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
//   -o-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
//   -ms-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
// }


// .wrap-input100 {
//   width: 100%;
//   position: relative;
//   background-color: #fff;
//   border-radius: 20px;
//   margin-bottom: 30px;
// }


.panel > .panel-heading {
  z-index: 100;
}

.data-field .prettycheckbox {
  float:initial !important;
}

.prettycheckbox {
  float:left !important;
}

.audit-trail-list-description {
  &.workflow-decision {
    background:#595959 !important;
    p {
      color:white;
    }
  }
}



.audit-trail-list-description {
    float: left !important;
    margin-right: 45px !important;
    padding:10px 10px 10px 10px !important;
    margin-left: 36px !important;
    padding-left: 10px !important;
    color: white;
    width:calc(100% - 285px);
    // min-width:460px;
    // max-width:460px;
}


.table-striped thead tr th {
  &:first-of-type {
    // padding-left:24px !important;
  }
}

.touchpoint-list-table {
    .table-striped thead tr th {
      &:first-of-type{

        padding-left:24px !important;
      }
        padding-left:2px !important;
      span {
        padding-left:0 !important;
      }
    }
}


// }


.image-display-title {
  height: 30px;
  color:$DASHBOARD_SECTION_TITLE;
  font-size:25px;
  font-weight:bold;
  height:auto;
  margin:0;
  padding:0;
  text-align:center;
  margin-bottom:10px;
  width:100%;
}


.hide-top-trs {    
  table thead {
    tr:nth-child(1) {
      display: none !important;
    }
    tr:nth-child(2) {
      display: none !important;
    }
  }
}

// #tab-datatable  {
//       // 284px;
//       max-width: calc(100% - 84px);
//       background: #c3c3c3;
// }


// <!-- ENGINE MODULE | LicenseTracker -->
// @import '../../modules/license_tracker/LicenseTracker';
@import '../../modules/contract/Contract';
@import '../../modules/jbp_report/JbpReport';
@import '../../modules/revenue_tracker_overview/RevenueTrackerOverview';
@import '../../modules/metadata_manager/MetadataManager';


// .lost_budget-viewAnimation { 
//   overflow: hidden; // this breaks dropdowns!!
// }

$chart_black: #444;
$chart_blue: #00b4f1;
$chart_gold: #ffc800;
$chart_green: #00b050; //  style="color:#00b050;"
$chart_grey: #818181;
$chart_red: #c00000;

.color-chart-black {
  color:$chart_black;
}
.color-chart-blue {
  color:$chart_blue;

}
.color-chart-green {
  color:$chart_green;

}
.color-chart-gold {
  color:$chart_gold;

}
.color-chart-grey {
  color:$chart_grey;

}

.pie-col-light {
  float: left;
  padding-right: 5px;
}

.pie-col-1 {
  color:$chart_black;
  display:block;
  float:left;
  width:33%;
  // width:100px;
  width:83px; //33%;

  i {
    margin-right:10px;
  }
}
.pie-col-2 {
  color:#818181;
  display:block;
  float:left;
  text-align:right;
  padding-right:10px;
  // box-sixzin;
  // width: 114px; // working original width
  width: 128px;
  font-size:12px; // reduce from 14px
  // width: 157px;
  text-align: right;
  span {
    float:left;
    display: block;
    color:#444;
  }
}
.pie-col-3 {
  color:#645d5d; 
  display:block; 
  float:left;
  width:33%;
}


.chart-col-1 {
  color:#818181;
  display:block;
  float:left;
  text-align:right;
  width:137px; //33%;
  i {
    margin-right:10px;
  }
}
.chart-col-2 {
  color:#444;
  padding-left:10px;
  display:block;
  float:left;
  width:112px;
  padding-right:10px;
}
.chart-col-3 {
  color:#444;
  display:block;
  float:left;
  width: 70px;
}
.chart-col-4 {
  color:#00b050;
  display:block;
  float:left;
  width: 113px;
}

$deliveredGreen:green;
$undeliveredRed:red
;$deliveredBlue:#00b4f1;
$undeliveredGrey:#BFBFBF;
.sumup-rows li.sumup-total span.delivered,
.delivered {
  color:$deliveredGreen !important;
  span {
    color:$deliveredGreen !important;
  }
}
.sumup-rows li.sumup-total span.delivered,
.delivered-sst {
  color:$deliveredBlue ;
  span-sst {
    color:$deliveredBlue ;
  }
}
.sumup-rows li.sumup-total span.undelivered,
.undelivered {
  color:$undeliveredRed !important;
  span {
    color:$undeliveredRed !important;
  }
}

.jbp-percent-of-target {
  width:100%;
  width: 120px;
  margin: 0 auto;
  border-radius:6px;
  display: block;
  // float: left;
  overflow: hidden;
}
.jbp-pot-delivered {
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
  background:$deliveredGreen;
  height:20px;
  float:left;
}
.jbp-pot-undelivered {
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
  background:$undeliveredRed;
  height:20px;
  float: left;
}

.sst-pot-delivered {
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  background:$deliveredBlue;
  height:20px;
  float:left;
}
.sst-pot-undelivered {
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  background:$undeliveredGrey;
  height:20px;
  float: left;
}

.sumup-rows {
  margin:0;
  padding:0;
  list-style:none;
  width:100% !important;
  display:block;
  float:left;
  li {
    font-size: 16px;
    font-weight: bold;
    display:block;
    width:100%;
    float:left;
    span {
      display: block;
      float: left;
      font-weight: normal;
    }
    &.sumup-header {
      span {
        font-weight: bold;
        color:$chart_black !important;
      }
    }
    &.sumup-total {
      span {
        color:$chart_black !important;
        font-weight: bold;
      }
    }
  }
  .sumup-col-1 {
    width: 180px;
    text-align:right;
  }
  .sumup-col-2 {
    width: 150px;
    text-align:right;
  }
  .sumup-col-3 {
    color:$chart_green !important;
    width: 150px;
    text-align:right;
  }
  .sumup-col-4 {
    width:150px;
    text-align:right;
  }
  .sumup-col-5 {
    color:$chart_black !important;
    text-align:right;
    width:150px;
  }
}

.graph-well {
  margin-bottom: 20px;
  background:#f6f4f4;
  padding:15px;
  float:left;
  border-radius:16px;
  width: 100%;
  > h4 {
    color: #444;
    font-size: 16px;
    font-weight: bold;
    margin-bottom:20px;
        margin: 0 0 10px;
  }
  ul {
    margin:0;
    padding:0;
    list-style:none;
    // width:340px;
    width:360px;
    display:block;
    float:left;
    li {
      font-size: 16px;
      font-weight: bold;
      display:block;
      width:100%;
      float:left;
    }
  }
}
.jbp-tool {
  .form-section .form-label {
    width: 195px;
    text-align:right;
    padding-right:10px;
  }

}

.jbp-pie {
  float: left;
  width: 170px;
  margin-top: -30px;
  margin-bottom: -10px;
  .pie-chart{
    // background: red;
    float: left;
    // max-width: 100%;
    max-width: 120px;
    margin-left:23px;
  }

} 
.gotoreport-btn {
  cursor: pointer;;
      -webkit-transition: background .6s ease-in-out;
    -moz-transition: background .6s ease-in-out;
    -o-transition: background .6s ease-in-out;
    transition: background .6s ease-in-out;
  &:hover {

      background:#cc092f;
    i {
      color:white;
    }
    span {
      color:white;

    }
  }
  background: white;
  border: 2px solid #cc092f;
  border-radius: 8px;
  width: 268px;
  padding: 13px;
  margin: 20px auto;
  display: block;
  float: left;
  margin-left: 42px;
  i {
    -webkit-transition: color .4s ease-in-out;
    -moz-transition: color .4s ease-in-out;
    -o-transition: color .4s ease-in-out;
    transition: color .4s ease-in-out;
    color:#cc092f;
    float: left;
    font-size: 42px;
  }
  span {
    -webkit-transition: color .4s ease-in-out;
    -moz-transition: color .4s ease-in-out;
    -o-transition: color .4s ease-in-out;
    transition: color .4s ease-in-out;
    font-size: 22px;
    color: #cc092f;
    padding-top: 9px;
    display: block;
    margin-left: 57px;
  }
}


// .full-page-action .section-header h2.section-title {
//   color:darkgrey !important;
//   font-size:23px !important;
// }

// .full-page-action .section-header h4 {
//   font-size:27px !important;
//   color:#cc092f !important;

// }




.maintenance {
    max-width: 100%;
    #datatable-page {
        overflow-x:scroll;
    }
}

.baseflexrow-report-button {
  margin-left: -22px;
}

// fix for BASE3-1313
.login-screen .base-logo {
    margin-left: 100px;
}

.data-field .data-field-icon {
    text-align: center;
    font-size: 20px !important;
    margin-top: -5px;
}

.assets-datatable thead tr {
  &:nth-child(1),
  &:nth-child(2) {
    display: none;
  }
}
.reporting-list-overhang-fix .panel-body {
  min-height: 106px;
}


// for the border color:
input[type="radio"]:checked:after {
    background-color: red !important;
box-shadow: 0 0 0 2px red;
}


// <!-- ENGINE MODULE | TestTreeConfig -->
// @import '../../modules/test_tree_config/TestTreeConfig.scss';

.table-striped tbody tr:hover {
  .btn.btn-submit.btn-mini {
      color: #78ac06 ;
      background: #fefefe ;
  }
}

a {
    color:#00541c;
    text-shadow: 1px 1px 0px #beb5b55c;
}



// tippy_ztlppvza8kl5trjldi9am
// .data-field-actions {
  //  .btn.btn-submit {
  //     position: relative;
  //     display: inline-block;
  //     padding: 0.25em 0.5em;
  //     text-decoration: none;
  //     color: #FFF;
  //     background: #fd9535;
  //     border-radius: 4px;
  //     box-shadow: inset 0 2px 0 rgba(255,255,255,0.2), inset 0 -2px 0 rgba(0, 0, 0, 0.05);
  //     font-weight: bold;
  //     border: solid 2px #d27d00;
  // }
  // .btn.btn-mini:active {/*on Click*/
  //     box-shadow: 0 0 2px rgba(0, 0, 0, 0.30);
  // }
// }

h5 {
  color:#444;
}

.chosen-container-single {  
  .chosen-single-with-deselect.chosen-single abbr {
      top: 6px;
      right: 35px;
      z-index: 99999999;
      float: left;
      position: absolute;
  }
}


.special-bru {
  .form-section label {
        display:none !important;
  }
  .form-section .form-items {
        max-width:100% !important;
        width:100% !important;
  }
}

.workflow-tool-header .form-section .form-items {
      max-width: 65%;
}// <!-- ENGINE MODULE | CampaignInsightsLibrary -->



// .super-block {
//  .row {

//  }
// }

.tight-padding-cols {
  .col-md-2:first-child {
    padding-left:15px !important;
  }
  .col-md-2, 
  .col-md-1 {
    padding-left:5px;
    padding-right:5px;
  }
}

.input-group {
  .validate-if-required {
    right: -15px !important;
    top: -9px !important;
  }
}

.width-118 {
  width:118px;

}

.width-142 {
  width:142px;
}


.inventory-filter-panel {
  width:100%;
}
// <!-- ENGINE MODULE | AssetExport -->
@import '../../modules/asset_export/AssetExport.scss';
@import '../../modules/flight_checker_report/FlightChecker.scss';


@media (min-width: 1102px){
}

@media (min-width: 768px) and (max-width: 1645px ){
  .custom-grid {
      .col-md-2 {
        // width: 16.66666667%;
        width: 25%;
        // width: 33.33333333%;
      }
  }
}

.custom-grid {
  .btn-sword.file-grid-sword .file-display span {
      font-size: 12px;
      padding-right: 10px !important;
      display: block;
      float: left;
  }
  .btn-sword.file-grid-sword .file-display span {
  }
  .inc-download {
    display: block;
    float: right;
    margin-top: 4px;
    width:auto;
    text-align: right;
    // background: orange;
    > div {
      float: left;
      // background: red;
    }
    .prettycheckbox {
        margin-top: 8px !important; 
    }
  }
  .inc-in-export {
    display: block;
    float: left;
    text-align: right;
    width:70px;
    // background: green;
  }
}

.panel.panel-info.laydown-datatable.panel-waaagh {

    margin-left: 0;
    margin-right: 0;
}

.form-label {
  color:#818181;
}


// . {
//   background: pink;
//     width:100%;
//     height:368px;
// }
// .slide-row.ng-enter,
// .slide-row.ng-leave {
//     -webkit-transition:2.5s linear all;
//     -moz-transition:2.5s linear all;
//     -o-transition:2.5s linear all;
//     transition:2.5s linear all;
//     border: 1px solid blue;
// }

// .slide-row.ng-enter.ng-enter-active {
//     border: 1px solid red;
// }


 // .slide-row.ng-enter, .slide-row.ng-leave {
 //    position: absolute;
 //    // width: 100%;
 //    background:blue;

 //    -webkit-transition:all 2s ease-in-out;
 //    transition:all 2s ease-in-out;
 //  }
 //  .slide-row.ng-enter {
 //    // transform: translateX(100%);
 //    // -webkit-transform: translateX(100%);
 //    transform: translate3d(100%, 0, 0);
 //    -webkit-transform: translate3d(100%, 0, 0);
 //  }
 //  .slide-row.ng-enter-active {
 //    // transform: translateX(0);
 //    // -webkit-transform: translateX(0);
 //    transform: translate3d(0, 0, 0);
 //    -webkit-transform: translate3d(0, 0, 0);
 //  }
 //  .slide-row.ng-leave {
 //    opacity: 1;
 //    // transform: translateX(0);
 //    // -webkit-transform: translateX(0);
 //    transform: translate3d(0, 0, 0);
 //    -webkit-transform: translate3d(0, 0, 0);
 //  }
 //  .slide-row.ng-leave-active {
 //    opacity: 0;
 //    // transform: translateX(-100%);
 //    // -webkit-transform: translateX(100%);
 //    transform: translate3d(-100%, 0, 0);
 //    -webkit-transform: translate3d(-100%, 50, 50); 
 //  }


.slide-row[ui-view].ng-enter, .slide-row[ui-view].ng-leave {
  position: absolute;
  left: 0;
  right: 0;
  -webkit-transition:all 5s ease-in-out;
    -moz-transition:all 5s ease-in-out;
    -o-transition:all 5s ease-in-out;
    transition:all 5s ease-in-out;
}

.slide-row[ui-view].ng-enter {
  opacity: 0;
  -webkit-transform:scale3d(0.5, 0.5, 0.5);
  -moz-transform:scale3d(0.5, 0.5, 0.5);
  transform:scale3d(0.5, 0.5, 0.5);
}

.slide-row[ui-view].ng-enter-active {
  opacity: 1;
  -webkit-transform:scale3d(1, 1, 1);
  -moz-transform:scale3d(1, 1, 1);
  transform:scale3d(1, 1, 1);
}

.slide-row[ui-view].ng-leave {
  opacity: 1;
  /*padding-left: 0px;*/  
  -webkit-transform:translate3d(0, 0, 0);
  -moz-transform:translate3d(0, 0, 0);
  transform:translate3d(0, 0, 0);
}

.slide-row[ui-view].ng-leave-active {
  opacity: 0;
  /*padding-left: 100px;*/
  -webkit-transform:translate3d(100px, 0, 0);
  -moz-transform:translate3d(100px, 0, 0);
  transform:translate3d(100px, 0, 0);
}


// #ui-container .pageType-campaign .ng-table-counts .btn{
//    color:$NGTABLECOUNTS_BTN_COLOR;
//     background:$NGTABLECOUNTS_BTN_BG;
//     &.active {
//       color:$NGTABLECOUNTS_BTN_ACTIVE_COLOR !important;
//       background:$NGTABLECOUNTS_BTN_ACTIVE_BG;
//     }
// }

/////////////////////////////////////////////////////////////////


// $NEWbuildbuild-bg:white; 





////////////////////
// panel panel-info 
.panel-heading-controls .nav-tabs > li a {
    &.active a {

    }
}

.form-section .form-label {
    width:35% !important;
}
.form-section .form-items {
    width:65% !important;
}

#ui-container .form-group .input-group .form-control:focus {
    border: 1px solid orange !important;
}

.panel-heading-controls .nav-tabs > li a:hover {
    color: white;
}

.chosen-container-single .chosen-single abbr {
    position: absolute;
    top: 7px;
    right: 39px;
    display: block;
    border: 1px solid black;
    width: 12px;
    height: 12px;
    ::before {
        position: absolute;
        color:green;
        content:'pp';
        height:10px;
        width:10px;
    }
    height:10px; width:10px;
    position:relative;
    // background: url(/build/images/chosen-sprite.8b55a822.png) -42px 1px no-repeat;
    font-size: 1px;
    border-radius: 20px;
    padding: 9px;
}



.accordion-inner {
    // padding: 0;
    border-top: 1px solid #e5e5e5;
}

.login-screen-extra {
    // background-attachment: fixed !important;
    // background-position: 0px 213px !important;
    // background-size: 440px !important;
}
.login-screen {
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
    .company-logo {
        padding-top: 50px;
        padding-bottom: 10px;
        max-width: 100%;
    }
    .brand-icon {
        height: 50px;
        height: 50px;
        position: absolute;
        z-index: 999;
        top: 10px;
        left: 10px;
        border-radius: 0;
    }
    > div {
        position: relative;
        z-index: 99999;
    }
    &::before,
    &::after {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        width:100%;
        height:100%;
        content:'';
    }
    &::before {
        background-attachment: fixed !important;
        background-size: 100%;
        opacity:.7;
    }
    &::after {
        background-attachment: fixed !important;
        background-position: center;
        background-size: 554px !important;
        opacity:.7;
    }
}



.cls-content .background-solid {
    background-color: transparent !important;
}

.cls-content .panel-body {
    text-align:center;
    padding: 15px !important;
    color: #444;
    margin-left: -73px;
}

.cls-content .panel-body img {
    margin: 0 auto 10px;
    display: block;
}

.dashboard-layout .section-header h3 {
    color: #00541c;
}


.contact100-form {
    width: 100%;
}
.input-new{
    padding:100px;
    border-radius:20px

}

.wrap-input100 {
    width: 100%;
    position: relative;
    background-color: #fff;
    border-radius: 20px;
    margin-bottom: 30px;
}

.landing-form-box {
    //width: 850px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 26px;
    overflow: hidden;
    padding: 10px 80px 25px 80px;

    box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
}


.company-logo{
    // width:400px; 
    margin:0 auto 10px;display: block;
    // padding-left: 80px
}

.wrap-input100 {
    width: 100%;
    position: relative;
    background-color: #fff;
    border-radius: 20px;
    margin-bottom: 30px;
}



.focus-input100 {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    border-radius: 20px;
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}
hr.dashed {
    border-top: 1px dashed #999;
}

.contact100-form {
    width: 100%;
}
.input-new{
    padding:10px;
    border-radius:20px

}

// drill-full.html.twig
.table-striped tbody tr:hover td {  
    .drill-toggle {
      color:$BASE_BLACK !important;
      .fa-fw {
        color:$BASE_BLACK !important;

      }
    }
}
.drill-toggle { // <a>
    color:$BASE_BLACK;
    .fa-fw { // <i>
        color:$BASE_BLACK;
    }
    &:hover {
        color:$BASE_BLACK;
    }
}

.base-grey{
    background-image: url(/instance/tisn/Base_grey.png) !important;
    padding-left:5px;width:155px;
    height: 68px;
    background-repeat: no-repeat;
    background-position: center;
}

.contact100-form {
    width: 100%;
}


.datepicker-days .day.active { // overrid for the active day style for the datepicker
  background:$BASE_HEADER_BG !important;
  color:#fefefe !important;
}


.input-new{
    padding:10px;
    border-radius:20px

}

hr.dashed {
  border-top: 1px dashed #999;
}

.login-screen {
  position:relative;
}


.cls-content .panel-body {
  width: 505px;
}
.cls-content .panel-body {
  text-align:center;
  padding: 15px !important;
  color: #444;
  margin-left: -73px;
}

.cls-content {
  padding-top:0 !important;
}

.cls-content .pad-btm {
  padding:10px !important;
}

.cls-content .background-solid {
  background-color: transparent !important;
}

//////////master-2035-2044

// $ENGINE_HEADER_COLOUR:#f2f2f2;
// $ENGINE_HEADER_BACKGROUND:#282828;
// .context-toggle.left {
//   background-color:$ENGINE_HEADER_BACKGROUND;
// }
// .collapse.navbar-collapse {
//   background-color:$ENGINE_HEADER_BACKGROUND;
//     .toggle-icon i::before {
//     color:$ENGINE_HEADER_COLOUR;
//   }
// }
// .header_user-settings-block {
//   color:$ENGINE_HEADER_COLOUR;
////// =  =
.wrap-input100 {
    width: 100%;
    position: relative;
    background-color: #fff;
    border-radius: 20px;
    margin-bottom: 30px;
}

.btn-primary.btn-register {
  background:black !important;
  color:white !important;
}

.trth-cell-span {

  color:var(--engine-text-color-hightlight) !important;
}
// $CORPORATE_WHITE:$BASE_HEADER_BG;
.section-header .section-title .badge,
.section-header .section-title .badge i {
  // color:$BASE_HEADER_COLOR !important;
  color:var(--engine-text-color-invert-hightlight) !important;
  // background:$BASE_HEADER_BG !important;
  background:var(--engine-text-color-hightlight) !important;
}

.section-header .section-title .badge-h3,
.section-header .section-title .badge {
  // color:$BASE_HEADER_BG !important;
  color:var(--engine-text-color-hightlight) !important;
}

// .panel > .panel-heading h2.section-title {color:$BASE_HEADER_BG !important;}
// .dashboard-layout .section-header  h2.section-title { color:$BASE_HEADER_BG  !important;}
// .panel-heading-controls .nav-tabs > li.active a {
//   background:$BASE_GREY !important;
//   border:1px solid $BASE_GREY !important;
////////// BASE3-2044
// }

.brand-icon { // fixes white on white issue
  // background:#c3c3c3;
  border-radius:8px;
  margin-top:10px;
  margin:20px;
  display:block;
}


///////// BASE3-1963-extended
.focus-input100 {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  border-radius: 20px;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}


.form-section .form-label {
  width:35% !important;
}
.form-section .form-items {
  width:65% !important;
}

.chosen-container-single .chosen-single abbr {
  position: absolute;
  top: 7px;
  right: 39px;
  display: block;
  border: 1px solid black;
  width: 12px;
  height: 12px;
  ::before {
    position: absolute;
    color:green;
    content:'pp';
    height:10px;
    width:10px;
  }
  height:10px; width:10px;
  position:relative;
  font-size: 1px;
  border-radius: 20px;
  padding: 9px;
}

// .btn-default.btn-forget { //ICEAND
//   background:transparent !important;
//   border-color:transparent !important;
//   color:#737171 !important;
//   &:hover {
//     text-decoration: underline;
//     color:#737171 !important;
//     border-color:transparent !important;
//     background:transparent !important;

//   }
// }

// .btn.btn-submit, .btn.btn-primary2 {
//   background: black;
//   box-shadow: 0px -2px 0px transparent inset;
//   border: 1px solid black;
//   color: $ACTUAL_HEADER_BG;
// }
/////////  BASE3-1963-extended=======

$ENGINE_PANEL_HEADER_BACKGROUND:#c3c3c3;
$ENGINE_PANEL_HEADER_COLOR:#282828;
.panel-heading {
    background: $ENGINE_PANEL_HEADER_BACKGROUND;
    border-color:$ENGINE_PANEL_HEADER_BACKGROUND;
}

// style for client set up form
.pageType-campaign.client-setup-form {
  .form-section {
    padding: 10px;
    background: #c3c3c3;
    border-radius: 10px;
    border: 1px solid #a09e9e;
    width: calc(100% - 10px);
    margin: 5px;
  }
  .form-section .form-label {
    width: 100% !important;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 10px;
    color: #282828;
  }
}

.show-color {
    width: 25px;
    height: 25px;
    float: left;
    position: absolute;
    border-radius: 30px;
    top: 1px;
    left: 7px;
    top: 5px;
}

.color-picker-input {
  padding-left:40px !important;
}


.activity-filter-section footer > p { // style for filter buddy help text
  max-width:calc(100% - 272px);
}

#embeddingContainer iframe {
  height:calc(100vh - 106px) !important;
}



.inventory-loader {

}
.special-loading {
    float: left;
    width: 100%;
    &.main.main-waaagh.report-view-loading::before{
      display:none;
      height:100%;
    }
}

// #header {
//     background: red;
//     padding: 10px 0;
//     position: relative;
//     -webkit-transition: all 1s linear;
//     -moz-transition: all 1s linear;
//     -o-transition: all 1s linear;
//     transition: all 1s linear;
//     left:0;
// }

// #header.transition {
//     background: green;
//     left: 50px;
// }

.dynamic-tr {
  // -webkit-transition: all 2s linear;
  // -moz-transition: all 2s linear;
  // -o-transition: all 2s linear;
  // transition: all 2s linear;
  // height: 0;
  // opacity: 0;
}

.dynamic-tr.ng-enter {
  transition:all ease-in 2.25s;
  height: 0px;
}
.dynamic-tr.ng-enter.ng-enter-active {
  height: 300px;
}

.dynamic-tr.dynamic-row {
  // opacity: 1;
  // height: 300px;
}
// .dynamic-row.ng-enter {
//   transition: 2.8s linear all;
//   opacity: 0;
// }

// .dynamic-row-init .dynamic-row.ng-enter {
//   opacity: 1;
// }

// .dynamic-row.ng-enter.ng-enter-active {
//   opacity: 1;
// }

// .dynamic-row.ng-leave {
//   transition: 2.3s linear all;
//   opacity: 1;
// }
// .dynamic-row.ng-leave.ng-leave-active {
//   opacity: 0;
// }


.replace-tooltip-dialog {
  p {
  color:#444;
  }
  .close-tooltip-btn {
    position: absolute;
    left: 5px;
    bottom: -20px;
  }
}


.has-error {
  color:red;
}

.manage-datatable-headers {
  .form-section .form-label {
    padding-left:0;
    color:white;
  }
  .panel-heading,
  .panel-footer,
  .panel-body {
    background: #000000;
  }
  .manage-datatable-headers-utilities {
    .prettyradio label {
      color:white;
    }
    .form-section .form-label label {
      color:white !important;
    }
    border-radius: 16px !important;
    .panel-heading {
      background: #474545;
    }
    .panel-body {
      background: #474545;
    }
    .panel-footer {
      background: #474545;
    }
  }
  .jazzy-callout {
    margin: 10px;
    background: rgb(229, 178, 85);
    border-radius: 10px;
    float: left;
    width: 100%;
    padding:10px;
    .form-section .form-label {
      color:#474545;
    }
  }
}

.make-this-work-how {
    .panel > .panel-heading > a,
    .panel > .panel-heading .panel-title {
      color:black !important;
    }
}
.minimal-datatable {
    .main-gantt-section, .gantt-table-wrapper, #tab-datatable, #datatable-page, .datatable-table {
    background:orange !important;
    background-color:transparent !important;
    }
    .table .table {

      background-color:transparent !important;
    }
    
    .trth-cell-span {
     color:white !important;
    }
    .table-striped tbody tr:nth-child(2n),
    .table-striped tbody tr:nth-child(2n+1) {
      background-color: transparent;
      border-top-color: transparent;
      border-bottom-color: transparent;
      td > span {
       color:white !important;
      }
    }
    .table-striped tbody tr:hover {
      background-color: white !important;
      td > span {
       color:black !important;
      }
    }
    //.main > .panel.laydown-datatable .panel-body {
    .panel.laydown-datatable .panel-body {
      background: transparent !important;
    }
}

.change-position-widget {
    .move-position-up {
      &:hover {
        background:green;
        color:white;
      }
    }

    .move-position-down {
      &:hover {
                background:green;
        color:white;
      }
    }
}

.giantBear-section {
  background:blue;
  .chart-doughnut {
    height:40px !important;
  }
}



.cell-audit-campaign {
  background:#00640045 !important;
}
.cell-audit-touchpoint {
  background:#ffffff59 !important;
}