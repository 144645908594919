.flightchecker-popover {

  .main-block {
    padding-bottom: 120px;
  }
  .chosen-results {
    max-height: 100px;

  }
  .form-label label {
    text-align:left;
  }
  .form-group.currency::before {
      padding: 9px 10px 4px 13px;
  }
  .panel {
    .panel-heading {
      display: none;
    }
    .panel-body {
      padding:0px 0px 0px !important;
    }
  }
  .main-block {
  	    max-width: 92vw;
    overflow-x: scroll;
  }
}
.store-list-file-popover {
  .col-md-3 {
    width:273px;
  }
  .col-md-9 {
    width:1163px;
  }
}

.close-tooltip-btn {
  position:absolute;
  left:10px;
  bottom:10px;
}

.datatable-columns-edit {  
  background:gold !important;

  .audit-trail-list-item { 
  width:auto;
  
  //padding: 10px 0px;
    .as-sortable-item-handle {
    
      width: max-content;
      display:flex;
      background:orange; border:3px dashed black;
    
      span:first-of-type {
        flex-basis:content !important;
      }
    
      span:last-of-type {
        flex-basis:content !important;
      }
    }
  }
}
