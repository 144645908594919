
$tableFontSize:14px;
$valueWidth:80;
$unitsWidth:51;
$goWidth:51;
$deptWidth:120;
$inviWidth:145;
$mediWidth:220;
$dtsDWidth:130;
$dtsDdWidth:115;

$paddingTotals:100;
// 20

.sales-focus-report {
  // width:($paddingTotals + $valueWidth + $unitsWidth + $goWidth + $deptWidth + $inviWidth + $mediWidth + $dtsDWidth + $dtsDdWidth)+px !important;
  width:1360px;
  margin: 0 auto;

}
.sfbtw-table-body.totals .value-units p { font-weight: bold;color:#282828;}
.sfbtw-table-body.totals .value-units-go p { font-weight: bold;color:#282828;}
.value-units {
  // width:131px;
  width:($valueWidth+$unitsWidth)+px;
  float:left;
  margin-left:10px;
}
.value-units-go {
  // width:182px;
  width:($valueWidth+$unitsWidth+$goWidth)+px;
  float:left;
  margin-left:10px;
}
.dept-invi-medi {
  // width:350px;
  width:($deptWidth+$inviWidth+$mediWidth)+px;
  float:left;
  margin-left:10px;
}
.final-dts {
  float:left;
  margin-left:10px;
  // width:195px;
  width:($dtsDWidth+$dtsDdWidth)+px;
}
.sfbtw-table-thead {
  float: left;
  width:100%;
  .header {
    width:100%;
    color:white;
    font-size: 11px;
    background:grey;
    float:left;
    display:block;
    text-align: center;
    margin-bottom: 0;
  }
  .sub-header {
    color:black;
    background:#c3c3c3;
    float:left;
    display:block;
    font-size: $tableFontSize !important;
    line-height: 1em !important;
    box-sizing:border-box;
    padding:3px;
    margin-bottom: 0;
    &.value {
      width:$valueWidth+px;
    }
    &.units {
      width:$unitsWidth+px;
    }
    &.go {
      width:$goWidth+px;
    }
    &.thick {
      background:grey;
      height:45px;
      color:white;
      width:100%;
    }
    &.dept {
      width:$deptWidth+px;
    }
    &.invi {
      width:$inviWidth+px;
    }
    &.medi {
      width:$mediWidth+px;
    }
    &.dts-d {
      width:$dtsDWidth+px;
    }
    &.dts-dd {
      width:$dtsDdWidth+px;
    }
    .choice-type-toggle {
      float: right;
      zoom: .7;
      margin-left: 3px;
    }
  }
}
.sfbtw-table-body {
  float: left;
  width:100%;
  &:nth-child(odd) {
    .sub-header {
      background:#f7f5f5;
      &.green-unit {
        background:#6eab6e;
        color:white;
      }
    }
  }
  &:nth-child(even) {
    .sub-header {
      background:#eaeaef;
      &.green-unit {
        background:#3c831e;
        color:white;
      }
    }
  }
  .sub-header {
    
    font-size: $tableFontSize !important;
    float:left;display:block;
    line-height: 1em !important;
    box-sizing:border-box;
    padding:9px 3px 3px 3px;
    margin-bottom: 0;
    height:30px;
  	span {
  		margin-top:1px;
  	}
  	&.text-wrap-element {
  		span {
  			margin-top: -7px;
  		}
  	}
    &.value {
      text-align: right;
      width:$valueWidth+px;
    }
    &.units {
      width:$unitsWidth+px;
      border-left:1px solid #c3c3c3;
      text-align: center;
    }
    &.go {
      border-left:1px solid #c3c3c3;
      width:$goWidth+px;
    }
    &.dept {
      width:$deptWidth+px;
    }
    &.invi {
      border-left:1px solid #c3c3c3;
      width:$inviWidth+px;
    }
    &.medi {
      border-left:1px solid #c3c3c3;
      width:$mediWidth+px;
    }
    &.dts-d {
      width:$dtsDWidth+px;
    }
    &.dts-dd {
      border-left:1px solid #c3c3c3;
      width:$dtsDdWidth+px;
    }
    &.zero-unit {
      background:#e34c4c;
      color:white;
    }
    
    .btn-mini {
      text-decoration: none;
      font-size: 12px;
      padding-top: 1px;
      padding-bottom: 1px;
    }
  }
}

.sfbtw-header {
  width:100%;
  float: left;
}

.week-card {
  border:2px solid grey;
  float: left;
  width:100px;
  margin-left:5px;
  &.wc-ctrl {
    border:2px solid transparent;
    .days {
      font-weight: bold;
      span {
        background: transparent;
      }
    }
  }
  .titl {
    text-align: center;
    font-weight: bold;
    height:40px;
    margin:0;
    padding-top: 5px;
  }
  .numb {
    font-weight: bold;
    line-height: 1.2em;
    font-size: 14px;
    color:#282828;
    text-align: center;
    height:40px;
    margin:0;
  }
  .start {
    text-align: center;
    font-size: 14px;
    margin-bottom:0;
  }
  .end {
    font-size: 14px;
    text-align: center;
    margin-bottom:0;
  }
  .days {
    text-align: center;
    padding:5px;
    span {
      background: #c3c3c3;
      width: 100%;
      display: block;
      color: #282828;
      padding: 2px 0;
    }
  }
}
.yield-display {
	    // margin-left: 38px;
  background: #f1f1f1;
  border-radius:8px;
  float: left;
  overflow: hidden;
  margin-left: 173px;
  .yd-col-left {
    width:100px;
    float:left;
    p{font-size: 16px; text-align: center;}
  }
  .yd-col-mid {
    width:90px;
    float:left;
    text-align: center;
  }
  .yd-col-right {
    width:90px;
    float:left;
  }
  p {
    padding:5px;
    margin-bottom:0;
    font-size: 16px;
    span {font-size: 16px !important;}
  }
  .rev {
    background: #c3c3c3;
    color: #282828;
    p {
      color:#282828;
    }
  }
  .occ {
    border-bottom:1px solid #c3c3c3;
  }
  .yie {
    border-bottom:1px solid #c3c3c3;
  }
  .var {
  }
}
.col-choices {
  margin-left:10px;
  width:380px;
  float:left;
  text-align: center;

}
.col-yield {
  margin-left:10px;
  width: 479px;
  float:left;
}
.col-days {
  margin-left:10px;
  width:420px;
  float:left;
  float: right;
}

.heat-map-on .sub-header {
  color:#5f5b5b !important;
}