/* there is no need to define anything inside of the destination
CSS class since the keyframe will take charge of the animation */
// @import '../../scss/themes/heineken.scss';

.login-screen.ng-leave {
  animation: my_fade_animation 0.4s linear;
  -webkit-animation: my_fade_animation 0.4s linear;
}

@keyframes my_fade_animation {
  from { opacity:1; }
  to { opacity:0; }
}

@-webkit-keyframes my_fade_animation {
  from { opacity:1; }
  to { opacity:0; }
}

/* start 'enter' transition on main view */
#ui-container.ng-enter {
    /* transition on enter for .5s */
    transition: 0.8s;

    /* start with opacity 0 (invisible) */
    opacity: 0;
}

/* end 'enter' transition on main view */
#ui-container.ng-enter-active {
    /* end with opacity 1 (fade in) */
    opacity: 1;
}

// $login-page-fullscreen-image:'/base-bg1.jpg';
// $login-page-fullscreen-image:'/Heineken_SMS_background.png';
// $login-page-fullscreen-image:$LOGIN_BG;
.login-screen {
    // background: rgb(241,245,248);
    // background: radial-gradient(circle, rgba(241,245,248,1) 0%, rgba(191,206,217,1) 100%);
    // background-image:$LOGIN_BG;
    // background-image:$LOGIN_BG, radial-gradient(circle, rgba(241,245,248,1) 0%, rgba(191,206,217,1) 100%);
    // background-image: url($login-page-fullscreen-image);
    // background-image: url('/base-bg1.jpg');
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.forgot-password-link {
    float:left;
}

.cls-content {
    padding: 0px 15px 15px;
    // padding-top: 10vh;
    position: relative;
    // padding-top: 17vh;
    .panel-body > div img {
            width: 250px;
    }
    .cls-content-sm {
        width: 350px !important;
    }
    .cls-content-sm, 
    .cls-content-lg {
        min-width: 270px;
        margin: 0 auto;
        position: relative;
        background-color: transparent;
        border: 0;
        box-shadow: none;
    }
    .panel-body {
        padding: 15px !important;
        // color: $CORPORATE_WHITE;
        color: $CORPORATE_BLACK;
        // background: $CORPORATE_WHITE;
    }
    .background-solid {
        background-color: transparent !important;
    }
    .pad-btm {
        font-size: 16px;
        color: $CORPORATE_BLACK;
        text-align: center;
        padding: 32px 0;
        float: left;
        width: 100%;
    } 
    .login-form-group {
        padding-left:0px;
        margin-bottom: 15px;
    }
    .input-group {
        position: relative;
        display: table;
        border-collapse: separate;
        input {
            border: 1px solid black;
            border-radius: 15px;
                padding: 8px 8px 7px;
        }
    }
    .input-group-addon:first-child {
        border-right: 0;
    }
    .input-group .form-control:first-child, 
    .input-group-addon:first-child, 
    .input-group-btn:first-child > .btn, 
    .input-group-btn:first-child > .btn-group > .btn, 
    .input-group-btn:first-child > .dropdown-toggle, 
    .input-group-btn:last-child > .btn-group:not(:last-child) > 
    .btn, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .input-group-addon {
        // border: 1px solid #e1e5ea;
        // border-right-color: rgb(225, 229, 234);
        // border-right-style: solid;
        // border-right-width: 1px;
        // background-color: transparent;
        // border-radius: 0;
        // border-top-right-radius: 0px;
        // border-bottom-right-radius: 0px;
        // min-width: 45px;
        
        border-radius: 8px 0 0 8px;
        background: white;
        color: $CORPORATE_BRAND_COLOR;

    }
    .input-group-addon, 
    .input-group-btn {
        width: 1%;
        white-space: nowrap;
        vertical-align: middle;
    }
    .input-group .form-control,
    .input-group-addon, .input-group-btn {
        display: table-cell;
    }
    .input-group .form-control:last-child, 
    .input-group-addon:last-child, 
    .input-group-btn:first-child > .btn-group:not(:first-child) > .btn, 
    .input-group-btn:first-child > .btn:not(:first-child), 
    .input-group-btn:last-child > .btn, 
    .input-group-btn:last-child > .btn-group > .btn, 
    .input-group-btn:last-child > .dropdown-toggle {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .input-group .form-control, 
    .input-group-addon, .input-group-btn {
        display: table-cell;
    }
    .input-group-addon {
        border: 1px solid black;
        border-radius: 50px 0 0 50px;
        padding: 5px 10px;
        border-right: 0px solid transparent;
    }
    .input-group .form-control {
        position: relative;
        z-index: 2;
        float: left;
        width: 100%;
        margin-bottom: 0;
            border-radius: 0 16px 16px 0;
        font-size: 16px;
    }
    .form-control {
        font-size: 13px;
        height: 100%;
        border-radius: 0;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        box-shadow: none;
        border: 1px solid #e9e9e9;
        transition-duration: .5s;
    }
    //hidevalidation
    .login-form-group::before {
        display: none;
    }
    .acceptPolicy {
        position: absolute;
        top: -1px;
        left: -4px;
    }
    .opt-in-text {
        position: relative;
        p {
            font-size: 13px;
        }
    }
}

.company-logo {
    max-width: 100%;
}

.login-box {
  margin: 0, auto;
  width: 400px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 25px 85px;
  margin-bottom: 25px;

  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.portal-heading {
    font-size:15px;
    color:$CORPORATE_BRAND_COLOR;
    font-weight: bold;
}

.register-subtext {
    margin-top:10px;
    color:#737171 !important;
}
.landing-form-box {
    .login-form-group {
        float: left;
        max-width: 100%;
        margin-bottom: 10px;
        width:100%;
        .input-group {
            border-color:grey;padding-bottom: 18px; 
            input {
                border-color:$CORPORATE_GREY;
                padding:12px 10px 12px 12px !important; 
                border-radius:0 20px 20px 0px;

            }
        }
        .input-group-addon {
            border-color:$CORPORATE_GREY;
            padding: 4px 9px 3px 11px;
            border-radius:20px 0px 0px 20px;
        }
        input { ::webkit-input-placeholder {
            padding-left: 10px;} }
    }
}

//
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
//textarea:-webkit-autofill,
//textarea:-webkit-autofill:hover,
//textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,

select:-webkit-autofill:focus {
    border: 1px solid $CORPORATE_BLACK !important;
    border-radius: 50px;
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
    outline:none;
}

.cls-content  {
  .input-group {
    display: table !important;
    input {
      border-radius: 0 20px 20px 0px !important;
    }
    .input-group-addon {
      // display: none;
    }
  }
}