.table-scroll thead {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.table-scroll tbody {
    max-height: 150px;
    overflow-y: auto;
    display: block;
    width: 100%;
    table-layout: fixed;
}

.table-scroll tr {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.table-scroll td {

    height: 47px; // needed in order to keep rows from collapsing
}


body {
  overflow: hidden;
  perspective: 1000px;
}

.funky-show-hide.ng-hide-add {
  transform: rotateZ(0);
  transform-origin: right;
  transition: all 0.5s ease-in-out;
}

.funky-show-hide.ng-hide-add.ng-hide-add-active {
  transform: rotateZ(-135deg);
}

.funky-show-hide.ng-hide-remove {
  transform: rotateY(90deg);
  transform-origin: left;
  transition: all 0.5s ease;
}

.funky-show-hide.ng-hide-remove.ng-hide-remove-active {
  transform: rotateY(0);
}

.check-element {
  border: 1px solid black;
  opacity: 1;
  padding: 10px;
}
