//////////////////////////////////
/*           PANEL             */
//////////////////////////////////

.panel-heading  .panel-title {
  min-height: 5px !important;
}

.panel-info > .panel-heading > a {
    position: absolute;
    right: 10px;
    top: 2px;
    font-size: 16px;
    color: #fff !important;
    margin: 5px;
}

.dashboard-widget {
  float:left;
  width: 100%;
}

.no_panel_style {
  .panel {
  }
  .panel-body {
    min-height:50px;
  }
  .panel-footer {
    display: none;
  }
}

.panel {
  margin-bottom: $panel-margin-bottom;
  background-color: $panel-background-color;
  border: transparent !important;
  border-bottom-color: transparent !important;
  border-radius: $panel-border-radius;
  // box-shadow: $panel-box-shaddow;
  // -webkit-box-shadow:$panel-box-shaddow;
  border-radius: $panel-border-radius $panel-border-radius 0 0 !important;
}

.panel-body {
  position:relative;
}

.panel > .panel-heading {
  background:var(--engine-panel-header-background);
  border-color:var(--engine-panel-header-background);
}

.panel > .panel-heading {
    min-height:$panel-heading-min-height;
    border-radius:$panel-header-border-radius;
    border:none;
    position:relative;
    .panel-title{
      text-transform: initial;
      font-size: 16px !important;
      color:var(--engine-panel-header-color) !important;
      i {
        font-size:$panel-header-fontsize !important;
      }
    }
    .accordion-toggle > a i {
        font-size:$panel-header-fontsize !important;
        color:var(--engine-panel-header-color) !important;
    }
    > a {
      right: 0px;
      top: -2px;
      position: absolute;
      color:var(--engine-panel-header-color) !important;
      margin-right: 0;
      margin-top: 7px;
      font-size: 20px;
      width: 100%;
      text-align: right;
      cursor: pointer;
      padding-right: 10px;
      // color:$panel-titleDOTinfo-panel-heading-link_color !important;
    }
}

.panel-info .panel-body {
    padding: 15px;
    background:var(--engine-panel-background);
    background: var(--engine-panel-background);
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 0px;  
    border-top: 0;
    position: relative;
}

.panel-body p:last-child {
  margin-bottom: 0;
}
.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
}
.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}

/*           PANEL TITLE              */

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  min-height:$panel-heading-min-height !important;
  
    font-size: $panel-title-font-size;
    color:$panel-title-color !important;
    font-weight: $panel-title-font-weight;
    text-transform: $panel-title-text-transform;
    letter-spacing:$panel-title-letter-spacing;
    line-height:$panel-title-line-height;
    i {
      // color:$panel-title-icon-color !important;
      display:$panel-title-icon-display;
    }
    > a {
      color:$panel-title-link-color;
      outline: none;
      text-decoration: none;
      & > a:hover,
      & > a:focus {
        outline: none;
      }
      &:hover{
        color: $panel-title-link-hover-color;
        text-decoration: $panel-title-link-hover-text-decoration;
      }
      &:focus {
        color: $panel-title-link-focus-color;
        text-decoration: $panel-title-link-hover-text-decoration;
      }
    }
}

/*           PANEL FOOTER            */

.panel-footer {
  padding: $panel-footer-padding;
  border-top:var(--engine-panel-background) 0px solid;
  background-color: var(--engine-panel-background);
  border-bottom-right-radius:  $panel-footer-border-bottom-right-radius;
  border-bottom-left-radius: $panel-footer-border-bottom-left-radius;
}

.no_panel_style .panel-footer {
  opacity:0 !important;
}
.panel > .list-group {
  margin-bottom: 0;
}
.panel > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}
.panel > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
}
.panel > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
}
.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}
.list-group + .panel-footer {
  border-top-width: 0;
}
.panel > .table,
.panel > .table-responsive > .table,
.panel > .panel-collapse > .table {
  margin-bottom: 0;
}
.panel > .table caption,
.panel > .table-responsive > .table caption,
.panel > .panel-collapse > .table caption {
  padding-left: 15px;
  padding-right: 15px;
}
.panel > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child {
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  border-top-left-radius: 1px;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: 1px;
}
.panel > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 1px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 1px;
}
.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive {
  border-top: 1px solid #dddddd;
}
.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0;
}
.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
  border: 0;
}
.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}
.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}
.panel > .table-bordered > thead > tr:first-child > td,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.panel > .table-bordered > tbody > tr:first-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.panel > .table-bordered > thead > tr:first-child > th,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
.panel > .table-bordered > tbody > tr:first-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
  border-bottom: 0;
}
.panel > .table-bordered > tbody > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-bordered > tbody > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}
.panel > .table-responsive {
  border: 0;
  margin-bottom: 0;
}
// .panel-group {
//   margin-bottom: 23px;
// }
// .panel-group .panel {
//   margin-bottom: 0;
//   border-radius: 2px;
// }
// .panel-group .panel + .panel {
//   margin-top: 5px;
// }
// .panel-group .panel-heading {
//   border-bottom: 0;
// }
// .panel-group .panel-heading + .panel-collapse > .panel-body {
//   border-top: 1px solid #eeeeee;
// }
// .panel-group .panel-footer {
//   border-top: 0;
// }
// .panel-group .panel-footer + .panel-collapse .panel-body {
//   border-bottom: 1px solid #eeeeee;
// }
// .panel-default > .panel-heading {
//   color: #333333;
//   background-color: #f5f5f5;
//   border-color: #eeeeee;
// }
// .panel-default > .panel-heading + .panel-collapse > .panel-body {
//   border-top-color: #eeeeee;
// }
// .panel-default > .panel-heading .badge {
//   color: #f5f5f5;
//   background-color: #333333;
// }
// .panel-default > .panel-footer + .panel-collapse > .panel-body {
//   border-bottom-color: #eeeeee;
// }
// .panel-primary > .panel-heading {
//   color: #ffffff;
//   background-color: #009688;
//   border-color: #009688;
// }
// .panel-primary > .panel-heading + .panel-collapse > .panel-body {
//   border-top-color: #009688;
// }
// .panel-primary > .panel-heading .badge {
//   color: #009688;
//   background-color: #ffffff;
// }
// .panel-primary > .panel-footer + .panel-collapse > .panel-body {
//   border-bottom-color: #009688;
// }
// .panel-success > .panel-heading {
//   color: #ffffff;
//   background-color: #259b24;
//   border-color: #259b24;
// }
// .panel-success > .panel-heading + .panel-collapse > .panel-body {
//   border-top-color: #259b24;
// }
// .panel-success > .panel-heading .badge {
//   color: #259b24;
//   background-color: #ffffff;
// }
// .panel-success > .panel-footer + .panel-collapse > .panel-body {
//   border-bottom-color: #259b24;
// }
// .panel-info > .panel-heading {
//   color: #ffffff;
//   background-color: $panel-info-panel-heading-bg ;
//   border-color: #00bcd4;
// }
// .panel-info > .panel-heading + .panel-collapse > .panel-body {
//   border-top-color: #00bcd4;
// }
// .panel-info > .panel-heading .badge {
//   color: #00bcd4;
//   background-color: #ffffff;
// }
// .panel-info > .panel-footer + .panel-collapse > .panel-body {
//   border-bottom-color: #00bcd4;
// }
// .panel-warning > .panel-heading {
//   color: #ffffff;
//   background-color: #ffc107;
//   border-color: #ffc107;
// }
// .panel-warning > .panel-heading + .panel-collapse > .panel-body {
//   border-top-color: #ffc107;
// }
// .panel-warning > .panel-heading .badge {
//   color: #ffc107;
//   background-color: #ffffff;
// }
// .panel-warning > .panel-footer + .panel-collapse > .panel-body {
//   border-bottom-color: #ffc107;
// }
// .panel-danger > .panel-heading {
//   color: #ffffff;
//   background-color: #e51c23;
//   border-color: #e51c23;
// }
// .panel-danger > .panel-heading + .panel-collapse > .panel-body {
//   border-top-color: #e51c23;
// }
// .panel-danger > .panel-heading .badge {
//   color: #e51c23;
//   background-color: #ffffff;
// }
// .panel-danger > .panel-footer + .panel-collapse > .panel-body {
//   border-bottom-color: #e51c23;
// }


.panel.panel-info.laydown-datatable.panel-waaagh {
    background:var(--engine-page-background);
}


.panel-info .panel-body.inset-panel-body, .panel-body.inset-panel-body {
    background:var(--engine-filter-buddy-background) !important;
    border-top:$FILTER_BUDDY_BORDER_TOP;
    border-top-color:var(--engine-filter-buddy-background) !important;
    border-bottom:$FILTER_BUDDY_BORDER_BOTTOM;
    border-bottom-color:var(--engine-filter-buddy-background) !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    float:left;
}
.panel-heading-controls .nav-tabs.nav-justified > li a {
    border-bottom: 2px solid #dfd6d6ab;
} 


.panel-heading .btn-group-has-toggle {

    position: relative;
    position: absolute;
    right: 0;
    top: 4px;
    transform: scale(.73);
}


.full-page-action {
  padding:25px;
  background:var(--engine-page-background) !important;
  min-height: 100vh;
    overflow-y: auto;
    z-index: 90000;
    position: relative;
    &.panel {
      border-radius: 0 !important;
    }
    .panel-body > .panel {
      border-radius:8px 8px 0 0;
    }
  .panel > .panel-heading {

     h2.section-title {
      height: 30px;
      color:var(--engine-text-color-hightlight);
      font-size:25px;
      font-weight:bold;
      height:auto;
      margin:0;
      padding:0
     }
     h4 {
      font-size: 14px;
      color:var(--engine-text-color-default);
      margin:0;
      padding:0
     }
    h4.panel-title {
        color:var(--engine-text-color-default);
    }
  }
  .section-header {
    padding-bottom:30px;
    position:relative; 
    background:transparent !important;
    padding:10px 0 0 0px;
    width: 100%;
    margin-bottom:20px;
    h2.section-title {
      color:var(--engine-text-color-hightlight);
      font-size:25px;
      font-weight:bold;
      margin:0;
      padding:0
    }
    h4 {
      font-size: 14px;
      color:var(--engine-text-color-default);
      margin:0;
      padding:0
    }
  }
  .form-section .form-label {
    padding-left:0px;
  }
  
}


/////////////////////////////////////////
.panel-body {
  .list-group {
    margin: 30px 0;
  }
  .list-group-item {
    height:30px;
    box-sizing:border-box;
    color: #fefefe;
    height: 50px;
    position: relative;
    .badge {
      border-radius:8px;
      background:$CORPORATE_BRAND_COLOR;
      color:$CORPORATE_WHITE;
        padding: 8px;
        font-size: 16px;
        border-radius: 8px;
        float:left;
        position: absolute;
        left:0;
        top:0;
    }
    // .data-as-sortable-item-handle {
    // }
    // &.as-sortable-item {

    // }
    // &.as-sortable-placeholder {
    //  display: block;
    //  background:red;
    //  border-bottom: 1px solid aliceblue;
    //  border-top: 1px solid grey;
      //  .badge {
     //       border-radius:8px;
     //       background:$CORPORATE_BRAND_COLOR;
     //       color:$CORPORATE_WHITE;
      //      padding: 8px;
      //      font-size: 16px;
     //           border-radius: 8px;
     //     }
      // }
    .badge-h3 {
        color:$CORPORATE_BRAND_COLOR;
        float: left;
        display: block;
        font-size: 19px;
        line-height: 1em;
        width: 100%;
        margin-bottom: 2px;
        margin-left: 50px;
    }

    .badge-h4 {
        margin-left: 50px;
        color:$CORPORATE_GREY_DARK;
        float:left;
        font-size: 12px;
        line-height: 1em;        
    }
    &:first-of-type{
      border-top:0px solid transparent !important;
    }
    &:last-of-type{
      border-bottom:0px solid transparent !important;
    }
  }
}
