
.panel-control {
    height: 100%;
    position: relative;
    float: right;
    padding: 0 15px 0 5px;
}






.panel-heading-controls {
  .nav-tabs li {
    a {

    }
    &.active a {

    }
    // &.active a:hover {
    //     padding-top: 3px;
    // }
  }
}

.panel-heading-controls {
    position:absolute;
    bottom:-1px;
    top: initial;
    right:10px;
    width: 50%;
    .btn-group-has-toggle {
        position: absolute !important;
        right: 305px !important;
        top: 6px;
    }
    .nav-tabs {
      max-width: 100%;
      padding: $nv-padding;
      margin: $nv-margin;
        float: $nv-float;
        > li {
        padding:$nv-li-padding;
        margin:$nv-li-margin;
            a {
              margin-top:0;
              color:var(--engine-tab-color);
              background:var(--engine-tab-background);
              border:1px solid var(--engine-tab-background);
              cursor:$nv-li-a-cursor;
              padding:$nv-li-a-padding; //$nv-li-a_PADDING;
              margin:$nv-li-a-margin; //$nv-li-a_MARGIN;
              margin-top:5px;
              &:hover { // fixes some bug
                margin-top:0px;
                padding-top: 0px;
                padding-bottom: 5px;
              }
            }
        }
        > li.active {
          a {
            color:var(--engine-tab-active-color) !important;
            background:var(--engine-tab-active-background);
            // font-weight: bold;
            border:1px solid var(--engine-tab-active-background);
            &:hover {
              margin-top:5px;
              padding-bottom: 0px;
              padding-bottom:0px !important; // don't pop on hover for active
            }
          } // color wheel routletti
        }
      > li a:hover {
        color: white;
      }
    }

}


.well {
  padding: 20px;
  background: white;
  float: left;
  width: 100%;
  .form-section .form-label {
    padding-left: 0;
  }
  &.has-controls {
    padding-top: 34px;
    .panel-heading-controls {
      // bottom:auto !important;
      // z-index: 10;
      //   top: 5px !important;
    }
  }
  .tab-content {
    border:0px solid transparent;
  }
}


.well.has-controls {
    padding-top: 45px !important;
  .panel-heading-controls {
    z-index: 1;
    top:-1px !important;
    height: 40px;
    .nav-tabs li {
      a {
        font-size: 16px;
        margin-top:0;
        box-shadow: 0px 2px 3px #4446444d inset;;
        border-radius: 0 0 8px 8px !important;
        border-top: 1px solid #4446444D !important;
      }
      &.active a {
        box-shadow:initial;
        border-top: 1px solid transparent !important;
        // font-weight:bold;
      }
      &.active a:hover {
          padding-top: 0px;
          padding-bottom: 0px;
          padding-bottom:0px !important; 
      }
      a:hover {
          padding-top: 3px;
          margin-top:0px;
          padding-bottom: 0px;
      }
    }
  }
}

.client-setup-form {
  h3 {
      display: block;
      float: left;
      margin: 0;
      width: 100%;
      text-transform: uppercase;
      font-size: 18px;
      color: #282828;
      margin-top:10px;
  }
}
