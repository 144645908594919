.avatar-preview.size-120 {
    height: 120px;
    width: 120px;
    .layer {
        img {
            max-width: 120px !important;
            max-height: 120px !important;
            width: 120px !important;       
            height: 120px !important;       
        }
    } 
}

.avatar-preview.size-40 {
    height: 30px;
    width: 30px;
    .layer {
        img {
            max-width: 30px !important;
            max-height: 30px !important;
            width: 30px !important;       
            height: 30px !important;       
        }
    } 
}

// .avatar-preview.size-120 .layer img {
//     max-width: 120px !important;
//     max-height: 120px !important;
//     width: 120px !important;
//     height: 120px !important;
// }

// .avatar-preview {
//     width:400px;
//     height:400px;
//     margin:50px auto;
//     position: relative;
//     z-index:100;
//     background:blue;
//     span {
//         position:absolute;
//         top:0;
//         left:0;
//         right:0;
//         bottom:0;
//         // background:orange;
//         &.avatar_background {
//             z-index:220;
//         }
//         &.avatar_skin-color {
//             z-index:240
//         }
        
//         &.avatar_hair-style {
//             z-index:260;
//         }
        
//         &.avatar_outfit {
//             z-index:280
//         }
        
//         &.avatar_bling {
//             z-index:290
//         }
//         img {
//             max-width:100%;
//         }
//     }
// }

// .tab-content {
//     min-height: 530px;
// }

.contact-pic-title i{
  color: #fff;
  background-color: #222;
  font-size: 19px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 30px;
}

.contact-pic-title h3 {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 1px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  color: #595959;
}

.contact-picrues {
  height: 250px;
  overflow: auto;
}

.contact-pic-text h4{
  color: #595959;
  font-weight: bold;
  margin-bottom: 30px;
}

.contact-pic-text p {
  font-size: 14px;
  color: #000;
}

.create-btn {
    color: #fff;
    background-color: #ccc;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 5px 10px;
    font-weight: bold;
    line-height: 24px;
}

.create-btn:hover {
    color: #fff;
    background-color: #000;
    text-decoration: none;
}

.submit-btn {
    color: #fff;
    background-color: #ccc;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 5px 10px;
    font-weight: bold;
    line-height: 24px;
    border-radius: 20px;
}

.submit-btn:hover {
    color: #fff;
    background-color: #000;
    text-decoration: none;
}
.submit-btn.light-blue {    
    background-color: #00a2e8;    
}

.create-btn.light-blue:hover {
    background-color: #ccc;
}

.create-btn.light-blue {    
    background-color: #00a2e8;    
}

.submit-btn.light-blue:hover {
    background-color: #ccc;
}
.contact-pic-btn {
    margin-top: 10px;
}
.contact-picrues img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-bottom: 5px;

}
.bd-example.bd-example-tabs ul li a {
    color: #000;
    border-radius: 0px;
    font-size: 14px;
    padding: 4px 35px;
    margin-right: 3px;  
}
.bd-example.bd-example-tabs ul li .active {
    background-color: #fff;
    border-bottom: none;
}
.bd-example-tabs .tab-content {
    border-style: none;
    padding: 20px 0px 0px;
        overflow: hidden;
}
.bd-example-tabs .nav-tabs {
    border-bottom: none;
}
.bd-example-tabs .tab-content img {
    height: 133px;
    object-fit: cover;
    float: right;
}

.contact-pic-text .switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 22px;
}

.contact-pic-text .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.contact-pic-text .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #32CD32;
    -webkit-transition: .4s;
    transition: .4s;
}

.contact-pic-text .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.contact-pic-text input:checked + .slider {
  background-color: #ccc;
}


.contact-pic-text input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.contact-pic-text .slider.round {
  border-radius: 34px;
}

.contact-pic-text .slider.round:before {
  border-radius: 50%;
}
.avatar-warp {

    display: block;
    height: 40px;
    box-sizing: border-box;
    font-size: 14px;
    padding-top: 11px;
    float: left;
    margin-right: 10px;
    color: #ffffffa6;
}

.avatar-preview {

};

.avatar-preview {
    position: relative;
    height:20px;
    width:20px;
    &.large {
        height:160px;
        width:160px;
        .layer img {
            width:160px;
            max-width:160px;
            max-height:160px;
            height:160px;
        }

    }
    margin: 0 auto;

    .layer {
        position:absolute;
        img {
            object-fit: contain;
            top:0;
            left:0;
            position:absolute;
            max-width:20px;
            max-height:20px;
            height: 20px;
        }

        &.layer-1{
            z-index:100;
        }
        &.layer-2{
            z-index:200;
        }
        &.layer-3{
            z-index:300;
        }
        &.layer-4{
            // z-index:400;
            z-index:250;
        }
        &.layer-5{
            z-index:500;
        }
    }
}


.avatari-wrapper {
  .modal-title > i {

    border: 1px solid black;

    border-radius: 100%;

    padding: 3px;

    font-size: 9px;

    text-align: center;
    background:url('/extinctionr_2000.png');
  }
  .nav-tabs {
    padding:5px 10px;
    border-radius:0;
  }
}

.loading-avatar {
  img {
    max-width: 113px;
    max-height: 113px;
  }
}


.avatar-tabs-wrapper {
    // background:orange;
}

// 0800 280 0351
// psc10001418335

// basic things like not mailto links in emails
.avatar-tabs-wrapper {
    .nav-tabs.nav-justified {
        // background-color: orange;
        > li > a {
            cursor:pointer;
            font-size: 10px !important;
            padding: 5px 10px;
            border-radius:0;
            &.active {
            // background:green;

            }
        }

    }
}

.avatar-preview-section {
    padding:10px;
    .image-lock {
            width: 64px;
            height: 64px;
            background: #bdb9b22b;
            border-radius: 4px;
            border: 0;
            position: relative;
            background: white;
            text-align: center;
            margin: 0 auto;
            padding: 0;
        
        > img {
            width: 64px;
            height: 64px;
            margin-left: 0;
            margin-top: 0;
        }
        > button {
            width: 64px;
            border-radius: 4px;
            position: absolute;
            height: 64px;
            border: 1px solid transparent;
            left: 0px;
            bottom: 0;
            top: 0;
            left: 0;
            font-size: 10px;
            right: 0;
            background: transparent;
            line-height: 14px;
        }
    }
}

.borsec {
    // background:green;
    .tab-content {
        // padding:5px;
        // background:blue;
        overflow: auto;
        padding: 0px;
        border-style: solid;
        border-color: #ddd;
        border-width: 0 1px 1px 1px;
        border:0;
        position: relative;
        height: 123px;
        width: 123px;
    }
    .tab-pane {
        background:white;
        width: 123px;
        height: 113px;
        position: absolute;
        top: 0px;
        left: 0px;
        text-align: center;
        p {
    margin: 0 0 11.5px;
    font-size: 14px;
    text-align: left;
    line-height: 1.1em;
        }
    }
}


/* start 'enter' transition on main view */
.borsec .tab-pane.ng-leave {
    /* transition on enter for .5s */
    transition: 1.5s;
    // transform:scale(1);
    /* start with opacity 0 (invisible) */
    opacity: 1;
}

/* end 'enter' transition on .engineengine view */
.borsec .tab-pane.ng-leave-active {
    /* end with opacity 1 (fade in) */
    // transform:scale(.7);
    opacity: 1;
}


/* start 'enter' transition on main view */
.borsec .tab-pane.ng-enter {
    /* transition on enter for .5s */
    transition: 1.5s;
    /* start with opacity 0 (invisible) */
    // transform:scale(.7);
    opacity: 0;
}

/* end 'enter' transition on .engineengine view */
.borsec .tab-pane.ng-enter-active {
    /* end with opacity 1 (fade in) */
    // transform:scale(1);
    opacity: 1;
}


.avatar-preview .layer > img {
    transition: 1.5s;
    /* start 'enter' transition on main view */
    &.ng-leave {
        /* transition on enter for .5s */
        transition: 1.5s;
        // transform:scale(1);
        /* start with opacity 0 (invisible) */
        opacity: 1;
    }
        /* end 'enter' transition on .engineengine view */
    &.ng-leave-active {
        /* end with opacity 1 (fade in) */
        // transform:scale(.7);
        opacity: 1;
    }
    /* start 'enter' transition on main view */
    &.ng-enter {
        /* transition on enter for .5s */
        transition: 1.5s;
        /* start with opacity 0 (invisible) */
        // transform:scale(.7);
        opacity: 0;
    }
    /* end 'enter' transition on .engineengine view */
    &.ng-enter-active {
        /* end with opacity 1 (fade in) */
        // transform:scale(1);
        opacity: 1;
    }

}

// .avatar-tabs-wrapper {
//     .nav-tabs { // ul
//         li a {
//             color:gold;
//             span {
//             }

//         }
//     }
// }

    .nav-tabs {
      // min-width: 346px;
      max-width: 100%;
      padding: $nv-padding;
      margin: $nv-margin;
        float: $nv-float;
        > li {
        padding:$nv-li-padding;
        margin:$nv-li-margin;
            a {
              cursor:$nv-li-a-cursor;
              // border:dashed green 4px: //$_BORDER;
              background:$nv-li-a-background; //li_a_BG;
              border-radius: $nv-li-a-border-radius; //$nv-li-a_BORDER_RADIUS;
              padding:$nv-li-a-padding; //$nv-li-a_PADDING;
              margin:$nv-li-a-margin; //$nv-li-a_MARGIN;
              border:$nv-li-a-border;
              color: $nv-li-a-color; //$nv-li-a_COLOR;
              margin-top:5px;
              &:hover { // fixes some bug
                margin-top:0px;
                padding-top: 0px;
                padding-bottom: 5px;
              }
            }
        }
        > li.active {
          a {
            border-radius:$nv-li-a-active-border-radius;
            border:$nv-li-a-active-border;
            color:$nv-li-a-active-color;
            background:$nv-li-a-active-background;
          } // color wheel routletti


        }
    }