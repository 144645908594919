.pattern-loader {
	transition:all 1s;
	position:absolute;
	// min-height:200px;
	top:0;
	bottom:0;
	left:0;
	right:0;
	width: 100%;
	z-index: 909;
	
	&.ng-enter, &.ng-leave {
	  transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
	}

	&.ng-enter,
	&.ng-leave.ng-leave-active {
	  opacity:0;
	}

	&.ng-leave,
	&.ng-enter.ng-enter-active {
	  opacity:1;
	}
}

.pattern-loading-condition {
	transition:all 1s;
	&.ng-enter, &.ng-leave {
	  transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s;
	}

	&.ng-enter,
	&.ng-leave.ng-leave-active {
	  opacity:0;
	}

	&.ng-leave,
	&.ng-enter.ng-enter-active {
	  opacity:1;
	}
}


.pop-in-loader {
  animation-delay:.5;
  animation: pop-in-loader 0.4s;
}

@keyframes pop-in-loader {
  from {
    opacity:.5;
    // transform: translateY(7px);
  }
}

 .inventory-loader {
    width:100%;
    height:100%;
    background:#dddddd8a;
    z-index:9999;
    position:absolute;
    top:0;
    left:0;
    // padding-top:20px;
    padding-top:0px;
    z-index: 999999;
    bottom:0;
    overflow:hidden;
        min-height: 100vh;

}

.inventory-filter-panel {
	background:orange;
	// float:left;
	transition:all .7s;
	opacity:1;
	transform: scale(1);
	width: 100%;
	&.visible {
		// background:red;
		// transform: scale(0.95);
    	// opacity: .5;
	}
	&.hiding {
		transition:all .4s;
		// background:gold;
		transform: scale(0.96);
    	opacity:0;
	}
}

.foobarge {
	// background:orange;
	// float:left;
	// transition:all .6s;
	// opacity:1;
		// transform: scale(1);
	&.visible {
		// background:red;
		// transform: scale(0.95);
    	// opacity: .5;
	}
	&.hiding {
		// transition:all .4s;
		// background:gold;
		// transform: scale(0.96);
  //   	opacity:0;
	}
}

// used to display loading spinner while panel is open
:not(.panel-loader-collapsed).panel-has-loader {
		min-height: 200px !important;
}
