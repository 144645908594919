$profileWidth:125px;
.profile-pi {
  .validate-if-required {
    display: none;
  }
  .image p {
    text-align: center;
  }
  .image img {
        height: $profileWidth !important;
    width: $profileWidth !important;
    max-height: $profileWidth !important;
    max-width: $profileWidth !important;
  }
}
.profile-nicename {}
.profile-email {}

.file-cropper-overlay {
    
    position: absolute;
    background: #E4E4E4;
    left: -19px;
    top: -25px;
    z-index: 4;
    padding-bottom: 15px;
    min-width: 100%;
    right: -20px;
    height: 400px;
  .custom-file-upload {
    padding: 10px 10px 0;
    box-sizing: border-box;
  }
}
.cropArea {
  overflow: hidden;
  width:250px;
  height:250px;
  margin: 0 auto;
}
.confirm-crop {
  margin-top: 10px;
  text-align: left;
  padding: 10px;
      margin: 0 auto;
    display: inline;
}
.checkered{
    height: 240px;
    background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
    background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
    background-repeat: repeat, repeat;
    background-position: 0px 0, 5px 5px;
    -webkit-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
    -webkit-background-origin: padding-box, padding-box;
    background-origin: padding-box, padding-box;
    -webkit-background-clip: border-box, border-box;
    background-clip: border-box, border-box;
    -webkit-background-size: 10px 10px, 10px 10px;
    background-size: 10px 10px, 10px 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-shadow: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
    -webkit-transform: scaleX(1) scaleY(1) scaleZ(1);
    transform: scaleX(1) scaleY(1) scaleZ(1);
}


button.profile-preview {
    position: relative;
    &::after {
      position: absolute;
      content: 'Edit';
      top: 37px;
      background: white;
      right: 0;
      border: 1px solid #c3c3c3;
      padding: 7px;
      font-size: 10px;
      line-height: 0;
      border-radius: 8px;
      font-weight: bold;
      text-transform: uppercase;
      color: #282828 !important;
    }
}