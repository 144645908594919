
.filter-buddy-section {
    .datepicker { border-radius:8px; }
    .datepicker-days tbody tr:nth-of-type(odd){ background:#8080801a; }
    .datepicker-days tbody tr:nth-of-type(odd) .cw{ background:#444444eb;color:#818181; }
    .datepicker-days tbody tr:nth-of-type(even){}
    .datepicker-days tbody tr:nth-of-type(even) .cw{ background:#444;color:#818181; }
    // .datepicker-months tbody tr td span:nth-of-type(even) .cw{ background:#444;color:#818181; }
}

.datepicker-months,
.datepicker-years {
    max-width: 224px;
    .prev,
    .next,
    .datepicker-switch {
        color:white;
        text-align: center;
        font-weight: 100;
        cursor: pointer;
    }
    tbody tr td span {
    	display: block;
    	float: left;
    	padding: 3px;
    	width: 35px;
    	box-sizing: border-box;
    	text-align: center;
    }
}
.datepicker-years tbody tr td span {
    width: 42px;
}

.datepicker {
    // datepicker datepicker-dropdown dropdown-menu datepicker-orient-left datepicker-orient-top
    // z-index: 100;
    z-index: 2222225 !important;
    tr td {
        cursor:pointer;
    }
    &::before {
        position: absolute;
        top:0px;
        height:40px;
        z-index:-1;
        background: $CORPORATE_ACTION_COLOR;
        width:100%;
        border-radius:8px 8px 0 0;
        content:'';
    }
    .datepicker-days {
        position: relative;
        z-index: 13;
         thead tr th {
            font-weight: 100;
         }
         thead tr:first-of-type th {
            color: white;
         }
         thead tr:nth-child(2) th.cw {
            background:#88888b38;
         }
    }
}