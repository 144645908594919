// html input[type="button"],
// input[type="reset"],
// input[type="submit"] {
//   -webkit-appearance: button;
//   cursor: pointer;
// }
// button[disabled],
// html input[disabled] {
//   cursor: default;
// }
// button::-moz-focus-inner,
// input::-moz-focus-inner {
//   border: 0;
//   padding: 0;
// }
// input {
//   line-height: normal;
// }
// input[type="checkbox"],
// input[type="radio"] {
//   box-sizing: border-box;
//   padding: 0;
// }
// input[type="number"]::-webkit-inner-spin-button,
// input[type="number"]::-webkit-outer-spin-button {
//   height: auto;
// }
// input[type="search"] {
//   -webkit-appearance: textfield;
//   -moz-box-sizing: content-box;
//   -webkit-box-sizing: content-box;
//   box-sizing: content-box;
// }
// input[type="search"]::-webkit-search-cancel-button,
// input[type="search"]::-webkit-search-decoration {
//   -webkit-appearance: none;
// }
// input,
// button,
// select,
// textarea {
//   font-family: inherit;
//   font-size: inherit;
//   line-height: inherit;
//   font-weight: 300;
// }
// fieldset {
//   padding: 0;
//   margin: 0;
//   border: 0;
//   min-width: 0;
// }
// legend {
//   display: block;
//   width: 100%;
//   padding: 0;
//   margin-bottom: 23px;
//   font-size: 21px;
//   line-height: inherit;
//   color: #333333;
//   border: 0;
//   border-bottom: 1px solid #e5e5e5;
// }
// label {
//   display: inline-block;
//   max-width: 100%;
//   margin-bottom: 5px;
//   font-weight: bold;
// }
// input[type="search"] {
//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   box-sizing: border-box;
// }
// input[type="radio"],
// input[type="checkbox"] {
//   margin: 4px 0 0;
//   margin-top: 1px \9;
//   line-height: normal;
// }
// input[type="file"] {
//   display: block;
// }
// input[type="range"] {
//   display: block;
//   width: 100%;
// }
// select[multiple],
// select[size] {
//   height: auto;
// }
// input[type="file"]:focus,
// input[type="radio"]:focus,
// input[type="checkbox"]:focus {
//   outline: thin dotted;
//   outline: 5px auto -webkit-focus-ring-color;
//   outline-offset: -2px;
// }
// output {
//   display: block;
//   padding-top: 9px;
//   font-size: 14px;
//   line-height: 1.666;
//   color: #555555;
// }
// .form-control {
//   display: block;
//   width: 100%;
//   height: 41px;
//   padding: 8px 12px;
//   font-size: 14px;
//   line-height: 1.666;
//   color: #555555;
//   background-color: #ffffff;
//   background-image: none;
//   border: 1px solid #cccccc;
//   border-radius: 2px;
//   font-weight: 300;
//   -webkit-transition: border-color ease-in-out .15s;
//   -o-transition: border-color ease-in-out .15s;
//   transition: border-color ease-in-out .15s;
// }
// .form-control:focus {
//   border-color: #009688;
//   outline: 0;
// }
// .form-control::-moz-placeholder {
//   color: #999999;
//   opacity: 1;
// }
// .form-control:-ms-input-placeholder {
//   color: #999999;
// }
// .form-control::-webkit-input-placeholder {
//   color: #999999;
// }
// .form-control[disabled],
// .form-control[readonly],
// fieldset[disabled] .form-control {
//   cursor: not-allowed;
//   background-color: #eeeeee;
//   opacity: 1;
// }
// textarea.form-control {
//   height: auto;
// }
// input[type="search"] {
//   -webkit-appearance: none;
// }
// input[type="date"],
// input[type="time"],
// input[type="datetime-local"],
// input[type="month"] {
//   line-height: 41px;
//   line-height: 1.666 \0;
// }
// input[type="date"].input-sm,
// input[type="time"].input-sm,
// input[type="datetime-local"].input-sm,
// input[type="month"].input-sm {
//   line-height: 24px;
//   line-height: 1.5 \0;
// }
// input[type="date"].input-lg,
// input[type="time"].input-lg,
// input[type="datetime-local"].input-lg,
// input[type="month"].input-lg {
//   line-height: 46px;
//   line-height: 1.33 \0;
// }
// .form-group {
//   margin-bottom: 15px;
// }
// .radio,
// .checkbox {
//   position: relative;
//   display: block;
//   margin-top: 10px;
//   margin-bottom: 10px;
// }
// .radio label,
// .checkbox label {
//   min-height: 23px;
//   padding-left: 20px;
//   margin-bottom: 0;
//   font-weight: normal;
//   cursor: pointer;
// }
// .radio input[type="radio"],
// .radio-inline input[type="radio"],
// .checkbox input[type="checkbox"],
// .checkbox-inline input[type="checkbox"] {
//   position: absolute;
//   margin-left: -20px;
//   margin-top: 4px \9;
// }
// .radio + .radio,
// .checkbox + .checkbox {
//   margin-top: -5px;
// }
// .radio-inline,
// .checkbox-inline {
//   display: inline-block;
//   padding-left: 20px;
//   margin-bottom: 0;
//   vertical-align: middle;
//   font-weight: normal;
//   cursor: pointer;
// }
// .radio-inline + .radio-inline,
// .checkbox-inline + .checkbox-inline {
//   margin-top: 0;
//   margin-left: 10px;
// }
// input[type="radio"][disabled],
// input[type="checkbox"][disabled],
// input[type="radio"].disabled,
// input[type="checkbox"].disabled,
// fieldset[disabled] input[type="radio"],
// fieldset[disabled] input[type="checkbox"] {
//   cursor: not-allowed;
// }
// .radio-inline.disabled,
// .checkbox-inline.disabled,
// fieldset[disabled] .radio-inline,
// fieldset[disabled] .checkbox-inline {
//   cursor: not-allowed;
// }
// .radio.disabled label,
// .checkbox.disabled label,
// fieldset[disabled] .radio label,
// fieldset[disabled] .checkbox label {
//   cursor: not-allowed;
// }
// .form-control-static {
//   padding-top: 9px;
//   padding-bottom: 9px;
//   margin-bottom: 0;
// }
// .form-control-static.input-lg,
// .form-control-static.input-sm {
//   padding-left: 0;
//   padding-right: 0;
// }
// .input-sm,
// .form-group-sm .form-control {
//   height: 24px;
//   padding: 2px 10px;
//   font-size: 12px;
//   line-height: 1.5;
//   border-radius: 2px;
// }
// select.input-sm,
// select.form-group-sm .form-control {
//   height: 24px;
//   line-height: 24px;
// }
// textarea.input-sm,
// textarea.form-group-sm .form-control,
// select[multiple].input-sm,
// select[multiple].form-group-sm .form-control {
//   height: auto;
// }
// .input-lg,
// .form-group-lg .form-control {
//   height: 46px;
//   padding: 10px 22px;
//   font-size: 18px;
//   line-height: 1.33;
//   border-radius: 6px;
// }
// select.input-lg,
// select.form-group-lg .form-control {
//   height: 46px;
//   line-height: 46px;
// }
// textarea.input-lg,
// textarea.form-group-lg .form-control,
// select[multiple].input-lg,
// select[multiple].form-group-lg .form-control {
//   height: auto;
// }
// .has-feedback {
//   position: relative;
// }
// .has-feedback .form-control {
//   padding-right: 51.25px;
// }
// .form-control-feedback {
//   position: absolute;
//   top: 0;
//   right: 0;
//   z-index: 2;
//   display: block;
//   width: 41px;
//   height: 41px;
//   line-height: 41px;
//   text-align: center;
//   pointer-events: none;
//   margin-top: 14px;
// }
// .input-lg + .form-control-feedback {
//   width: 46px;
//   height: 46px;
//   line-height: 46px;
// }
// .input-sm + .form-control-feedback {
//   width: 24px;
//   height: 24px;
//   line-height: 24px;
// }
// .has-success .help-block,
// .has-success .control-label,
// .has-success .radio,
// .has-success .checkbox,
// .has-success .radio-inline,
// .has-success .checkbox-inline,
// .has-success.radio label,
// .has-success.checkbox label,
// .has-success.radio-inline label,
// .has-success.checkbox-inline label {
//   color: #78a355;
// }
// .has-success .form-control {
//   border-color: #78a355;
//   -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
//   box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
// }
// .has-success .form-control:focus {
//   border-color: #5f8144;
// }
// .has-success .input-group-addon {
//   color: #78a355;
//   border-color: #78a355;
//   background-color: #f0ffe4;
// }
// .has-success .form-control-feedback {
//   color: #78a355;
// }
// .has-warning .help-block,
// .has-warning .control-label,
// .has-warning .radio,
// .has-warning .checkbox,
// .has-warning .radio-inline,
// .has-warning .checkbox-inline,
// .has-warning.radio label,
// .has-warning.checkbox label,
// .has-warning.radio-inline label,
// .has-warning.checkbox-inline label {
//   color: #d5973b;
// }
// .has-warning .form-control {
//   border-color: #d5973b;
//   -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
//   box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
// }
// .has-warning .form-control:focus {
//   border-color: #b67c27;
// }
// .has-warning .input-group-addon {
//   color: #d5973b;
//   border-color: #d5973b;
//   background-color: #fefaeb;
// }
// .has-warning .form-control-feedback {
//   color: #d5973b;
// }

// .input-group {
//   position: relative;
//   display: table;
//   border-collapse: separate;
// }
// .input-group[class*="col-"] {
//   float: none;
//   padding-left: 0;
//   padding-right: 0;
// }
// .input-group .form-control {
//   position: relative;
//   z-index: 2;
//   float: left;
//   width: 100%;
//   margin-bottom: 0;
// }
// .input-group-lg > .input-group-addon,
// .input-group-lg > .input-group-btn > .btn {
//   height: 46px;
//   padding: 10px 22px;
//   font-size: 18px;
//   line-height: 1.33;
//   border-radius: 6px;
// }
// select.input-group-lg > .form-control,
// select.input-group-lg > .input-group-addon,
// select.input-group-lg > .input-group-btn > .btn {
//   height: 46px;
//   line-height: 46px;
// }
// textarea.input-group-lg > .form-control,
// textarea.input-group-lg > .input-group-addon,
// textarea.input-group-lg > .input-group-btn > .btn,
// select[multiple].input-group-lg > .form-control,
// select[multiple].input-group-lg > .input-group-addon,
// select[multiple].input-group-lg > .input-group-btn > .btn {
//   height: auto;
// }
// .input-group-sm > .form-control,
// .input-group-sm > .input-group-addon,
// .input-group-sm > .input-group-btn > .btn {
//   height: 24px;
//   padding: 2px 10px;
//   font-size: 12px;
//   line-height: 1.5;
//   border-radius: 2px;
// }
// select.input-group-sm > .form-control,
// select.input-group-sm > .input-group-addon,
// select.input-group-sm > .input-group-btn > .btn {
//   height: 24px;
//   line-height: 24px;
// }
// textarea.input-group-sm > .form-control,
// textarea.input-group-sm > .input-group-addon,
// textarea.input-group-sm > .input-group-btn > .btn,
// select[multiple].input-group-sm > .form-control,
// select[multiple].input-group-sm > .input-group-addon,
// select[multiple].input-group-sm > .input-group-btn > .btn {
//   height: auto;
// }
// .input-group-addon,
// .input-group-btn,
// .input-group .form-control {
//   display: table-cell;
// }
// .input-group-addon:not(:first-child):not(:last-child),
// .input-group-btn:not(:first-child):not(:last-child),
// .input-group .form-control:not(:first-child):not(:last-child) {
//   border-radius: 0;
// }
// .input-group-addon,
// .input-group-btn {
//   width: 1%;
//   white-space: nowrap;
//   vertical-align: middle;
// }
// .input-group-addon {
//   padding: 8px 12px;
//   font-size: 14px;
//   font-weight: normal;
//   line-height: 1;
//   color: #555555;
//   text-align: center;
//   background-color: #eeeeee;
//   border: 1px solid #cccccc;
//   border-radius: 4px;
// }
// .input-group-addon.input-sm {
//   padding: 2px 10px;
//   font-size: 12px;
//   border-radius: 2px;
// }
// .input-group-addon.input-lg {
//   padding: 10px 22px;
//   font-size: 18px;
//   border-radius: 6px;
// }
// .input-group-addon input[type="radio"],
// .input-group-addon input[type="checkbox"] {
//   margin-top: 0;
// }
// .input-group .form-control:first-child,
// .input-group-addon:first-child,
// .input-group-btn:first-child > .btn,
// .input-group-btn:first-child > .btn-group > .btn,
// .input-group-btn:first-child > .dropdown-toggle,
// .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
// .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
//   border-bottom-right-radius: 0;
//   border-top-right-radius: 0;
// }
// .input-group-addon:first-child {
//   border-right: 0;
// }
// .input-group .form-control:last-child,
// .input-group-addon:last-child,
// .input-group-btn:last-child > .btn,
// .input-group-btn:last-child > .btn-group > .btn,
// .input-group-btn:last-child > .dropdown-toggle,
// .input-group-btn:first-child > .btn:not(:first-child),
// .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
//   border-bottom-left-radius: 0;
//   border-top-left-radius: 0;
// }
// .input-group-addon:last-child {
//   border-left: 0;
// }
// .input-group-btn {
//   position: relative;
//   font-size: 0;
//   white-space: nowrap;
// }
// .input-group-btn > .btn {
//   position: relative;
//   height: 41px;
// }
// .input-group-btn > .btn + .btn {
//   margin-left: -1px;
// }
// .input-group-btn > .btn:hover,
// .input-group-btn > .btn:focus,
// .input-group-btn > .btn:active {
//   z-index: 2;
// }
// .input-group-btn:first-child > .btn,
// .input-group-btn:first-child > .btn-group {
//   margin-right: -1px;
// }
// .input-group-btn:last-child > .btn,
// .input-group-btn:last-child > .btn-group {
//   margin-left: -1px;
// }

.form-section {
  float: left;
  width: 100%;
  position: relative;
  padding: 5px 0 5px;

  &.light {
    background: white;
  }

  &.dark {
    background: unquote($CORPORATE_HEADER_BG+'1a');
  }

  .form-label {
    padding-left: 30px;
    float: left;
    width: 150px;
    float: left;
    box-sizing: border-box;

    label {
      font-size: 16px;
    }
  }

  &.label-above {
    .form-items {
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  .form-items {
    float: left;
    width: 100%;
    max-width: 350px;

    .form-group {
      position: relative;
    }

    .form-group::after {
      content: '\f071';
      height: 100%;
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 300;
      min-height: 35px;
      height: 100%;
      width: 35px;
      background: $CORPORATE_ACTION_COLOR;
      border-radius: 0 $base-INPUT-BORDER-RADIUS $base-INPUT-BORDER-RADIUS 0;
      display: block;
      font-family: 'Font Awesome 5 Pro';
      color: $CORPORATE_WHITE;
      box-sizing: border-box;
      padding: 5px 10px;
      border: 1px solid $CORPORATE_BLACK;
      border-left: 1px solid transparent;
      float: left;
    }
  }

  &.required {
    .form-items .form-group::after {

      content: '\f12a';
    }
  }

  &.required.valid {
    .form-items .form-group::after {

      content: '\f00c';
    }
  }
}

.super-block {
  h5 {
    font-size: 12px;
    color: $CORPORATE_BLACK;
    text-align: center;
    font-weight: bold;
  }

  @include respond-below(md) {
    h5 {
      text-align: right;

    }
  }


  .lost-wrench {
    text-align: left;
    font-size: 12px;
    display: block;
    line-height: 1.1em;
    border-radius: 16px;
    border: 1px solid #444;
    padding: 9px;
    min-height: 34px;
    display: block;

    &.tl-right {
      text-align: right;
    }
  }

  width: 100%;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;

  .col-md-2 {
    width: 14.285714285714285714285714285714%;
  }

  @include respond-below(md) {

    .seven-cols .col-md-1,
    .col-sm-2 {
      width: 100%;
      float: left;
    }
    .seven-cols .col-lg-1 {
      width: 100%;
      *width: 100%;
    }
  }


  @media (min-width: 992px) {
    .seven-cols .col-md-1,
    .seven-cols .col-sm-1,
    .seven-cols .col-lg-1 {
      width: 14.285714285714285714285714285714%;
      *width: 14.285714285714285714285714285714%;
    }
  }


  @media (min-width: 1200px) {
    .seven-cols .col-md-1,
    .seven-cols .col-sm-1,
    .seven-cols .col-lg-1 {
      width: 14.285714285714285714285714285714%;
      *width: 14.285714285714285714285714285714%;
    }
  }

  .row-full {
    width: 100%;
    float: left;
    max-height: 70px;

    .itemdd {
      width: 130px;
      float: left;
      padding: 10px;
      box-sizing: border-box;

      span {
        min-height: 32px;
        width: 100%;
        display: block;
        border-radius: $base-INPUT-BORDER-RADIUS;
        background: $CORPORATE_WHITE;
        // border: 1px solid $HEINEKENGREENLIGHT;
        border: 1px solid $CORPORATE_BLACK;
        line-height: 1.1em;
        font-size: 13px;
        font-size: 13px;
        padding: 8px 5px;

        &.bf-totals-row {
          border: 0px solid transparent;
          padding: 0;
        }
      }

      &.currency {
        position: relative;
        // padding-left: 32px;
        box-sizing: border-box;

        span {
          text-align: right;
        }

        input {
          text-align: right;
        }

        .form-control {
          padding-left: 32px;
        }

        &.sele::before { // for modifiers % £ // ::after valiadtion
          height: 32px !important;
          border: 1px solid gainsboro;
          background: $CORPORATE_WHITE;
          border-right: 1px solid $CORPORATE_ACTION_COLOR;
        }

        &::before { // for modifiers % £ // ::after valiadtion
          position: absolute;
          z-index: 99999;
          content:$BASE_CURRENCY_CODE;
          width: 32px;
          top: 2px;
          right: 35px;
          min-height: 32px;
          height: 32px;
          padding: 2px 10px;
          border-radius: $base-INPUT-BORDER-RADIUS 0 0 $base-INPUT-BORDER-RADIUS;
          font-size: 12px;
          // border: 1px solid $HEINEKENGREENLIGHT;
          // border-right: 1px solid $HEINEKENGREENLIGHT;
          // color:$CORPORATE_BLACK;
          // background:$HEINEKENGREENLIGHT;
          font-family: 'Font Awesome 5 Pro';
        }

      }
    }
  }
}

.input-group.calendar {
  position: relative;
  box-sizing: border-box;

  span {
    text-align: right;
  }

  input {
    // padding-left: 32px;
  }

  .form-control {
    // text-align: left;
    text-align: left;
    // padding-left: 38px;
    background-image: url(/calendar-alt-solid.png) !important;
    background-repeat: no-repeat !important;
    background-size: 12px !important;
    background-position: right 15px center !important;
  }

  &.sele::before { // for modifiers % £ // ::after valiadtion
    height: 32px !important;
    background: $CORPORATE_WHITE;
  }

  // &::before { // for modifiers % £ // ::after valiadtion
  //   position: absolute;
  //   z-index: 999;
  //   content: '\f133';
  //   // content:'\f154';
  //   width: 32px;
  //   top: 2px;
  //   right: 35px;
  //   min-height: 32px;
  //   height: 32px;
  //   padding: 2px 10px;
  //   border-radius: $base-INPUT-BORDER-RADIUS 0 0 $base-INPUT-BORDER-RADIUS;
  //   font-size: 12px;
  //   color: $CORPORATE_BLACK;
  //   text-align: left;
  //   // background:$CORPORATE_WHITE;
  //   // border: 1px solid gainsboro;
  //   // border-right: 1px solid $HEINEKENGREENLIGHT;
  //   // background:$CORPORATE_WHITE;
  //   font-family: 'Font Awesome 5 Pro';
  // }

}


// .activity-filter-section .form-group .form-control, .activity-forms .form-group .form-control {
//     height: 35px;
//     border: 1px solid #444 !important;
//     background: #fefefe !important;
//     color: #444 !important;
//     border-radius: 8px !important;
//     padding: 4px 4px 4px 10px;
//     box-sizing: border-box;
// }

// .activity-filter-section .form-group .form-control, .activity-forms .form-group .form-control {
//     height: 35px;
//     border: 1px solid $HEINEKENGREENLIGHT !important;
//     background: #fefefe !important;
//     color: $HEINEKENGREENLIGHT !important;
//     border-radius: 8px !important;
//     padding: 4px 4px 4px 10px;
//     box-sizing: border-box;
// }

.form-section.disabled { // hide extra validation for disabled fields, workaround hack as required not driven
  .form-items .form-group::after {
    content: unset !important;
  }
}

.activity-filter-section .filter-form .form-group {
  width: 100% !important;
}

#ui-container .form-group .input-group .form-control[disabled=disabled] {
  background: red;
  border-color: transparent !important;
}

// sub-repeating-form-field

#ui-container .pageType-campaign .chosen-container-single .chosen-single div b::before {

  // top: 4px;
  top: 0px;

}


#ui-container .pageType-campaign.readonly-form_type {
  // background:red;
  .input-group.calendar::before {
    display: none;
  }

  .form-group .input-group .chosen-container.chosen-disabled .chosen-single {
    background: transparent !important;
    margin-left: -3px;
    margin-top: -7px;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: transparent !important;
  }

  ::-moz-placeholder { /* Firefox 19+ */
    color: transparent !important;
  }

  :-ms-input-placeholder { /* IE 10+ */
    color: transparent !important;
  }

  :-moz-placeholder { /* Firefox 18- */
    color: transparent !important;
  }

  .super-block .lost-wrench {
    color: white !important;
    background: #c3c3c3 !important;
    text-align: left;
    border: 0px;
  }

  .form-group.currency {
    &::before {
      color: #444;
      border-right: 0px !important;
      margin-top: -1px !important;
      //     text-align: left;
      //     position: absolute;
      //     z-index: 99;
      //     content: "\f154";
      //     width: 30px;
      left: -10px;
    }

    .input-group .form-control[disabled=disabled] {
      text-align: left;
      margin-left: 26px;
    }
  }

  .form-group .input-group .chosen-container.chosen-disabled a {
    > span {
      padding-left: 10px;
      background: #c3c3c3 !important;
      color: white !important;
    }
  }

  .form-group .input-group .chosen-container.chosen-disabled .chosen-choices li.search-choice {
    // background: transparent !important;
    background: #c3c3c3 !important;

    span {
      color: white !important;
      // background: #c3c3c3 !important;
      font-size: 16px;

    }

    .search-choice-close {
      display: none;
    }
  }

  .form-group .input-group .chosen-container.chosen-disabled a.chosen-default > span {

    background: transparent !important;
  }


  .form-group .input-group .chosen-container.chosen-disabled .chosen-choices {

  }

  .form-group .input-group .chosen-container.chosen-disabled .chosen-single {
    padding-left: 0 !important;

    b {
      display: none;
    }
  }

  .chosen-container-multi.chosen-disabled:before {
    display: none;
  }

  .chosen-container-multi.chosen-disabled {
    background: transparent !important;

  }

  .form-group .input-group .form-control[disabled=disabled] {
    // padding-left:0 !important;
    font-size: 16px !important;
    color: white !important;
    background: #c3c3c3 !important;
  }

}


#ui-container .pageType-campaign.readonly-form_type .form-group .input-group .form-control,
#ui-container .pageType-campaign.readonly-form_type .form-group .input-group .form-control[disabled=disabled] {
  font-size: 16px !important;
  color: #444 !important;
  background: transparent !important;
  padding-left: 0;
  height: auto;
  margin-top: -5px;
  border: 1px solid transparent !important;
}

#ui-container .pageType-campaign.readonly-form_type .form-group .input-group .chosen-container.chosen-disabled a > span {
  padding-left: 2px;
  background: transparent !important;
  color: #444 !important;
  font-size: 16px;
}

#ui-container .pageType-campaign.readonly-form_type .form-group .input-group .chosen-container.chosen-disabled .chosen-single {

  &.chosen-default {
    display: none;
  }
}

#ui-container .pageType-campaign.readonly-form_type {
  label {
    float: right;
    margin-right: 5px;
    font-weight: bold;
    text-align: right;
  }

  .validate-if-required {

    display: none;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .form-group .input-group {
    max-width: 100%;
    width: 100%;
    display: block;
    height: 35px;
  }

  .form-group .input-group .chosen-choices li.search-field input[type="text"] {
    &.default {
      display: none;

    }
  }
}

#ui-container .pageType-campaign.readonly-form_type .super-block {
  .left-block {
    display: none;
  }
  .form-group.currency {
    position: relative;
        padding-left: 6px;
    &::before {
      display: none;
    }
    .input-group::before {
      top:0px;
      top: -3px;
      position: absolute;
    }
    .lost-wrench::before,
    .input-group::before {
      z-index: 99;
      content: var(--engine-currency-unicode);
      left: 0px;
      min-height:initial;
      height: initial;
      padding: 0px 1px 4px 0px;
      border-radius: 16px 0 0 16px;
      font-size: 10px;
      font-family: 'Font Awesome 5 Pro';
      // background:red;
    }
  }

  .form-group.currency .input-group {
    .form-control {

    }
  } 

  .form-group .input-group .chosen-container.chosen-disabled {
    .chosen-single {
      background:transparent !important;
      padding:0 !important;
      span {
        font-size:12px !important;
        margin-top: -5px;
      }
    }
  }


  .form-group .input-group .form-control,
  .form-group .input-group .lost-wrench {

    font-size: 12px !important;
    margin-left: 0px!important;
    padding-top: 0px;
    position: absolute;

  }
  .form-group .input-group .form-control {
    margin-top:-5px !important;
  }

  .lost-wrench {
    color: #444 !important;
    background: transparent !important;
    text-align: left;
    border: 0px;
    padding: 0;
    margin: 0;
    // border-bottom: 1px solid black;
    border-radius: 0;

  }
}

#ui-container .pageType-campaign.readonly-form_type .super-block .lost-wrench {
}

// #ui-container .pageType-campaign.readonly-form_type .super-block .lost-wrench

#ui-container .pageType-campaign.readonly-form_type .form-group {
  margin-bottom: 0px;
}

#ui-container .pageType-campaign.readonly-form_type .form-group.calendar .input-group .form-control,
#ui-container .pageType-campaign.readonly-form_type .form-group.currency .input-group .form-control[disabled=disabled] {
  text-align: left;
  margin-left: 15px;
  color: #444 !important;
  font-size: 16px;
  margin-top: -2px;
}

#ui-container .pageType-campaign.readonly-form_type {
  #ui-container .form-group .input-group .form-control {
    background: orange !important;
  }
}



#ui-container .pageType-campaign.readonly-form_type {
  .form-group .input-group .chosen-container,
  .form-group .input-group .chosen-container.chosen-disabled {
    float: left;

    .chosen-choices {
      padding-left: 0 !important;

      margin-top:: -5px;

      li.search-choice {
        margin-left: 0 !important;
        background: transparent !important;
        padding-left: 0px !important;

        span {
          color: #444 !important;
        }
      }
    }
  }

  .main-block {

    width: 90%;
    margin: 0 auto;


  }

  .super-block h5 {
    text-align: left;
  }
}

@include respond-above(md) {
  #ui-container .pageType-campaign.readonly-form_type label {
    float: left;
    text-align: left;
  }
}

@include respond-below(md) {

  .main-block .super-block h5 {
    text-align: right !important;
  }

  .main-block .row > .col-md-2 {
    float: left;
    width: 100% !important;


  }

  .main-block .row > .col-md-4 {

    width: 100% !important;
  }
  .main-block .hidden-lg {
    width: 50%;
    float: left;

    h5 {
      margin-right: 10px;
      text-align: right !important;
    }
  }
  .main-block .lost-wrench {
    width: 50%;
    float: left;
  }
  .main-block .form-group,
  .main-block .form-group.currency {
    width: 50% !important;
    float: left !important;
  }


}


.super-block .col-md-2 {

}

@include respond-below(md) {
  .hidden-form-label {
    width: 50%;
    float: left;
    padding-right: 10px;
    //background-color: red;
  }
  .pull-right-sm {
    float: right !important;
    padding-bottom: 10px;

  }
  .total-left-sm {
    float: left !important;
    width: 100% !important;
    font-weight: bold;
    display: block;
  }
  .total-value {
    font-weight: bold;
    display: block;
    float: right !important;
  }
  .extra-padding {
    left: 200px

  }

}


@include respond-above(md) {
  .hidden-form-label {
    background-color: blue;
    display: none;
  }
  .total-left-sm {
    font-weight: bold;
    text-align: right;
    display: block;
  }
  .total-value {
    font-weight: bold;
    text-align: center;
    display: block
  }

  .single-save {
    padding-bottom: 10px;
    // padding-left: 25px;
  }
}


#ui-container .form-group .input-group.calendar .form-control:hover {
  cursor: pointer;

}

/* punchier validation boxes */
.validate-if-required {
    position: absolute !important;
    right: -20px !important;
    top: -7px !important;
    z-index: 2 !important;
    color: #504848 !important;
    padding: 0px 7px 1px 6px !important;
    z-index: 1 !important;
    border-radius: 16px;
    z-index:327 !important;
    right: -24px !important;
    top: -7px !important;
    border-left: none;
}
