@import '../common.scss';
$CURRENCY_CODE:$BASE_CURRENCY_CODE;

$BASEPINK:#07065d;
$BASEBLUE:#07065d;
$BASECOOLGREY5:#b1b3b3;
$BASECOOLGREY8:#888b8d;
$CORPORATE_GREY:#ddd;
$LOGIN_BG: url('/instance/base/base-bg1.jpg');

$BACKGROUND-SYS:$BASE_GREY;
$ACTUAL_HEADER_BG:$BASE_HEADER_COLOR;
$ACTUAL_HEADER_COLOR:$BASE_HEADER_BG;
$CORPORATE_HEADER_BG:$BASE_ACTION_BG;
$CORPORATE_BRAND_COLOR:$BASE_HEADER_BG;
$CORPORATE_ACTION_COLOR:$BASE_ACTION_BG;
$TABLE_HOVER_COLOR:$CORPORATE_BRAND_COLOR;
$NAVIGATOR_BG:$BASE_BLACK;
$SIDEBAR_BG:$BASE_BLACK;
$DASH_BG:$BASE_HEADER_COLOR;
$DASH_H2:$BASE_HEADER_COLOR;
$DASH_H4:$BASE_BLACK;
$DASH_OFFSET:-30px;
$DASH_ICON:$BASE_WHITE;
$DASH_ICON_RADIUS:16px;
$FILTER_BUDDY_BACKGROUND:$BASE_HEADER_COLOR !important;
$FILTER_BUDDY_BORDER_TOP:1px solid $BASE_HEADER_COLOR !important;
$FILTER_BUDDY_BORDER_BOTTOM:1px solid $BASE_HEADER_COLOR !important;
$LAYDOWN_BG:transparent;
$PANEL_SPLITTER_BACKGROUND:$BASE_HEADER_COLOR;
$TIPPYCONTENTBG:$BASE_GREY;
$TIPPYBG:$BASE_GREY;//unquote($BASE_HEADER_BG+B3);
$TIPPYARROWBG:$BASE_GREY;
$sidebar-button-fontsize:14px;
$DASHBOARD_SECTION_TITLE:$BASE_HEADER_BG;
$DASHBOARD_SECTION_SUBTITLE:$BASE_HEADER_BG;
$PANEL_HEADER_BACKGROUND:$BASE_HEADER_BG;
$PANEL_HEADER_COLOR:$BASE_HEADER_COLOR;
$PANELHEADERBG:#f3f4f4;
$PANELHEADERFONT:darkgrey;
$NEWbuildbuild-bg:white;
$SEARCH_CHOICE_CLOSE_BG:transparent;
$SEARCH_CHOICE_CLOSE_COLOR:$BASE_GREY;
$SEARCH_CHOICE_CLOSE_HOVER_BG:transparent;
$SEARCH_CHOICE_CLOSE_HOVER_COLOR:$BASE_HEADER_BG;
$CHOSEN_SEARCH_BEFORE_COLOR:$BASE_GREY;
$CHOSEN_DROP_RESULT_SELECTED_COLOR:$BASE_HEADER_BG;
$TABLE_PAGINATION_ACTIVE_BG:$BASE_HEADER_BG;
$TABLE_PAGINATION_ACTIVE_COLOR:$BASE_HEADER_COLOR;
$NGTABLECOUNTS_BTN_BG:$BASE_ACTION_BG;
$NGTABLECOUNTS_BTN_COLOR:$BASE_HEADER_COLOR;
$NGTABLECOUNTS_BTN_ACTIVE_COLOR:$BASE_HEADER_COLOR;
$NGTABLECOUNTS_BTN_ACTIVE_BG:$BASE_HEADER_BG;
$SEARCH_CHOICE_CLOSE_BG:transparent;
$SEARCH_CHOICE_CLOSE_COLOR:$BASE_GREY;
$SEARCH_CHOICE_CLOSE_HOVER_BG:transparent;
$SEARCH_CHOICE_CLOSE_HOVER_COLOR:$BASE_HEADER_BG;
$CHOSEN_SEARCH_BEFORE_COLOR:$BASE_BLACK;
$CHOSEN_DROP_RESULT_SELECTED_COLOR:$BASE_BLACK;
$TIMELINE_MARKER_COLOR : #FFFF6676;

@import '../../app.scss';

// .login-screen {
//   .brand-icon {
//     height: 50px;
//     position: relative;
//     z-index: 999;
//   }
//   > div {
//     position: relative;
//     z-index: 99999;
//   }
//   background-image: url(/instance/tisn/login.bg.png);
//   background:initial !important;
//   background-size:cover;
//   &::before,
//   &::after {
//     position: absolute;
//     top:0;
//     left:0;
//     right:0;
//     bottom:0;
//     width:100%;
//     height:100%;
//     content:'';
//   }
//   &::before {
//     background-image: url(/instance/base/base-bg1.jpg);
//     background-attachment: fixed !important;
//     background-size: 100%;
//       background-image: url(/instance/base/base-bg1.jpg);
//       background-attachment: fixed !important;
//       background-size: cover;
//       background-repeat: no-repeat;
//   }
//   &::after {
//     background-attachment: fixed !important;
//     background-repeat: no-repeat;
//     background-position: center 500px;
//     background-size: 10% !important;
//     opacity:.7;
//   }
//   .company-logo{
//     margin:0 auto 10px;display: block;
//     padding-left: 90px;
//     width: 230px;
//     padding-left: 80px;
//     margin-left: 83px
//   }
// }
