
.modal-content {

}

.modal-header {
  background-color:var(--engine-panel-header-background) !important;
  border:$PANEL_HEADER_BACKGROUND;
  border-color:var(--engine-panel-header-background) !important;
  h4 {
    color: var(--engine-panel-header-color) !important;
  }
  padding: 10px 15px;
  border: none;
  position:relative;
}
.modal-close {
z-index: 999999;
    position: absolute;
    top: 3px;
    right: 5px;
    /* border: 1px solid; */
    color: var(--engine-panel-header-color);
    // background: #737171;
    // background: #222;
    background:transparent;
    border-radius: 40px;
    width: 27px;
    height: 27px;
    cursor: pointer;
  i {
    color: var(--engine-panel-header-color);
    font-size: 20px;
    margin-left: 6px;
    margin-top: 3px;
  }
}

.modal-title {
  text-transform: $MODAL_HEADER_TEXT_TRANSFORM;
  font-size: $MODAL_HEADER_FONTSIZE !important;
  color:$PANELHEADERFONT !important;
  i {
    font-size:$MODAL_HEADER_FONTSIZE !important;
  }
  margin:0;
  padding:0;
  line-height: 1em;
}
.modal-content {
  background-color: $MODAL_CONTENT_BGCOLOR !important;
  max-height: calc(80vh);
  overflow-y: scroll;
  overflow-x: hidden !important;
  //overflow-y: auto !important;
  position:relative;
}
