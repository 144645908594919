/*
Here's a correct table of percentages to hex values. E.g. for 50% white you'd use #80FFFFFF.
100% — FF
95% — F2
90% — E6
85% — D9
80% — CC
75% — BF
70% — B3
65% — A6
60% — 99
55% — 8C
50% — 80
45% — 73
40% — 66
35% — 59
30% — 4D
25% — 40
20% — 33
15% — 26
10% — 1A
5% — 0D
0% — 00
*/

$CORPORATE_ACTION_COLOR:$CORPORATE_HEADER_BG;

$btn-font-weight: 300;
$btn-font-size: 14px;
$btn-line-height: 1.666;
$btn-padding:5px 10px;
$btn-border-radius:16px;
// $CORPORATE_BRAND_COLOR:$CORPORATE_ACTION_COLOR;
$btn-primary-background:$CORPORATE_ACTION_COLOR;
$btn-primary-border: 1px solid $CORPORATE_ACTION_COLOR + A6;
$btn-primary-color: $CORPORATE_WHITE;
$btn-primary-box-shadow: 0px -2px 0px transparent inset;

$btn-primary-disabled-background:$CORPORATE_BRAND_COLOR + A6;
$btn-primary-disabled-border: 1px solid $CORPORATE_BRAND_COLOR + A6;
$btn-primary-disabled-color: $CORPORATE_WHITE + A6;
$btn-primary-disabled-box-shadow: 0px -2px 0px transparent inset;

$btn-primary-hover-background:$CORPORATE_GREY_DARK;
$btn-primary-hover-border: 1px solid $CORPORATE_GREY_DARK;
$btn-primary-hover-color: $CORPORATE_WHITE;
$btn-primary-hover-box-shadow: 0px -2px 0px transparent inset;

$btn-primary-focus-background:$CORPORATE_GREY_DARK + A6;
$btn-primary-focus-border: 1px solid $CORPORATE_GREY_DARK + A6;
$btn-primary-focus-color: $CORPORATE_WHITE;
$btn-primary-focus-box-shadow: 0px -2px 0px transparent inset;

$btn-secondary-background:$CORPORATE_GREY_LIGHT;
$btn-secondary-border: 1px solid $CORPORATE_GREY_LIGHT + A6;
$btn-secondary-color: $CORPORATE_GREY_DARK;
$btn-secondary-box-shadow: 0px -2px 0px transparent inset;

$btn-secondary-disabled-background:$CORPORATE_GREY_LIGHT + A6;
$btn-secondary-disabled-border: 1px solid $CORPORATE_GREY_LIGHT + A6;
$btn-secondary-disabled-color: $CORPORATE_GREY_DARK + A6;
$btn-secondary-disabled-box-shadow: 0px -2px 0px transparent inset;

$btn-secondary-hover-background:$CORPORATE_GREY_DARK;
$btn-secondary-hover-border: 1px solid $CORPORATE_GREY_DARK;
$btn-secondary-hover-color: $CORPORATE_WHITE;
$btn-secondary-hover-box-shadow: 0px -2px 0px transparent inset;

$btn-secondary-focus-background:$CORPORATE_GREY_DARK + A6;
$btn-secondary-focus-border: 1px solid $CORPORATE_GREY_DARK + A6;
$btn-secondary-focus-color: $CORPORATE_WHITE;
$btn-secondary-focus-box-shadow: 0px -2px 0px transparent inset;

.btn {
	padding: $btn-padding;
	border-radius: $btn-border-radius;
  	font-weight: $btn-font-weight;
  	font-size: $btn-font-size;
  	line-height: $btn-line-height;
  	 padding: 8px 30px;
    line-height: 1em;
    /* fallback */
    background: #07065d;
    box-shadow: 0px -2px 0px transparent inset;
    border: 1px solid #07065d;
    color: #fefefe;

	display: inline-block;
  	margin-bottom: 0;
  	text-align: center;
  	vertical-align: middle;
  	cursor: pointer;
  	white-space: nowrap;
  	touch-action: manipulation;
  	background-image: none;
  	-webkit-user-select: none;
  	-moz-user-select: none;
  	-ms-user-select: none;
  	user-select: none;
  	-webkit-transition: all 0.3s linear;
  	-o-transition: all 0.3s linear;
  	transition: all 0.3s linear;
  	    // border: initial;
  	&.btn-submit,
	&.btn-primary {
		background:var(--engine-action-button-background);
		box-shadow: $btn-primary-box-shadow;
		border: 1px solid var(--engine-action-button-background);
		color: var(--engine-action-button-color);
		&:disabled {
			background:$btn-primary-background;
			box-shadow: $btn-primary-box-shadow;
			border: $btn-primary-border;
			color: $btn-primary-color;
		
		}
		&:hover {
			background:$btn-primary-hover-background;
			box-shadow: $btn-primary-hover-box-shadow;
			border: $btn-primary-hover-border;
			color: $btn-primary-hover-color;
		}
		&:focus {
			background:$btn-primary-focus-background;
			box-shadow: $btn-primary-focus-box-shadow;
			border: $btn-primary-focus-border;
			color: $btn-primary-focus-color;
		}
	}
	&.btn-default,
	&.btn-secondary {
		background:var(--engine-default-button-background);
		border: var(--engine-default-button-background);
		color: var(--engine-default-button-color);
		box-shadow: $btn-secondary-box-shadow;
		&:disabled {
			background:$btn-secondary-background;
			box-shadow: $btn-secondary-box-shadow;
			border: $btn-secondary-border;
			color: $btn-secondary-color;
		
		}
		&:hover {
			background:$btn-secondary-hover-background;
			box-shadow: $btn-secondary-hover-box-shadow;
			border: $btn-secondary-hover-border;
			color: $btn-secondary-hover-color;
		}
		&:focus {
			background:$btn-secondary-focus-background;
			box-shadow: $btn-secondary-focus-box-shadow;
			border: $btn-secondary-focus-border;
			color: $btn-secondary-focus-color;
		}
	}
	&.btn-filters {
		background:$btn-primary-background;
		box-shadow: $btn-primary-box-shadow;
		border: $btn-primary-border;
		color: $btn-primary-color;
		&:disabled {
			background:$btn-primary-background;
			box-shadow: $btn-primary-box-shadow;
			border: $btn-primary-border;
			color: $btn-primary-color;
		
		}
		&:hover {
			background:$btn-primary-hover-background;
			box-shadow: $btn-primary-hover-box-shadow;
			border: $btn-primary-hover-border;
			color: $btn-primary-hover-color;
		}
		&:focus {
			background:$btn-primary-focus-background;
			box-shadow: $btn-primary-focus-box-shadow;
			border: $btn-primary-focus-border;
			color: $btn-primary-focus-color;
		}
	}
	&.btn-mini {
		padding-right:12px;
		padding-left:12px;
	}
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  // outline: thin dotted;
  // outline: 5px auto -webkit-focus-ring-color;
  // outline-offset: -2px;
      outline: none;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

	// .btn.btn-submit:hover, .btn.btn-primary:hover {

	// 	#737171
	// }



// .activity-filter-section 


table {
  background-color: transparent;
  border-collapse: collapse;
}


/////////////////////////////////// 
/* the table pager */

.ng-table-pager {

	.ng-table-counts .btn-group {
		.btn:last-of-type {
    		border-top-right-radius: 0px;
    		border-bottom-right-radius: 0px;
		}
	}
}
.ng-table-counts {
  margin-bottom:20px;
  margin-right: 20px;
  .btn {
  font-size:12px;
    padding: 3px 7px;
    color:var(--engine-action-button-color);
    background:var(--engine-action-button-background);
    &.active {
      color:var(--engine-defaut-button-color) !important;
      background:var(--engine-default-button-background);;
    }
  }
}




.cursor-pointer {
    cursor:pointer;
}


.as-sortable-item {
  position: relative;
}
.as-sortable-item-handle {
  position: absolute;
  top:0;left:0;bottom:0;right:0;
}



#channel-filters select {
  width:100%;
}



.panel-body {
	min-height:72px
}

.modal-body {
    border:$PANEL_BODY_BORDER !important;
    background:$PANEL_BODY_BACKGROUND !important;
    float: left;
    width: 100%;
  .entry-form {
    z-index:400;
    position: relative;
    padding: 15px;
    max-height: 80vh;
    overflow-y: scroll;
  }
}










.modal-content {
	//overflow-y: auto !important;
	.modal-body {

		overflow-y: scroll;
	}	
}





.activity-filter-section {
	margin-top:10px;
	    z-index:5679; // one higher than panel splitter
    position: relative;
}




.tab-content {
	padding-bottom: 30px;
}



#ui-container {
	min-height: 100vh;
}

.navigator-launch {
	margin-top: -10px;
    display: block;
	border: none;
	background: transparent;
	text-shadow: none;
	box-shadow: none;
	img {
		height: 20px !important;
    	margin-top: 5px;
	}
}

///////// filters 

.hide-filter-buddy {
	display:none;
}









.tab-gantt-table thead tr th span {
	padding-right:0 !important;
}



// datepicker datepicker-dropdown dropdown-menu datepicker-orient-left datepicker-orient-top
// datepicker-dropdown dropdown-menu datepicker-orient-left datep// <!-- ENGINE MODULE | Campaign -->
//@import '../modules/Campaign/Campaign.scss';





.position-button-outside-left {
	position:relative;
	> .btn {
		padding-left: 10px;
    padding-right: 10px;
		left:-50px;
		top:0px;
		position: absolute;
	}
}

.buildbuild .active-modal.modal-content {
    // max-width: 600px;
    // margin: 40px auto 0;
    /* background: transparent !important; */
    // border: 1px solid transparent !important;
    // box-shadow: initial !important;
    background: white !important;
}
.special-delivery .panel-heading-controls {
	position: absolute;
    top: -41px;
    right: 0;
}

.btn-submit.modal-dynamic {
	
}

.edit-form-controls {
	float:left;
	width:100%;
	margin-bottom:20px;
	button:last-of-type {
		margin-right:15px;
	}
}


.accordion { 

	padding:0 !important;

}
.accordion-group {

    margin-bottom: 10px;
    border: 0px solid transparent;
    border-radius: 2px;

	.accordion-heading {
		padding:0px;
		border-bottom: 0;
		.accordion-toggle {
		    display: block;
		    cursor: pointer;
		    padding: 0;
		        padding: 10px;
		    line-height: 28.75px;
		    color: #555555;
		    background-color: #efefef;
		    outline: none !important;
		    text-decoration: none;
		}
	}
	.keystone-status > button {
		border-radius: 16px;
	    border: 1px solid transparent;
	    background: transparent;
	    margin-left: -11px;
	}
	.accordion-inner {
    	border-top: 0px solid transparent;
		padding: 5px;
	}
}


// range review gantt classes for status

.rr_not-due {
    background:grey !important;
} 
.rr_not-started {
    background:red !important;
}
.rr_in-progress {
    background:gold !important;
} 
.rr_complete {
    background:green !important;
}

.header-row {
	background:$CORPORATE_ACTION_COLOR !important;
	.data-field {
		color:$CORPORATE_WHITE !important;
	}
}

.box {
	a:first-of-type {
		float:left;
		    margin-bottom: 15px;
	}
	a:last-of-type {
		float:left;
		    margin-bottom: 15px;
		    margin-left:15px;
	}
	p {
    	float: left;
	}
}

           
           
.add-form .box.thinking {

}

.add-form > .form-section {
    padding:0;
}


.prettycheckbox {

    text-align: center;
    > a {
        float: initial !important;
        margin:0 auto !important;
    }
}

.super-block .form-group .input-group textarea.form-control {
    font-size: 14px;
    line-height: 1.2em;
}




.refreshed.super-block .col-md-1 {
    width: 8.33333333%;
}

.status-circle {
	font-size: 18px;
	margin-top: -6px;
}

.pageType-campaign .panel.panel-info {
    border-radius: 8px 8px 8px 8px !important;
    border-bottom: 2px solid white !important;
    // border-bottom: 16px solid white !important;
}


.wrap-laydown { 
	position:relative;
	&::before { /* This is a fix for some ticket somewhere */
		content:'';
		height:10px;
		width:100%;
		box-size:border-box;
		border-left:1px solid $CORPORATE_GREY;
		border-right:1px solid $CORPORATE_GREY;
	    top: 0;
    	left: 0;
    	position: absolute;

	}
}




.tippy-content .btn.btn-primary {
	display:block;
	float: right;
	margin-right:5px;
}


.roi-datatable {
	text-align:left;margin-bottom:2px;float:left;width:100%;
	label {
			color:$CORPORATE_BLACK;
		    float: left;
		    font-size: 17px;
		    text-transform: uppercase;
		    font-weight: 300;
	}
	input {
		font-size: 16px;
	    width: 22px;
	    height: 22px;
	    background: red;
	    padding: 0px;
	    margin: 0px;
	}
	.label-form {
		float: right;
		margin-top: 2px;
	}
	> div > div {
		> span:first-of-type {
			float:left;
		}
		> span:last-of-type {
			float:right;
		}
	}
}

.section-title-two { // popover interface
	text-align: left;
    width: 100%;
    background:$CORPORATE_HEADER_BG;
    color:$CORPORATE_WHITE;
    font-size:16px;
   	color: white;
    padding: 5px 11px;
    // text-transform: uppercase;
    border-radius: 8px 8px 0px 0px;

        margin-top: -12px; // fixes overhang with container
}




.brick-col .panel { // dashboard widgets
	border-radius:8px 8px 0 0;
}




.maintenance-layout .section-header,
.dashboard-layout .section-header {
	height: auto;
	padding:10px 0 0 20px;
	margin-bottom:20px;
	h2.section-title {
	 	color:$DASHBOARD_SECTION_TITLE;
	 	font-size:25px;
	 	font-weight:bold;
	 	// height:auto;
	 	margin:0;
	 	padding:0;
	 	height: auto;
	 }
	 h4 {
	 	color:$DASHBOARD_SECTION_SUBTITLE;
	 	margin:0;
	 	padding:0
	 }
}

.panel > .panel-heading.fp-action-heading {
    border-color: transparent !important;
    background: transparent !important;
    border-radius: 8px 8px 0 0;
    border: none;
    position: relative;
}



@mixin small() {
    @media only screen and (max-width: 320px) {
        @content;
    }
}


.tippy-tooltip.light-theme {
  // background:transparent;
  border-radius:0px;
  background-color:transparent;
  padding:0 !important;
  background: white !important;
  float: left;
  .tippy-content {
    zoom:1;
    // padding:5px;
    background:$CORPORATE_WHITE;
    float:left;
    border:0px solid $CORPORATE_BLACK;
    // width:300px;
    ul {
      list-style: none;
      border:0;
      padding:0;
      li {}

    }
  }
}