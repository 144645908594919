// >>>>>>> JBP-tool-20210730

.new-graph-wrapper {
    .panel-heading-controls {
      width: 1001px !important;
    }
} 

.rmiIncrementalOverview {

  .spacer-row-for-gantt {
    display: none;
  }

  #tab-chart {
    background-image: url(/base-watermark.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    height:400px;
  }
  .datatable-table {
    tbody tr:last-of-type {
      span {
        font-weight: bold;
      }
    }
    tbody tr td:last-of-type {
      span {
        font-weight: bold;
      }
    }
  }
}

.jppInvestmentByChamberCyclePlanned {
.spacer-row-for-gantt {
    display: none;
  }

  #tab-chart {
    background-image: url(/base-watermark.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    height:400px;
  }
  .datatable-table {
    tbody tr:last-of-type {
      span {
        font-weight: bold;
      }
    }
  }
}

.rmiJbpOverview {
  .doubleScroll-scroll-wrapper {
    margin-bottom:0 !important;
    // height:5px !important;
  }
  .spacer-row-for-gantt {
    display: none;
  }

  #tab-chart {
    background-image: url(/base-watermark.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25%;
    height:400px;
  }
  .datatable-table {
    tbody tr td:last-of-type {
      span {
        font-weight: bold;
      }
    }
  }
}