.panel-splitter-control {
    padding: 10px 0;
    width: 100%;
    float: left;
}

.split-panel {
    overflow:hidden;
    float: left;
}
.split-panel-scroll-wrapper {
    overflow-x:scroll;
}

.panel-splitter-control {
    padding: 0px 0;
    width:100%;
    float: left;
}

.panel-splitter-control .ui-state-default, 
.panel-splitter-control .ui-widget-content .ui-state-default, 
.panel-splitter-control .ui-widget-header .ui-state-default {
    border: 1px solid rgba(29, 58, 48, 0.0);
    background: rgba(29, 58, 48, 0.0);
    font-weight: normal;
    color: #555555;
    cursor: col-resize;
}

.panel-splitter-control .ui-slider-handle.ui-state-default {
    top:25px;
    border: 1px solid rgba(72, 95, 160, 0.5);
    background: rgba(29, 39, 58, 0.5);
    font-weight: normal;
    color: #555555;
    cursor: col-resize;
    height:100vh;
}

.panel-splitter-control .ui-widget-content.ui-state-default {
    border: 1px solid #efecec;
    background: #efecec;
    border-radius:0;
    color: #222222;
}
.panel-splitter-control .ui-widget-content {
    border: none;
    background: none;
    color: #222;
}

.contains-slider {
    overflow: hidden;
    clear: left;
}
.table-controls {
    float: left;
    width:100%;
}
.invisible-onload {
    visibility: hidden;
}

.contains-slider {
    position: relative;
}

.panel-splitter-control .ui-state-default, .panel-splitter-control .ui-widget-content .ui-state-default, .panel-splitter-control .ui-widget-header .ui-state-default {
    border: 1px solid rgba(29, 58, 48, 0.0);
    background: rgba(29, 58, 48, 0.0);
    font-weight: normal;
    color: #555555;
    cursor: col-resize;
}

.panel-splitter-control .ui-slider-handle.ui-state-default {
    top: 15px;
    border: 7px solid white;
    // rgba(29, 39, 58, 1);
    background: $PANEL_SPLITTER_BACKGROUND;
    font-weight: normal;
    color: #555555;
    cursor: col-resize;
    /* height: 100vh; */
    border-bottom: 0;
    border-radius: 0;
}

.panel-splitter-control .ui-widget-content {
    border: none !important;
    background: none;
    color: #222;
    position: relative;
    z-index:8850;
}


.tab-gantt-table {
    margin-bottom:0 !important;
    thead tr th:first-of-type span {
        padding-left: 10px;
    }
}


/*// Split Panels*/

/*.stick-this {
    opacity:0;
    display: none;
    transition: opacity .4;
}
.stuck {
    position: fixed;
    height: 100%;
    top: -2px;
    max-width: 100%;
    width: inherit;
    z-index: 89;
}

.super-sticky {
    z-index: 9000;
    background: #ffffff;
    padding: 0 20px;
    box-shadow:0px 3px 2px #5f56565c;
    max-height: 170px;
    z-index: 444;
}

.super-sticky .doubleScroll-scroll-wrapper {
    z-index: 99900;
    background: cyan;
}

.super-sticky .ship-wrap-right-bottom {
    padding-top:0;
}
.super-sticky .ship-wrap-left-bottom {
    padding-top: 76px;
}
.super-sticky table {
    margin-bottom: 0;
}

.contains-slider {

    position: relative;
    background: green;
}*/

#syncscroll-left-page,
#syncscroll-left-page-top {
  padding-right:9px;
}
#syncscroll-right-page,
#syncscroll-right-page-top {
  padding-left:9px;

}

.panel-splitter-control .ui-widget-content {
    margin-top: -20px;
}
.panel-splitter-control .ui-slider-handle.ui-state-default {
    // box-shadow: 0px -2px 7px #5c5757;
    top: 15px;
    border:$panel-splitter-control-handle-border;
    // border-top:$panel-splitter-control-handle-border-top;
    // border-bottom:$panel-splitter-control-handle-border-bottom;
    background-color:$PANEL_SPLITTER_BACKGROUND !important; //$panel-splitter-control-handle-background;
    background-image:$panel-splitter-control-handle-graphic;
    background-repeat: no-repeat;
    background-attachment: initial;
    background-position: center;
    overflow: visible;
    min-height:100px;
    background-origin: content-box;
    width: 20px;
    // background-position:  95% 46%;
    background-position: 3px 46%;
    // background-size: 100%;
    background-size: 14px;
}

// .panel-splitter-control .ui-slider-handle::after {
//     position: absolute;
//     top:40px;
//     width:20px;
//     height:80px;
//     content:'';
//     background:$panel-splitter-control-handle-graphic;
// }



// .contains-slider {

// }
.panel-splitter-control {
    .ui-slider-horizontal:first-of-type {
        span {
            margin-left:-10px;
        }
    }
} 