// $checkbox-label-color:#333;

.prettycheckbox label {   
	display: block;
    float: left;
    margin: 3px 5px 1px 4px;
    cursor: pointer;
    color: $checkbox-label-color;
}



.prettycheckbox:focus {
    outline: transparent auto 0px !important;
}  
.prettycheckbox > a, 
.prettyradio > a {
    height: 28px;
    width: 30px;
    display: block;
    float: left;
    cursor: pointer;
    margin: 0;
    background-image: url('input-checkbox-radio-sprite.png') !important;
        background-position: 0px 0px;
    &:hover {

        background-position: -30px 0px;
    }
    &.checked {
        background-position: -60px 0px;
        &:hover {
            background-position: -90px 0px;

        }
    }
}


.has-checkbox {
    position: relative;
    .is-checkbox {
        height: 25px;
        overflow: hidden;
        margin-left: -6px;
        float: left;
    }
}