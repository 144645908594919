



body {
    font-family:$FONT_FAMILY !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: $FONT_COLOR_DEFAULT;
    // font-family: 'Karla', sans-serif;
}

b {
  font-weight: bold !important;
  // color:#fcc422;
}

