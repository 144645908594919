ul.alerts_list {
	// background: lightblue;
	margin: 20px auto;
	list-style: none;
	width:100%;
	display: block;
	padding:0;
	z-index:400px;
	float:left;
	li {
		width: 100%;
		// border-bottom:2px solid white;
		display: block;
		float:left;
		padding:10%;
		padding: 1%;
		a {
			padding:0;
			display: block;
		}
		img {
			max-height:27px;
			margin:0px auto;
		}
		i {
		}
		.title-section {
			width:20%;
			float:left;
		}
		.main-section {
			width:80%;
			float:left;
			.actions {
				width:100px;
				float:right;
				span:nth-of-type(2){
					float:right;
				}
			}
		}
	    span {
	    	display: block;
	    	float:left;
			&.alert_icon {
				// width:5%;
				width: 25px;
			}
	    }
	    &.active {
	    	// background:green;
	    }

	}
}
.alerts-modal {
	.toggle-icon img {
		    vertical-align: middle;
    max-height: 100%;
    max-width: 100%;
    width: 40px;
    padding: 5px 3px 3px;
    margin-right: 12px;
	}
	.widget-title-section {
		margin: 0 auto;
		font-size: 13px;
		line-height: 1.1em;
		font-weight:bold;
		color:$CORPORATE_BLACK;
	}
	.widget-title-id {
		margin: 0 auto;
		font-size: 13px;
		line-height: 1.1em;
		color:$CORPORATE_BLACK;
	}
}