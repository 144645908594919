$navbar-height:$TOPNAV_HEIGHT;

$header-background:var(--engine-header-background);
$header-link-color:var(--engine-header-color);
$header-link-fontsize:20px;

$TOPNAV_ICON_HEIGHT: 30px;

#sidenav {
    height: 100vh;
    width: 220px;
    margin-left: -220px;
}

.navbar-open.navbar-header {

    margin-left: 170px;
}

.topnav-wrapper {
    z-index:8856 !important; // required for pattern-split-panel.scss
    border-bottom:none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9000;
    box-sizing: border-box;
    background: $header-background;
    // transition-property: width,padding,left,right;
    transition-duration: .35s;
    padding-right: 50px;
    .topnav-view_animate {
        // background: #1c1c2b;
        // box-shadow: 0px 1px 0px $meta;
        > .container {
          width:100%!important;
        }
    }
    &.navigator-open {
        padding-right: 200px;
    }
}


.navbar {
    position: relative;
    min-height: $navbar-height;
    height: $navbar-height;
    margin-bottom: 0px;
    box-sizing:border-box;
    border: none;
    background-color:var(--engine-header-background);
}
.navbar-default {
    background-color:var(--engine-header-background);
    border-radius:0;
    border-bottom: 0px solid red;
    // .navbar-nav > li > a:hover, 
    // .navbar-inverse .navbar-nav > li > a:hover, 
    // .navbar-nav > li > a:focus, 
    // .navbar-inverse .navbar-nav > li > a:focus {
    //     border-bottom-color:white;
    // }
    .navbar-nav > li > a {
        border-bottom: 0px solid transparent;
        -webkit-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
        margin-top: 1px;
        color: var(--engine-header-color);
        font-weight: normal;
        font-style: normal;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 2px;
        font-size: 13px;
        padding-top: 5px;
        padding-bottom: 5px;
        a:hover, 
        a:focus {
            color: #777777;
            background-color: #ffffff;
        }
    }
}


.navbar-header {  
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        padding:1px 0;
        box-size:border-box;
        li {
        border-right:1px solid #b1bfda66;
        }
    }
}

.context-toggle {
    background:$TOPNAV_BACKGROUND;
    width:50px;
    height:50px;
    position: absolute;
    top:0;
    right:-50px;
    box-sizing: border-box;
    transition:all .3s ease;
    a {
        padding: 5px;
        display: block;
        img {
            max-width: 40px;
            max-height: 40px;
        }
    }
}

.wrap-this-in-a-directive {
    margin: 0px 10px 23px;
    label {
    }
    .toggle-switchq + label {
        font-size: 0;
        width: 0;
    }
}

// @media (min-width: 768px)
.navbar-nav > li {
    float: left;
    margin: 0 5px;
        height: 43px;
        // border-left:1px solid #c3c3c3;
}

.navbar-form {
    margin-top: 5px;
    margin-bottom: 5px;
    padding:0px 5px;
}

.context-toggle.left {
    left: 0;
    top: -1px;
}

.navbar-collapse {
  background: $header-background;
}

.nav .btn-group-has-toggle {
  transform:scale(.73);
      margin-top: 10px;
}


.context-toggle.left {
  background: $header-background;
}
.context-toggle.right {
  background: $header-background;
}

.navbar-header ul li {
    border-right: transparent 0px !important;
}

.context-toggle a {
    padding: 4px 5px 5px 3px;
    display: block;
    text-align: center;
    color: #555;
}

.topnav-wrapper {
  .nav > li > a {
      font-size: $header-link-fontsize !important;
      padding: 10px 15px;
      color:$header-link-color !important;
  }
}


.navbar .topnav-icon-image {
    // margin: $TOPNAV_ICON_PADDING;
    margin: 13px 0 0;
    max-height: $TOPNAV_ICON_HEIGHT;
    height: $TOPNAV_ICON_HEIGHT;
    &.avatar-preview {
        float:right;
        margin-top:-3px !important;
    }
}

.hide-sidebar {
  display: none !important;
}

.navbar,
.context-toggle{
  min-height: 45px !important;
  height: 45px !important;
}

#mainnav-menu-wrap {
    height: 100%;
}

.nano {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    > .nano-content {
        position: absolute;
        overflow: scroll;
        overflow-x: hidden;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.logo-image {
    max-height: 35px;
    padding: 5px;
    box-sizing: border-box;
}

#container:not(.mainnav-sm) #mainnav-menu-wrap>.nano>.nano-content {
    padding-top: 20px;
}

#topnav {
  position:relative;
  height:100px!important;
  overflow:hidden;
    border: 1px solid black;
    opacity: 1;
    .ng-hide {
      opacity: 0;
    }
    .ng-hide-add,
    .ng-hide-remove {
      transition: all linear 0.5s;
    }
}

.header_user-settings-block {
    display: block;
    height: 40px;
    box-sizing: border-box;
    font-size: 14px;
    padding-top: 11px;
    float: left;
    margin-right: 10px;
    color:var(--engine-header-color);
}