// caret-down: f0d7
// caret-up: f0d8
// chevron-left: f053
// chevron-down: f078
// chevron-right: f054
// chevron-up: f077
// chevron-circle-left: f137
// chevron-circle-right: f138

$INPUT_BORDER:2px solid green;
$INPUT_BACKGROUND:gold;
$INPUT_COLOR:red;
$search-choice-bg:#f3f4f4;
$search-choice-color:#444;
$im-filterBuddy-font-setting:12px;

@mixin searchChoices {
    position: relative !important;
    margin: 6px 3px 3px 2px !important;
    padding: 3px 25px 3px 6px !important;
    box-shadow: 0px 0px 0px transparent !important;
    border-radius: 1px !important;
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px;
    background: $search-choice-bg !important;
    border-color: $search-choice-bg !important;
    text-transform: uppercase;
    color: $search-choice-color !important;
    border-radius: $base-INPUT-BORDER-RADIUS !important;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 0;
    font-weight: normal;
}



.form-group {
    min-height: 35px;
    height: 100%;
    float: left;
    width:100%;
    margin-bottom: 10px;
    position:relative;
    float: left;
    .control-label {
        font-size: $im-filterBuddy-font-setting;
        width: auto;
        color: #bdbfbc;
        &.inside-element {
            width: 100%;
            position: absolute;
            top: -2px;
            font-size: 8px;
            width: auto;
            left: 10px;
            z-index: 6000;
        }
    }

    .input-group {
        max-width:100%;
        width:100%;
        display: block;
        .form-control { // input
            height:$base-INPUT-HEIGHT;
            border: $base-INPUT-BORDER;
            background-color: $base-INPUT-BACKGROUND;
            color: $base-INPUT-COLOR;
            border-radius:$base-INPUT-BORDER-RADIUS;
            padding:$base-INPUT-PADDING;
            box-sizing: border-box;
            width: 100%;
            &::placeholder {
                font-style: italic;
                font-size: $im-filterBuddy-font-setting;
                padding-top: 9px;
                color:$base-INPUT-PLACEHOLDER-COLOR;
            }
        }
        
        textarea.form-control {
            float:left;
            min-height:100px;
            max-height:400px;
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            &::placeholder {
                color: #bdbfbc !important;
            }
        }
        .chosen-container-multi:not(.chosen-container-active) {
            overflow:hidden;
        }
        .chosen-container{
            border: $base-INPUT-BORDER;
            background: $base-INPUT-BACKGROUND;
            color: $base-INPUT-COLOR;
            border-radius:$base-INPUT-BORDER-RADIUS;
            &.chosen-disabled {
                border:1px solid transparent !important;
                // color:#8c5959 !important;
                background:#cecbcb !important;
                .chosen-choices {
                    background: #cecbcb !important;
                }
                .chosen-single {
                    background: #cecbcb !important;
                    border:1px solid transparent !important;
                    div b {
                        opacity: 0;
                        display:none;
                    }
                }
                opacity: 1 !important;
                a > span {
                    // color:#8c5959 !important;
                    font-style: initial;
                }
            }
        }
        .chosen-container-multi{
            min-height:$base-INPUT-HEIGHT !important;
        }
        .chosen-container-single {
            min-height:100%;
            .chosen-single-with-deselect.chosen-single abbr.search-choice-close {
                background: white !important;
                top: 6px;
                right:6px;
                &::before {
                    font-family:'Font Awesome 5 Pro';
                    content:'\f00d';
                    color:black;
                    display: block;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    left: -1px;
                    top: -2px;
                    font-size: 14px;
                }
                &:hover:before {
                    color:grey;
                }
            }
            
        }
        &.chosen-active .chosen-single {
            height:$base-INPUT-HEIGHT;
        }
        .chosen-single {
            line-height: 23px;
            color: $base-INPUT-COLOR;
            background:$base-INPUT-BACKGROUND;
            box-shadow: initial;
            min-height: base-INPUT-HEIGHT;
            border-radius:$base-INPUT-BORDER-RADIUS;
            height: 100% !important;
            display: block;
            border: 0px solid transparent;
            box-sizing: border-box;
            padding-top: 5px;
            > div {
                border-left: none;
                background: transparent;
                right: 40px;
            }
            span {
                text-align: left;
                font-style: italic;
                font-size: $im-filterBuddy-font-setting;
                padding-top: 0px;
                padding-bottom: 2px;
                color:$base-INPUT-PLACEHOLDER-COLOR;
                border:1px solid transparent;
            }
        }
        .chosen-drop {
            border-top: $base-INPUT-BORDER;
            border-bottom: $base-INPUT-BORDER;
            border-left: 1px solid $base-INPUT-BACKGROUND !important;
            border-right: 1px solid $base-INPUT-BACKGROUND !important;
            border-top: 1px solid $base-INPUT-BORDER !important;
            -webkit-box-shadow: initial !important;
            box-shadow: initial !important;
            right: 0px;
            left: 0px;
        }
        .chosen-search {

        }
        .chosen-choices {
            background:$base-INPUT-BACKGROUND;
            min-height:$base-INPUT-HEIGHT !important;
            border:0 solid transparent;
            min-height: 33px !important;
            border: 0 solid transparent;
            border-radius: 16px;
            padding:0px 27px 3px 10px !important;
            box-shadow: initial !important;
            li.search-field input[type="text"] {
                font-style: italic;
                font-size: $im-filterBuddy-font-setting;
                padding-top: 7px;
                color:$base-INPUT-PLACEHOLDER-COLOR;
            }
        }
        .search-choice {
        }
    }
}

#ui-container .readonly-form_type .form-group .input-group .chosen-container {
    &.chosen-disabled {
        // color: !important;
        background:transparent !important;
        .chosen-choices {
            background: transparent !important;
        }
        .chosen-single {
            background: transparent !important;
        }
        a > span {
            // color:#8c5959 !important;
        }
    }
}

.chosen-container-multi .chosen-choices li.search-choice {

    @include searchChoices;
}

.chosen-container .chosen-results li {
    text-align: left;
}
.chosen-container-single .chosen-single div {
    padding-top:5px;
}
.chosen-container-active {
    border-radius:$base-INPUT-BORDER-RADIUS $base-INPUT-BORDER-RADIUS 0 0;
}

.chosen-container-multi .search-field input[type="text"] {
    margin: 1px 0;
    padding: 4px 20px 4px 5px;
    width: 100%;
    height: auto;
    outline: 0;
    border: 1px solid #aaa;
    background: url(/build/images/chosen-sprite.8b55a822.png) no-repeat 100% -20px;
    font-size: 1em;
    font-family: sans-serif;
    line-height: normal;
    border-radius: 0;
}

.chosen-container-multi .chosen-choices {
    padding: 3px 5px 0px !important;
}

#ui-container  {
  .panel > .panel-heading.fp-action-heading {
    padding:0 !important;
  }
  .full-page-action .section-header {
        padding: 0px 0 0 0px;
  }
}


// #ui-container 
//.pageType-campaign {
  .chosen-container-multi .chosen-choices li.search-choice {
    background:$CORPORATE_BRAND_COLOR !important;
    color: white !important;
    &.search-choice-close {
    }

    }
  .chosen-container .chosen-results li.highlighted {
      background-color: $CORPORATE_BRAND_COLOR !important;
      background-image:initial; 
      // background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, $CORPORATE_ACTION_COLOR), color-stop(90%, $CORPORATE_BRAND_COLOR));
      // background-image: linear-gradient(#3875d7 20%, #2a62bc 90%);
      // background-image: linear-gradient($CORPORATE_ACTION_COLOR 20%, $CORPORATE_BRAND_COLOR 90%);
      color: $CORPORATE_WHITE;
  }
  .chosen-container-single .chosen-search input[type="text"] {
    border-radius:14px;
  }
  .chosen-search-input,
  .chosen-choices li.search-field input[type="text"]::placeholder,
  .chosen-default > span,
  input::placeholder {
    color:#bdbfbc !important;
  }
  // input-select.scss 46
  // #ui-container .form-group .input-group .form-control {
  //   &::placeholder,
  //   &::-webkit-input-placeholder, /* Chrome/Opera/Safari */
  //   &::-moz-placeholder /* Firefox 19+ */
  //   &:-ms-input-placeholder, /* IE 10+ */
  //   &:-moz-placeholder { /* Firefox 18- */
  //       color: pink;
  //     // color:CORPORATE_GREY_LIGHT;
  //   }
  // }
  // .btn.btn-default {
  //   background:#a0a0a0;
  //   color:#fefefe;
  // }
  .panel-info .panel-body {
    padding:16px 16px 0px !important;
  }
  .form-section .form-items {
    position:relative;
    .form-section-callout {
      position: absolute;
        width: 30px;
        right: -48px;
        height: 30px;
        top: 2px;
        border-radius: 16px;
      &.info {
          background: #f3f4f4;
      }
      &.status {
          background: darkred;
          > a {
            i {
              color:darkred;
            }
          }
        }
        > a {
          i {
              color:#444;
            }
          display: block;
          width: 28px;
          height: 28px;
          border-radius: 16px;
          margin: 1px 1px;
          text-align: center;
          color: white;
        }
    }
  }

  // .chosen-container-multi:not(.chosen-container-active)&::before {
  .chosen-container-multi:not(.chosen-container-active):before {
    position: absolute;
      font-family: 'Font Awesome 5 Pro';
      content: '\f0d7';
      font-size: 23px;
      right: 14px;
      z-index: 999;
      top: 4px;
      font-weight: 900;
  }
  .chosen-container-single .chosen-single div b {
    background:initial !important;
    position:relative;
    &::before {
      position:absolute;
      font-family: 'Font Awesome 5 Pro';
      content:'\f0d7';
      font-size: 23px;
      font-weight: 900;
    }
  }
  // input-select.scss 147 
  .form-group .input-group .chosen-container {
    border-radius:16px;
  }
  .form-group .input-group .chosen-container.chosen-container-active {
    border-radius:16px 16px 0 0;
  }
  // input-select.scss 154 
  .form-group .input-group .chosen-container-active .chosen-single,
  .form-group .input-group .chosen-container.chosen-container-active .chosen-single {
    border-radius:16px 16px 0 0;
  }
  .form-group .input-group .form-control:disabled {
    background:red !important;
    background: #cecbcb !important;
    color: #8c5959 !important;
   }
  //// form.scss 719
  .input-group.calendar::before {
    right: 0;
  }
  // input-select.scss 167
  .form-group .input-group .chosen-single > div {
    right: 10px;
  }
  //// form.scss 452
  .form-section .form-items .form-group::after {
    background:transparent;
    content:initial;
    visibility: hidden;
  }
  .form-section.required .form-items .form-group::after {

      padding: 4px 11px;
  }
  .form-section .form-items .form-group::after {

      padding: 4px 7px;
  }

  
//}


// #ui-container .form-group.currency {
.form-group.currency {
    position: relative;
    // padding-left: 32px;
    box-sizing: border-box;
    span {
        text-align:right;
    }
    input {
        text-align: right;

    }
    .form-control {
        padding-left: 32px;
        padding-right: 10px;
    }
    &.sele::before { // for modifiers % £ // ::after valiadtion
        height:32px !important;
        border: 1px solid gainsboro;
        background: white;
        border-right: 1px solid $CORPORATE_ACTION_COLOR;
    }
    &::before { // for modifiers % £ // ::after valiadtion
        position:absolute;
        z-index: 99;
        // content:'\f154';
        content:var(--engine-currency-unicode);
        // content:$BASE_CURRENCY_CODE;
        width: 32px;
        font-size: 16px;
    line-height: 1.6em;
        left: 0px;
        min-height: 32px;
        height: 35px;
        padding: 4px 10px 4px 13px;
        border-radius: 16px 0 0 16px;
        font-size: 12px;
        font-family: 'Font Awesome 5 Pro';
        border-right: 1px solid;
            color: var(--engine-text-color-default);
    }

}



.chosen-container-multi .chosen-choices li.search-choice {
  text-transform:initial !important;
}

.tippy-content {
  border-radius:5px !important;
}


.panel.laydown-datatable {
  padding-top:20px;
}

.chosen-container-multi .chosen-choices li.search-choice {
  border-radius: 12px !important;
}

.chosen-container.chosen-container-multi {
}

.chosen-container.chosen-container-active {
    .chosen-drop {
        height: fit-content;
        clip: auto !important;
    }
    .chosen-results {
    }
}
.chosen-container.with-drop {
}

.chosen-container-multi .chosen-drop .result-selected {
  display: list-item;
  color: $CHOSEN_DROP_RESULT_SELECTED_COLOR;
  cursor: default;
}

.chosen-container .chosen-choices li.search-field {
    overflow: hidden;
}

$SEARCH_CHOICE_CLOSE_COLOR: #fefefe;
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    top: 21%;
    right: 5px;
    display: block;
    background:initial;  
    background-position: -42px -10px;
    background-color: $SEARCH_CHOICE_CLOSE_BG;
    &::before {
      font-family: 'Font Awesome 5 Pro';
      content: '\f00d';
      color:$SEARCH_CHOICE_CLOSE_COLOR;
      display: block;
      width: 10px;
      height: 10px;
      left-align: '';
      position: absolute;
      left: 3px;
      top: 0px;
      font-size: 12px;
    }
    &:hover {
      background-position: -42px -10px;
      // background-color: #78ac06;
      background-color: $SEARCH_CHOICE_CLOSE_HOVER_BG;
      color: $SEARCH_CHOICE_CLOSE_HOVER_COLOR;
      border-radius:8px;
      position: absolute;
      top: 21%;
      right: 5px;
      display: block;
      &::before {
        content: '\f00d'; // fa-times
        color:$SEARCH_CHOICE_CLOSE_HOVER_COLOR;
      }
    }
}

.add-new-term {
    display: block !important;
    border-top: 1px solid #c3c3c3;
    padding: 0 6px;
    a.btn.btn-primary {
        border:transparent !important;
        background:transparent !important;
        color: black !important;
        padding:0 !important;
        &:hover {
            background: var(--engine-action-button-background) !important;
            color: var(--engine-action-button-color) !important;
            padding: 5px 10px !important;
            border-radius: 10px;
            text-decoration:none !important;
        }
    }
}

.chosen-container {
  .chosen-choices .search-field {
    display: none;
  }
  .chosen-choices li:only-of-type { // this is the search field
    display: block !important;
  }
  &.chosen-container-active {
    li:only-of-type {
    }
    .chosen-choices .search-field {
        display: block;
        height:auto;

    }
  }
}


// #ui-container {

    // .chosen-container .chosen-single {
    // // .chosen-containe .chosen-choices {

    //     margin: 0;
    //     height: auto!important;
    //     height: 1%;
    //     position: relative;
    //     display: block;
    //     overflow: hidden;
    //     background-color: #fff;
    //     text-decoration: none;
    //     margin-bottom: 0;
    //     cursor: pointer;
    //     background-image: none;
    //     white-space: nowrap;
    //     font-weight: 400;
    //     line-height: 1.42857143;
    //     -webkit-user-select: none;
    //     -moz-user-select: none;
    //     -ms-user-select: none;
    //     user-select: none;    
    //     li.search-field {
    //         margin: 2px 3px 0 0;
    //         white-space: nowrap;
    //     }
    // }


    // .chosen-container-multi .chosen-choices {

    //     position: relative;
    //     overflow: hidden;
    //     margin: 0;
    //     padding: 0 5px;
    //     width: 100%;
    //     height: auto;
    //     border: $INPUT_BORDER !important;
    //     background: $INPUT_BACKGROUND !important;
    //     color: $INPUT_COLOR;
    // }
// }

// .chosen-container-multi .chosen-choices li.search-choice {
//   position: relative !important;
//   margin: 0px 3px 3px 0 !important;
//   padding: 7px 25px 7px 6px !important;
//   box-shadow: 0px 0px 0px transparent !important;
//   border-radius: 1px !important;
//   border-top-left-radius: 1px;
//   border-top-right-radius: 1px;
//   border-bottom-right-radius: 1px;
//   border-bottom-left-radius: 1px;
//   background: #f3a20e !important;
//   border-color: #f3a20e !important;
//   color: #170f0f !important;
// }

// .chosen-container {
//  max-height: 35px !important;
//     overflow: hidden;
//     &.chosen-container-active {
//      position: relative !important;
//      overflow: auto;
//      max-height: 100% !important;
//     }
// }

// .chosen-container-active .chosen-choices {
//  border-radius: 7px 7px 0 0 !important;
// }

// .chosen-container-multi .chosen-choices {
//     position: relative !important;
// }

// .chosen-container-active .chosen-drop {
//  position: relative !important;  
// }

// #ui-container .form-group .input-group .chosen-container{
//  &.chosen-container-active {
//      background:orange;
//      max-height: auto;
//          .chosen-drop {
//          }
//  }
// }

////////// ACTIVITY SPECIFIC FORM STYLES //////////
// .activity-filter-section,
// .activity-forms {
//   .form-group {
//     // width: auto !important;
//     margin-bottom: 10px;
//     position:relative;
//     .control-label { //label
//       font-size: 13px;
//       width: auto;
//       color: #444;
//     }
//     .form-control { // input
//       height:$base-INPUT-HEIGHT;
//       border: $base-INPUT-BORDER;
//       background: $base-INPUT-BACKGROUND;
//       color: $base-INPUT-COLOR;
//       border-radius:$base-INPUT-BORDER-RADIUS;
//       padding:$base-INPUT-PADDING;
//       box-sizing: border-box;
//       &::placeholder {
//         font-style: italic;
//         font-size: $im-filterBuddy-font-setting;
//         padding-top: 9px;
//         color:$base-INPUT-PLACEHOLDER-COLOR;
//       }
//     }
//       //    textarea.form-control {
//       //      height:70px;
//       //    }
//       .chosen-container{
//         border: $base-INPUT-BORDER;
//         background: $base-INPUT-BACKGROUND;
//         color: $base-INPUT-COLOR;
//         min-height:$base-INPUT-HEIGHT !important;
//         border-radius:$base-INPUT-BORDER-RADIUS;
//       }
//       .chosen-container-multi{
//         min-height:$base-INPUT-HEIGHT !important;
//       }
//       .chosen-container-single {
//         min-height:100%;
//       }
//       &.chosen-active .chosen-single {
//         height:$base-INPUT-HEIGHT;
//       }
//       .chosen-single {
//         line-height: 28px;
//         color: $base-INPUT-COLOR;
//         // background:$base-INPUT-BACKGROUND;
//         background:transparent !important;
//         box-shadow: initial;
//         min-height: $base-INPUT-HEIGHT;
//         min-height: 31px;
//         border-radius:$base-INPUT-BORDER-RADIUS;
//         height: 100% !important;
//         display: block;
//         border: 0px solid transparent;
//         box-sizing: border-box;
//         padding-top: 5px;
//         span {
//           font-style: italic;
//           font-size: $im-filterBuddy-font-setting;
//           padding-top: 0px;
//           padding-bottom: 2px;
//           color:$base-INPUT-PLACEHOLDER-COLOR;
//         }
//       }
//       .chosen-drop {
//         border: $base-INPUT-BORDER;
//         border-top: 0px solid transparent !important;
//         background: $base-INPUT-BACKGROUND;
//         -webkit-box-shadow: initial !important;
//         box-shadow: initial !important;
//         right:-1px;
//         left:-1px;
//       }
//       .chosen-search {

//       }
//       .chosen-choices {
//         background:$base-INPUT-BACKGROUND;
//         min-height:$base-INPUT-HEIGHT !important;
//         border:0 solid transparent;
//         // border-radius:($base-INPUT-BORDER-RADIUS - 1);
//         border-radius:7px;
//         // padding:$base-INPUT-PADDING !important;
//         padding:0px 4px 3px 10px !important;
//         box-shadow: initial !important;
//         // -webkit-box-shadow:initial;
//         li.search-field input[type="text"] {
//           font-style: italic;
//           font-size: $im-filterBuddy-font-setting;
//           padding-top: 9px;
//           color:$base-INPUT-PLACEHOLDER-COLOR;
//         }
//       }
//       .search-choice {
//         // float: left;
//         position: relative !important;
//         margin: 6px 3px 3px 2px !important;
//         padding: 3px 25px 3px 6px !important;
//         box-shadow: 0px 0px 0px transparent !important;
//         border-radius: 1px !important;
//         border-top-left-radius: 1px;
//         border-top-right-radius: 1px;
//         border-bottom-right-radius: 1px;
//         border-bottom-left-radius: 1px;
//         background: $CORPORATE_BRAND_COLOR !important;
//         border-color: $CORPORATE_BRAND_COLOR !important;
//         color: $CORPORATE_WHITE !important;
//         border-radius: 7px !important;
//       }
//   }
// }



// <div class="
// chosen-container chosen-container-multi chosen-with-drop chosen-container-active
// " title="" id="app_base3_activitytype_category_chosen" style="width: 100%">
// chosen-container chosen-container-multi chosen-with-drop chosen-container-active
// ul.chosen-choices {
//   // background:orange;
//   li.search-choice {
//     background:red;
//     span {
//       // background:gold;

//     }
//     a.search-choice-close {
//     }
//   }
//   li.search-field {
//     // <input class="chosen-search-input" type="text" autocomplete="off" value="Select Some Options" style="width: 25px;">
//     .chosen-search-input {

//     }
//   }
// }

// .chosen-drop {
//   ul.chosen-results {
//     li {
      
//       &.result-selected {

//       }
//       // " data-option-array-index="0">Deos</li>
//       &.active-result {
//         &.highlighted {
//           background:$CORPORATE_BRAND_COLOR !important;
//           color:$CORPORATE_WHITE;
//         }
//       }
//     }
//   }
// }

// <!-- Chosen -->
// @import '../../node_modules/chosen-js/chosen.css';

// .chosen-container-single .chosen-default {
//     color: #999 !important; 
//     border-radius: 0 !important;
//     background: white !important;
// }
// .chosen-container-single .chosen-default {
//     color: #999 !important;
//     background: white !important;
//     border-radius: 0 !important;
//     height:auto !important;    
// }

// .chosen-container .chosen-single,
// .chosen-container-active .chosen-single,
// .chosen-container.chosen-with-drop .chosen-single,
// .chosen-container-active.chosen-with-drop .chosen-single {
//     border: 1px solid #aaa !important;
//     border-bottom-right-radius: 0 !important;
//     border-bottom-left-radius: 0 !important;
//     background-image: none !important;
//     -webkit-box-shadow: 0 1px 0 #fff inset !important;
//     box-shadow: 0 1px 0 #fff inset !important;
//     border-radius: 0 !important;
// }

// .chosen-container-single .chosen-single span {
//     font-size: 16px !important;
//     color: #333 !important;
//     font-weight: bold !important;
//     padding: 5px 0 2px !important;
//     display: block !important;
//     overflow: hidden !important;
//     margin-right: 26px !important;
//     text-overflow: ellipsis !important;
//     white-space: nowrap !important;
// }


// .chosen-container-active.chosen-with-drop .chosen-single div b {
//     background-position: -21px 7px;
// }
// .chosen-container-single .chosen-single div b {
//     display: block;
//     width: 100%;
//     height: 100%;
//     background: initial !important;
//     position:relative;
// }

// .chosen-container-single .chosen-single div b:before {
//     content: "\f0d7";  /*fas fa-caret-down  this is your text. You can also use UTF-8 character codes as I do here */
//     font-family: FontAwesome;
//     left:-5px;
//     position:absolute;
//         top: 5px;
//     font-size: 20px;
//  }


// .chosen-container-active .chosen-choices {
//     border: 1px solid #3f83de;
// }
// .chosen-container-multi .chosen-choices {
//     margin: 0;
//     height: auto!important;
//     height: 1%;
//     position: relative;
//     display: block;
//     overflow: hidden;
//     background-color: #fff;
//     text-decoration: none;
//     margin-bottom: 0;
//     cursor: pointer;
//     background-image: none;
//     border: 1px solid #e1e5ea;
//     white-space: nowrap;
//     padding: 2px 5px;
//     font-weight: 400;
//     line-height: 1.42857143;
//     border-radius: 2px;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
// }

// .chosen-container-multi .chosen-choices li.search-field {
//     margin: 2px 3px 0 0;
//     white-space: nowrap;
// }

// .chosen-container-multi .chosen-choices li.search-field {
//     margin: 2px 3px 0 0;
//     white-space: nowrap;
// }
// .chosen-container-multi .chosen-choices li {
//     float: left;
//     list-style: none;
// }

// .chosen-container-multi .chosen-choices li.search-field input[type=text] {
//     margin: 1px 0;
//     padding: 0;
//     height: 25px;
//     outline: 0;
//     border: 0!important;
//     background: transparent!important;
//     box-shadow: none;
//     color: inherit;
//     border-radius: 0;
// }


//// from button.scss
