// $toggle-switch-label-color:#333;
// $toggle-switch-checked-color:#4db446;
// $toggle-switch-unchecked-color:#876363;
$toggle-switch-label-offset:50px;
$toggle-switch-checked-color:green; //$CORPORATE_HEADER_BG;
$toggle-switch-checked-border:1px solid $toggle-switch-checked-color;
$toggle-switch-unchecked-color:#e33a3a;
$toggle-switch-unchecked-border:1px solid $toggle-switch-unchecked-color;


.toggle-switchq+label {
    display: inline-block;
    position: relative;
    height: 16px;
    max-height: 1em;
    cursor: pointer;
    -webkit-touch-callout: none;
    margin-right: 0;
    margin-bottom: 0;
    padding-left: $toggle-switch-label-offset;
    line-height: 16px;
    font-weight: 400;
    color:$toggle-switch-label-color;
    &.text-light {
        color:#a8a5a5;
    }
}

// .unchecked-filter {
//     color: #afb9c3;
// }

.toggle-switchq+label:before {
    position: absolute;
    display: inline-block;
    left: 0;
    content: '';
    border-style: solid;
    // width: 30px;
    width: 40px;
    // height: 16px;
    height: 20px;
    border-width: 0;
    border-color: transparent;
    background-color: $toggle-switch-unchecked-color;
    box-shadow: 0;
    // border-radius: 8px;
    border-radius: 10px;
    transition: all .3s;
}

.toggle-switchq:checked+label:before {
    // background-color: #4db446;
    background-color: $toggle-switch-checked-color;
    transition: all .3s;
}

.toggle-switchq+label:after {
    position: absolute;
    content: '';
    width: 25px;
    // width: 14px;
    height: 25px;
    // height: 14px;
    top: -2px;
    left: -1px;
    background-color: white;
    box-shadow: 0 2px 5px 0 rgba(51,51,51,0.25);
    border-radius: 20px;
    // border-radius: 16px;
    transition: left .3s,right .3s;
    border:$toggle-switch-unchecked-border;
}

.toggle-switchq:checked+label:after {
    left: 17px;
    transition: left .3s,right .3s;
    border:$toggle-switch-checked-border;
}

.btn-group-has-toggle {
    position: relative;
}

.toggle-switchq {
    opacity: 0;
    position: absolute;
    width: 42px;
    background: orange none repeat scroll 0% 0%;
    z-index: 99;
    display: block;
    height: 17px !important;
    cursor: pointer;
}


// .audit-trail-list-item .toggle-switchq + label:before {
//     width: 40px;
//     height: 20px !important;
//     background-color: red;
// }

// .audit-trail-list-item .toggle-switchq + label:after {
//     width: 26px;
//     height: 26px;
//     top: -3px;
//     left: -1px;
//     background-color: white;
//     border-radius: 16px;
//     border: 2px solid green;
// }

// .audit-trail-list-item  .toggle-switchq:checked + label:before {
//     background-color: green;
// }