.main-drill-tree-section #tableRow0 {

  .drill-toggle {
    display: none;
  }
}

.minify-reports {
  .table-striped tbody tr {
    height: 28px;
    td {
      height: 13px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    padding: 2px 2px 2px 0;
    font-size: 11px;
    line-height: 1 !important;
    white-space: nowrap;
      > span {
        height:15px;
        padding-top:7px;
        &.double-line {
          padding-top:2px;
        }
      }
    }
  }
}

.minify-reports .drill-report-wrapper .datatable-table.table-striped tbody tr td > span {
 padding-left:0 !important;
 // background:red !important;
 .title-span {

 }
}

// }
.minify-reports .laydown-activity-pulse {
  height:23px;
}
 // .table-striped tbody tr td > span

.minify-reports tr#tableRow0 td span {
  visibility: hidden;
}

tbody tr td:first-of-type {
  .tr-depth0 {
    // background:blue;
  }
  .drill-title.span-depth0 {
    padding-left:30px !important;
  }
  .tr-depth1 {
    left:40px !important;
    // background:orange;
  }
  .drill-title.span-depth1 {
    padding-left:60px !important;
  }
  .tr-depth2 {
    left:70px !important;
    // background:green;
  }
  .drill-title.span-depth2 {
    padding-left:90px !important;
  }
  .tr-depth3 {
    left:100px !important;
    // background:gold;
    display: none;
  }
  .drill-title.span-depth3 {
    padding-left:120px !important;
  }
}

.drill-row {
  opacity: 1;
        -webkit-transition: .3s;
    &.ng-enter {
        -webkit-transition: .3s;
        transition: .3s;
        opacity: 0;
    }
    // &.ng-enter-stagger {
      // transition-delay: 1.7s;
      // animation-delay: 0.8s;
    // }
    &.ng-enter-active {
        opacity: 1;
    }
}
.drill-row-1 {

}
.drill-row-2 {

}
.drill-row-3 {

}
.drill-row-4 {

}
// sub-repeating-form-field


// drill-full.html.twig
.table-striped tbody tr:hover td {  
  .drill-toggle {
    color:$CORPORATE_WHITE !important;
    .fa-fw {
      color:$CORPORATE_WHITE !important;

    }
  }
}

.datatable_column-has-toggle {
  // background: red;
}
.datatable_column-header-has-toggle {
  // background:orange;
  .table-header-filter > span {
    // background: blue;
    padding-left:20px;
  }
}

.datatable_column-header-has-toggle .table-header-filter-span {
  // padding-left:100px;
}


.datatable_column-has-no-toggle {
    margin-left: -35px;
}

.drill-toggle {
  &.drill-tree-header_0 {
    left:-10px;
  }
  &.drill-tree-header_1 {
    left:-10px;
  }
  &.drill-tree-header_2 {
    left:-10px;
  }
// -10px
}
.drill-report-wrapper .table-striped tbody tr:first-child td {
  > span {
    visibility: hidden;
  }
  .drill-toggle {
    display:none;
  }
}

.drill-report-wrapper .table-striped tbody tr td > span {

  padding-left: 22px !important;
}

.drill-report-wrapper .table-striped tbody tr td:first-of-type {
  padding-left: 11px;
  > span {
    padding-left:28px;
  }
  &.drill-toggle-column {
    padding-left: 45px !important;
  }
}


.drill-report-wrapper #tab-datatable .table-striped tbody tr td > span {
  padding-left: 25px;

}
.drill-report-wrapper #tab-datatable .table-striped tbody tr td:first-of-type {
  padding-left:25px;
} 

.drill-report-wrapper .table-striped tbody tr td:first-of-type {
    padding-left: 26px;
}

.drill-row td:first-of-type {

  // left: 11px;
}

.drill-toggle { // <a>
  // &.first-column {
      // left: 13px;
  // }
  position:absolute;
  left: 0;
  cursor: pointer;
  z-index: 99;
  font-size: 17px;
  // font-size: 20px;
  top: 6px;
  cursor: pointer;
  display: inline-flex;
  color:$CORPORATE_ACTION_COLOR;
  // -moz-transition: all 0.3s ease;
  // transition: all 0.3s ease;
  right:26px;
  height: 21px;
  width: 26px;
  box-sizing:border-box;
  padding-top:3px;
  transform-origin: center center;
  .fa-fw { // <i>
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color:$CORPORATE_ACTION_COLOR;
    position: absolute;
    top: 5px;
    left: 0;
    display: block;
  }

  &:hover {
    color:$CORPORATE_WHITE;
    text-decoration: none !important;
        // transform: rotate(18deg) scale(1);
    .fa-fw { // <i>
      color:$CORPORATE_WHITE;
      // text-shadow:  -1px -2px rgba(0,0,0,0.15);
      &.fa-plus,
      &.fa-plus-hexagon {
      }
      &.fa-minus,
      &.fa-minus-hexagon {
        // transform: rotate(10deg) scale(1);
      }
    }
  }
}

.table-header-filter .drill-toggle {
  &:hover{
    color:$CORPORATE_BLACK !important;
    i {
      color:$CORPORATE_BLACK !important;

    }
  }
}

// Animation styles for the
// .drill-row {
//   // -moz-transition: all .s ease;
//   // transition: all .1s ease;
//   opacity:1;
//   &.animate-on-change-add {
//     // opacity:0.5;
//     // opacity:.5;
//   }
//   &.animate-on-change {
//     // opacity:.5;
//   }
//   &.animate-on-change-remove {
//     transition: all .3s ease;
//     opacity:0;
//     // opacity:.5;
//   }
// }


.header-span-has-toggle {
  padding-left:23px !important;
}
.table-header-filter-no-drill {
  // padding-top: 6px;
}
.ng-table-header { // stylesheet for modules/roi_report/templates/datatable.html.twig
  .btn.btn-default {
    margin:0;
      /* margin-right: 15px; */
      padding: 3px 1px 1px 1px;
      position: absolute;
      top: 0;
      height: 16px;
          padding-top: 4px;

      .fal {
        border: 1px solid #e4dddd;padding: 4px 6px 3px 5px;
        font-size: 9px;
        width: 20px;border-radius: 12px;
        border:0px solid #ccc;
        padding:0;
        float: left;
        display: block;
      }
      > span {
        display:none;float:left;
      }
      .tooltip-content-section{
        display:none;
        
        .btn.btn-primary {
        }
      }
  }
  .table-header-filter { // holds the buttons for toggle as well as filters
    position:relative; 
    width: 100%;
    display: block;
  }
  .table-header-filter:not(.table-header-filter-no-drill){ // only show padding when on the tree style headers \\TEMPFIX
    padding-right: 28px;
    margin-left: 7px;
    .drill-toggle {
      bottom:3px !important;
    }
  }
}

.drill-row td {
  &:first-of-type .drill-toggle {
    left:13px;
  }
  .drill-toggle {
    left:4px;
  }
}

.has-drill-toggle {
  // padding-left: 34px !important;
}


.drill-toggle-column {
  padding-left: 34px !important;
}


.wrap-laydown.minify-reports {
  // background: red;
}
.take-measurements {
  border-bottom:1px solid transparent;border-right:0;
}


.tab-content .tab-pane {
    min-height: 300px; 
}
