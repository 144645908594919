/*https://softwareengineering.stackexchange.com/questions/392205/how-to-keep-consistency-across-the-application-architecture-as-a-team-grows*/


.filter-buddy #filter_select_chosen {
    width: 200px;
    position: absolute;
    top: 8px;
    right: 115px;
}
.filter-buddy-actions-wrapper {
    width: 100%;
    position: absolute;
    height: 60px;
    right: 0;
        top: 0;
}
.filter-buddy-actions {
    background: #e7e7e7;
    padding: 10px;
    width: auto;
    float: right;
}
.filter-buddy-actions > span {
    float: left;
}
.filter-buddy-actions > span:not(:last-child) {
    margin-right:10px;
}

.table {
  tr {
    -webkit-transition: .5s;
      opacity: 1;
  }
  tr.ng-enter {
      -webkit-transition: .5s;
      transition: .5s;
      opacity: 0;
      height:0;
  }
  tr.ng-enter-active {
      opacity: 1;
      height:20px;
  }
}

.table-waiter {
    -webkit-transition: 3s;
    /*background: orange;*/
    opacity: 1;
}
.table-waiter.ng-enter {
    opacity: 0;
}
.table-waiter.ng-enter.ng-enter-active {
    opacity: 1;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

/* The starting CSS styles for the enter animation */
.force.ng-enter {
  transition:0.5s linear all;
  opacity:0;
}

/* The finishing CSS styles for the enter animation */
.force.ng-enter.ng-enter-active {
  opacity:1;
}


/* After the transition this will be the only class remaining */
.force {
  -webkit-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 6s;
  -moz-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 6s;
  -o-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 6s;
  transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 6s;
  opacity: 1; /* Default value but added for clarity */
}

/* Initial state when showing */
.force.ng-hide {
    background:red;

}
.force.ng-hide-remove {
    background:green;
    opacity: 0.5;
    display: block !important;
}

/* Will transition towards this state */
.force.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}




.btn-group.bootstrap-select {
    vertical-align: top !important;
}


.btn:not(.disabled):not(:disabled).active {
    box-shadow: inset 0 0px 0px rgba(0,0,0,0.2);
}

// .btn.btn-default.active {
//     border: 1px solid #e6e5e5;
//     border-top: 1px solid transparent;
//     background: #e6e5e5;
//     border-bottom: 3px solid #e6e5e5;
//     color: #3e3535 !important;
// }

.pagination > .active > a {
    background: #e6e5e5;
    color: #3e3535 !important;
}

.panel-info.filter-buddy {
    position:relative;
   .panel-heading > a {
        position: absolute;
      right: 3px;
    }
}

.expanderiser .fa-plus-circle {
    display: block;
}
.expanderiser .fa-minus-circle {
    display:none;
}
.expanderiser.clicked .fa-plus-circle {
    display: none;
}
.expanderiser.clicked .fa-minus-circle {
    display:block;
}   

#toggle_filter_buddy {
    display: block;
    position: relative;
    left: 0;
        margin: 0;
}

.filter-buddy .panel-title .fa-expand {
    position: absolute;
    right: 15px;
}


                // <div class=""
                // <div class=""
// .page.ng-hide-add, .page.ng-hide-remove {
//    -webkit-transition: 1000ms;
// }

//////////////////// The animations are challenging
// [ui-view].ng-enter,
// [ui-view].ng-leave {
//   transition: all ease 1.5s;
// }

// [ui-view].ng-enter,
// [ui-view].ng-leave-active {
//   opacity: 0;
// }

// [ui-view].ng-leave,
// [ui-view].ng-enter-active {
//   opacity: 1;
// }


// .panel.ng-enter,
// .panel.ng-leave {
//   transition: transform 1.5s ease;
// }

// .panel.ng-enter {
//   transform: translateX(50px);
// }

// .panel.ng-enter-active {
//   transform: translateX(0);
// }

// [ui-view] {
//   -webkit-transition: all 0.6s;
//   -moz-transition: all 0.6s;
//   -o-transition: all 0.6s;
//   transition: all 0.6s;
// }

// [ui-view].ng-enter .panel {
//     animation stuff;
// }
//                 // <div class=""
//                 // <div class=""
// .page.ng-hide-add .panel, .page.ng-hide-remove .panel {
//     display:block!important;
// }

// .popup.ng-hide-add .child h1 {
//     -webkit-animation: 450ms bounceInRight;
// }

// .popup.ng-hide-add .child h2 {
//     -webkit-animation: 750ms bounceInRight 250ms;
// }


.mega-widget {
  header {
    left:float;
    .column-title {
      position:relative;
      border:1px solid grey;
      padding:0;
      float:left;
    }
  }
  main {
    float:left;
    .events {
      position:relative;
      float:left;
      // .table-striped > tbody > tr:nth-child(even) {
      &:nth-child(even) {
        background-color: #444644 !important;
      }
      // .table-striped > tbody > tr:nth-child(odd) {
      &:nth-child(odd) {
        background-color: #2d2d2d !important;
      }
    }
    .event {
      position:absolute;
      top:15%;
      height:70%;


      background:red;
      color: white;

      font-size: 12px;

      line-height: 1em;

          line-height: 1em;
      padding: 4px 0 0 10px;
      text-align: left;
      
    }
    .week {
      padding:0;
      float:left;
    }
    .day {
      font-size: 8px;

      text-align: center;
      float:left;
      box-sizing:border-box;
      border:0px solid green;
      background:orange;
      // #syncscroll-right-page tbody td:nth-child(odd) {
      &:nth-child(odd){
        background: #ffffff12;
      }
    }
  }
}

.laydown-datatable .tab-content {
    // overflow-y: scroll !important;
    // overflow-x: hidden !important;
}


// **********************************************************************
// directive styles
// **********************************************************************

$color1: #343e3d;
$color2: #607466;
$color3: #aedcc0;
$color4: #7bd389;
$color5: #38e4ae;
$spacing: 14px;
.resizable {
    position: absolute;
    &.no-transition {
        transition: none !important;
        background-color: $color4;
    }
}
.resizable {
    text-align: center;
    box-sizing: border-box;
    width: 300px;
    height: 50px;
    background-color: $color5;
    border-radius: 10px;
}
.inner {
    box-sizing: border-box;
    overflow: hidden;
    padding: 1em;
    height: 100%;
    width: 100%;
}

.rg-right, .rg-left, .rg-top, .rg-bottom {
    display: block;
    width: $spacing; height: $spacing; line-height: $spacing;
    position: absolute;
    z-index: 1;
    // @include user-select(none);
    background: transparent;
    span {
        position: absolute;
        box-sizing: border-box;
        display: block;
        border: 1px solid #ccc;    
    }
}
.rg-right, .rg-left {
    span {
        border-width: 0 1px;
        top: 50%; margin-top: -10px;
        margin: -10px 0 0 $spacing/4;
        height: 20px;
        width: $spacing/2;
    }
}
.rg-top, .rg-bottom {
    span {
        border-width: 1px 0;
        left: 50%;
        margin: $spacing/4 0 0 -10px;
        width: 20px;
        height: $spacing/2;
    }
}
.rg-top     {
    cursor: row-resize;
    width: 100%;
    top: 0;
    left: 0;
    margin-top: -$spacing;
}
.rg-right   {
    cursor: col-resize;
    height: 100%;
    right: 13px;
    top: 0;
    margin-right: -$spacing;
}
.rg-bottom  {
    cursor: row-resize;
    width: 100%;
    bottom: 0;
    left: 0;
    margin-bottom: -$spacing;
}
.rg-left    {
    cursor: col-resize;
    height: 100%;
    left: 13px;
    top: 0;
    margin-left: -$spacing;
}

/////////////////////////// hide/show

.panel-title > a {
  transition:opacity .3s;
  color:$LIGHTTEXT !important;
  &.open {
    opacity:50%;
  }
}

#Slider {
    
}
#Actual {
    background: silver;
    color: White;
    padding: 20px;
}
// .slideup, .slidedown {
//     // max-height: 0;            
//     overflow-y: hidden;
//     -webkit-transition: max-height 0.5s ease-in-out;
//     -moz-transition: max-height 0.5s ease-in-out;
//     -o-transition: max-height 0.5s ease-in-out;
//     transition: max-height 0.5s ease-in-out;
// }
// .slideup {            
//     max-height: 0;            
//     // max-height: 60px !important;                    
// }

// .slideup, .slidedown {
//     // max-height: 0;            
//     overflow-y: hidden;
//     -webkit-transition: max-height 0.5s ease-in-out;
//     -moz-transition: max-height 0.5s ease-in-out;
//     -o-transition: max-height 0.5s ease-in-out;
//     transition: max-height 0.5s ease-in-out;
// }
.slidedown {
  display:block;
}            
.slideup {            
  display:none;
    // max-height: 0;            
    // max-height: 60px !important;                    
}

.panel-info .panel-body.inset-panel-body,
.panel-body.inset-panel-body {
  background: #343534;
  border-top: 1px solid #2f2f2f;
  border-bottom: 1px solid #4d4e4d;
}