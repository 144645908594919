////////////////////////////////
//      AUDIT TRAIL           //
////////////////////////////////

.modal-body {
	background:$CORPORATE_WHITE;
	padding:0 !important;
	background-image:initial !important;
	    overflow-y: auto !important;
	    // max-height:.6vh !important;
	.box {
		padding:15px;
		float: left;
		// background: gold;
		width:100%;
	}
	h4 {
		color:$CORPORATE_BRAND_COLOR;
		margin: 0;
		word-break:break-word;
	}
	.capsule {
		font-size: 11px;
		padding:3px 6px;
		border-radius:10px;
		background:$CORPORATE_GREY_LIGHT;
		color:$CORPORATE_BLACK;
		display: inline;
	}
	p {

	}
}

.modal-body.modal-admin-section .fx-container {
	margin-left:15px;
}

.modal-body.modal-admin-section > a{
	display: none;
}

.modal-body.modal-admin-section .audit-trail-list-item .btn{
	    margin-left: 15px;
}

.audit-trail-list { // ul
	list-style:0;
	padding:0;
	width:100%;
	float:left;
}
.audit-trail-list-item { // li
	display:block;
	width:100%;
	float:left;
	position:relative;
	float: left;
    padding: 10px 15px;
    // overflow: hidden;
	&:nth-of-type(even){
		// background:red;
	}
	&:nth-of-type(odd){
		background:#8483801f;
	}
}
.audit-trail-list-avatar {
    height:40px;
    width:40px; 
    border-radius:20px;
    overflow:hidden;
    float:left;
    position: absolute;
    left: 15px;
    right: 0;
    img {
    	width: 40px;
    	height: 40px;
    }
}
.audit-trail-list-description {
   	float:left;
   	margin-right:45px;
   	padding: 10px 45px 5px 55px;
    p {
    	color:$CORPORATE_BLACK;
    }
}
.audit-trail-list-timedate {
	width: 80px;
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
        padding-top: 15px;
    p {
    	font-size: 10px;
	    color: #444;
	    line-height: 1.6em;
	    margin-top: 10px;
      }
}

.pretty-checkbox {
    float: left;
}