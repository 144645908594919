
.image-lock {

}


.image-lock {
    margin-top:15px;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    background: white !important;
    border-radius: 4px;
    border: 1px solid #c3c3c3;
    position: relative;
    // tokyo-audio-grassroots-03.webp.png
    overflow:hidden;
    img {
        width:auto;
        height:98px;
        margin-left:0px;
        margin-top:0px;
    }
}

.image-lock > button {
    width: 98px;
    border-radius: 4px;
    background: transparent;
    position: absolute;
    height: 98px;
    border: 1px solid transparent;
    left: 0px;
    top: 0px;
}

// .image-lock {
//     width: 100px;
//     height: 100px;
//     background: #bdb9b22b;
//     border-radius: 4px;
//     border: 1px solid #c3c3c3;
//     position:relative;
// }

// .image-lock > button {
//     width: 98px;
//     border-radius: 4px;
//     background: transparent;
//     position: absolute;
//     height: 98px;
//     border: 1px solid transparent;
//     left: 0px;
// }
// .image-lock > img {
//     width:64px;
//     height:64px;
//     margin-left:18px;
//     margin-top:18px;
// }

.inventory-table {

    .image-lock {
        width: 37px;
        height: 37px;
        background: #bdb9b22b;
        border-radius: 2px;
        border: 1px solid #c3c3c3;
        position:relative;
    }

    .image-lock > button {
        width: 35px;
        border-radius: 2px;
        background: transparent;
        position: absolute;
        height: 35px;
        border: 1px solid transparent;
        left: 0px;
            left: -2px;
    top: -2px;
    font-size: 0px;
    }
    .image-lock > img {
    width: 33px;
    height: 35px;
    margin-left: 1px;
    margin-top: 0px;
    }
}

.prettycheckbox > a, 
.prettyradio > a {
    position: relative;

    background-image:initial !important;
    &::before {
        font-size: 32px;
        font-family: 'Font Awesome 5 Pro';
        color:#818181;
        position: absolute;
        left:0;
        top:0;
        display: block;
        height:30px;
        width:30px;
        content:'\f0c8';
    }
}
.prettycheckbox > a.checked, 
.prettyradio > a.checked {
    position: relative;
    background-image:initial !important;
    &::before {
        color:#818181;
        font-size: 32px;
        font-family: 'Font Awesome 5 Pro';
        position: absolute;
        left:0;
        top:0;
        display: block;
        height:30px;
        width:30px;
        content:'\f14a';
    }    
}

.rep-header {
    font-weight:bold;
    margin-bottom:10px;
}
