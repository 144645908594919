.main.engine_play {
	padding: 50px;
	h1 {
		color:lightgreen;
	}
}

.dashboard-area {
	    margin-top: 50px;
}

.download-block {
	// background:$LINK_DISABLED;
	// background-image:$LINK_DISABLED_TEXTURE;
	// padding:20px;
	// border:1px solid #c3c3c3;
	// border-radius:8px;
	// max-height: 200px;
	position: relative;
	// border-bottom: 3px dashed;
	img {
		max-width: 100%;
		// max-height: 110px;
		max-height: 43px;
		float: left

	}
	.label {
		color:#282829;
		text-transform: uppercase;
		position: absolute;
		// background-image:$LINK_DISABLED_TEXTURE;
	 //    background:$LINK_DISABLED;
	    left: 0;
	    border-radius: 0 !important;
	    bottom: 4px;
	    width: 100%;
      display: block;float: left;
		  background: black;
		            padding: 4px;
	}
}
.dl-block {
	// float:left;
	// display:block;
	// width:110px;
}
.dl-title {
	// width: 86px;
	// display: block;
	// word-break: break-word;
	// color: white;
	// line-height: 1em;
	// text-align: left;
	// float: left;
}


.flatten-out {
	.panel {
		margin-bottom:10px;
	}
	.col-md-6 {
		padding-left:0 !important;
		padding-right:0 !important;
	}
}

.masonry { 
  display: flex;
  flex-flow: row wrap;
  margin-left: -8px; /* Adjustment for the gutter */
  width: 100%;
}

.masonry-brick {
  flex: auto;
  height: 250px;
  min-width: 150px;
  margin: 0 8px 8px 0; /* Some gutter */
  .phlyf {
  	color:white;
  }
}

.masonry-brick:nth-child(4n+1) {
  width: 250px;
}
.masonry-brick:nth-child(4n+1):nth-child(4n+2) {
  width: 325px;
}
.masonry-brick:nth-child(4n+1):nth-child(4n+3) {
  width: 180px;
}
.masonry-brick:nth-child(4n+1):nth-child(4n+4) {
  width: 380px;
}

// .orkz-is-da-best {
//     position: relative;
//     transition:opacity .5s;
//     &.loading {
//         background:transparent !important;

//         > .panel-heading {
//             opacity:0;

//         }
//         > section {
//             > .panel-body {
//                 opacity:0;
//             }
//         }
//         > .panel-footer {
//             opacity: 0;
//         }
//         &::before {
//             position: absolute;
//             content:'';
//             width:100%;
//             height:100%;
//             top:0;
//             background-repeat: no-repeat;
//             background-size: 95px;
//             background-position: center 40%;
//             background-image:url(/xponential-design.gif);
//         }
//     }
// }
 // min-height: 169px !important; 

/* Have to set height explicity on ui-view 
to prevent collapsing during animation*/
// .hellowell[ui-view]{
.hellowell-wrapper{

 // min-height: 278px !important; 
 min-height: initial !important; 
 display:block;
 // background:blue;
}

.hellowell{
 // min-height: 278px !important; 
 min-height: initial !important; 
 // background:orange;
 display:block;
}

.ui-view-container {
  position: relative;
}

// .hellowell[ui-view].ng-enter, [ui-view].ng-leave {
// .hellowell[ui-view].ng-enter, 
.hellowell[ui-view].ng-enter {
  position: absolute;
  left: 0;
  right: 0;
  -webkit-transition:all 1.05s ease-in;
    -moz-transition:all 1.05s ease-in;
    -o-transition:all 1.05s ease-in;
    transition:all 1.05s ease-in;
}

.hellowell[ui-view].ng-enter {
  opacity: 0;
  -webkit-transform:scale3d(0.75, 0.75, 0.75);
  -moz-transform:scale3d(0.75, 0.75, 0.75);
  transform:scale3d(0.75, 0.75, 0.75);
}

.hellowell[ui-view].ng-enter-active {
  opacity: 1;
  -webkit-transform:scale3d(1, 1, 1);
  -moz-transform:scale3d(1, 1, 1);
  transform:scale3d(1, 1, 1);
}

.hellowell[ui-view].ng-leave {
  opacity: 1; 
  -webkit-transform:translate3d(0, 0, 0);
  -moz-transform:translate3d(0, 0, 0);
  transform:translate3d(0, 0, 0);
}

.hellowell[ui-view].ng-leave-active {
  opacity: 0;
  -webkit-transform:translate3d(20px, 0, 0);
  -moz-transform:translate3d(20px, 0, 0);
  transform:translate3d(20px, 0, 0);
}





// .table-striped tbody tr:hover {
//     color: #fefefe;
//     background: #cc092f;
// }

// .table-striped tbody tr:nth-child(even) {
//     background-color: #c5c5c53d;
//     border-top-color: #c5c5c53d;
//     border-bottom-color: #c5c5c53d;
// }
// .table-striped tbody tr:hover {
//     background: #cc092f !important;
// }
// .table-striped tbody tr:hover {
//     cursor: pointer;
// }

// workflow stuff


.workflow-h4 {
  font-size: 14px;
  line-height: 1.3em;
  margin: 0;
  padding-left: 10px;
  width:100%;
  padding:5px;
  color:#444;
  box-sizing:border-box;
  &.workflow-decision {
    color:orange;
    background:#595959 !important;
  }
}


.choose-input-group {
  .prettycheckbox {
    text-align: center;
    float: left;
    margin-top: -2px;
  }
}

.accordion-inner .pretty-checkbox {
  
	float: left;
}


.accordion-group.milestone-accordian {


    .accordion-inner {
        padding:0;
        margin-top: 20px;
        &:first-of-type {
        }
    }

    .data-field {
        // background:green;
        button {
            background:transparent !important;
            border:0px solid transparent;
            padding:0;
            margin:0;
            margin-left: -8px;
            margin-bottom: 5px;
            text-align: right;
            text-align: right;
            float: left;
            i {
              padding: .15em .15em .15em .15em;
              float: right;
              margin: 0px 0px 0px 10px;
              border-radius: 2px;
              font-size:25px;
            }
        }
    }

}


.workflow-actions {
  float:left;
}

.workflow-decision-action {

    width: 25px;
    height: 25px;
    border-radius: 16px;
    display: block;
    line-height: 1em;
    padding: 4px 1px 1px 3px;
    float: left;
    margin-right: 5px;
}


.data-field.widgetz {
    .status-widget {
        position:absolute;
        top:0;
        background:white;
        padding:20px;
        position: absolute;
        top: 0px;
        // background: black;
        background: #c3c3c3;
        border: 1px solid #444;
        width: 150px;
        height: 133px;
        z-index: 9999999;
        right: 0;
        border-radius:8px;
        &.position-over-checkbox {
          position: absolute;
          right: -29px;
          top: -58px;
        }
  }
}


.btn-statusWidget { // button
  margin-left:10px;
  font-size:12px; width:100%;text-align:left;
  color:#444;


  .btn-status-default { // span
    i {
      font-size: 25px !important;
      margin: 0px -2px 0px 5px !important;
    }
    background: transparent
  }

  .btn-status-default { // span
    font-size: 25px;
  }
  .btn-status-question { // span
    padding: 2px 0 0 0;
    float: right;
    border-radius: 16px;
    width:25px;
    height:25px;
    margin-left:10px;
    font-size: 17px !important;

    i {
      margin:0;
      font-size: 17px !important;
      &::before {
        margin-left: -4px;
      }
    }
  }
  .btn-status-text { // span
    color:$CORPORATE_BLACK;
    font-size:14px;
  } 
}

.workflow-tool-header {
  h4 {
    color:#444;    
  }
  h5 {
    color:#444;    

    &.capsule {
      b {

      }
    }
  } 
}



.workflow-tool-additional-control {
  position: absolute;
  right: 9px;
  top: 4px;
  i {
    background-color: transparent !important;
    font-size: 25px;
    color:rgb(129, 129, 129);
  }
}

.audit-trail-list-item.focused-task {
  background: #c096181f !important;
}

// base3box.test/?Authorization=Bearer 553de96776c7bb8f962791d320fff57955e3&route=metadata_manager_router&master_id=1&master_type=pipeline&return_url=n360-74-info.base.website%2Fcrm_commpipe_amend.php%3Fcrm_commpipe_id%3D61228

.metadata-manager-layout {
  .section-header .section-title {
    top: -9px;
  }
  .panel.panel-info > .panel-heading {
    // height: 0 !important;
    // padding: 0 !important;
    // min-height: 0 !important;
    display: none;
  }
  .panel-heading-controls.meta-data-menu {
    top: 12px !important;
    // top: -11px !important;
    // right: 0px !important;
    // width: 440px !important;
  }
  .audit-trail-list-avatar img {
    width: initial;
    height: 40px;
    max-height: 100%;
  }
  .gantt-pagination-footer {
    display:none;
  }

  .panel-heading-controls .nav-tabs > li a {
      // color: #1d1d1b;
      background: #1d1d1b !important;
      border-top: 1px solid #abb1b7 !important;
      border-bottom: 1px solid #abb1b7 !important;
  }

  .panel-heading-controls .nav-tabs > li a:hover {
      color: white;
  }
  .panel-heading-controls .nav-tabs li a:hover {
    margin-top: 5px !important;
    background:#1d1d1ba6;
    padding-bottom: inherit !important;
  }
}

.modal-header {
  height: 43px !important;
}
.modal-header h4 {
    color: #fefefe !important;
    text-transform: uppercase;
    margin-top: 5px;
}
.contains-context.buildbuild .modal-content {
    border-radius: 16px 16px 16px 16px;
    // border-radius: 16px 16px 0px 0px !important;
    overflow: hidden;

}


.modal-body {
    border-left: 1px;
    // border-radius: 0 0 16px 16px;
    border-radius: 0 0 0px 0px !important;
}
.modal-close i {
    color: #fefefe;
    font-size: 22px !important;
    margin-left: 4px !important;
    margin-top: 7px !important;
  }

   
   // form[name="license"] {
   // form[endpoint="'contract'"] {
   .contract-dt-override {
    background: pink;
.gantt-pagination-footer {
    display: none;

  }
   }


.box {
  .modal-body .box {
    float: left;
    width: 100%;
  }
}

.audit-trail-list.actual-at {
    float: left;
    overflow-y: scroll;
    height: 400px;
   .audit-trail-list-description {
    width: calc(100% - 155px);

   }
   .audit-trail-list-timedate {
    width: 300px;
    p {
      text-align: right;
      margin-right:15px;
      font-size: 12px;
      margin-top:30px;
    }
  }

}

#ui-container .license_tracker-container {
  .form-group .input-group:not(.valid) {
    select[required] + chosen-container {
      .chosen-single.chosen-default {
         background-color: #c096181f !important;

      }
    }
    .form-control[required] {
      background-color: #c096181f !important;
    }
  }
}
.metadata-manager-layout {
  .audit-trail-list-item {
      padding:0px 10px 0px 10px !important;

  }
  .audit-trail-list-description {
    position: relative;
    // padding:0px 10px 0px 10px !important;
  }
  .audit-trail-list-timedate {
    padding-top: 5px;
  }
  .workflow-h4 {
                    margin-top: 5px !important;
              }
}

.btn.btn-default.navigator-control {
  text-decoration: none;    
  // padding: 13px 0 0 40px;
}