$sidebar-background:$SIDEBAR_BG;
$sidebar-border:$SIDENAV_BORDER;

$sidebar-offset-top:$TOPNAV_HEIGHT;
$sidebar-width:$SIDENAV_WIDTH;

$sidebar-button-border:0px solid transparent !important;
$sidebar-button-boxshadow:initial !important;
$sidebar-button-background:transparent; //$SIDENAV_BACKGROUND;
$sidebar-button-color:$SIDENAV_COLOR;
$sidebar-button-active-background:$SIDENAV_ACTIVE_BACKGROUND;
$sidebar-button-active-color:$SIDENAV_COLOR;
$sidebar-button-hover-background:$SIDENAV_ACTIVE_BACKGROUND;
$sidebar-button-hover-color:$SIDENAV_COLOR;
$sidebar-button-fontsize:14px;

#mainnav-container {
    background: $sidebar-background;
    padding-top: 45px !important;
    position: fixed;
    transition-property: width,padding,left,right;
    transition-duration: .35s;
    position: absolute;
    border-right:$sidebar-border;
    // width: $nav_vertical-width;
    width: $sidebar-width;
    left: -$sidebar-width;
    padding-top: 40px;
    top: 0;
    bottom: 0;
    z-index: 444;
    min-height: 100%;
    left: -170px;
    padding-top: $sidebar-offset-top;
    &.reveal {
        left: 0;
    }
    .navbar-header {
        display: none !important;
        width:$sidebar-offset-top;
        box-sizing: border-box;
        background: $sidebar-background;
        height: $sidebar-offset-top;
        // border-bottom: $navbar-header-border-bottom;
     }   
    .btn-group {
        // position: absolute;
        right:0;
        width: 100%;
        .btn {
        > span {

                display: block;
    word-wrap: break-word;
    white-space: break-spaces;
        }
        max-width: 100%;
                
            position: relative;
            width: 100%;
            text-align:left;
            padding-left:40px;
            position:relative;
            height:40px;
            border: $sidebar-button-border;
            box-shadow:$sidebar-button-boxshadow;
            background:$sidebar-button-background;
            color:$sidebar-button-color;
            font-size:$sidebar-button-fontsize; 
            padding-top: $sidebar-button-fontsize;

            &.active{
                // background:$sidebar-button-active-background;
                color:$sidebar-button-active-color;
            }
            &:hover{
                // background:$sidebar-button-hover-background;
                color:$sidebar-button-hover-color;
            }
            > i.ra,
            > i.fa,
            > i.fal,
            > i.fas {
                transition-property: width,padding,left,right;
                transition-duration: .35s;
                width: 22px;
                text-align: center;
                position: absolute;
                top: 14px;
                right: 12px;
            }
        }
        .btn  {
            > span.ng-hide {
              opacity: 0;
            }
            > span.ng-hide-add {
              transition: all linear 0.2s;
            }
            > span.ng-hide-remove {
            }
            > span.ng-hide-remove {
              transition: all linear 0.3s;
              transition-delay: 0.3s;
            }
        }
    }
}


// Style to align the navigation on hte lhs
.same-as-sidebar {
    padding: 56px 0px 0px 50px;
    &.hide-mainnav {
        padding: 56px 0px 0px 0px;

    }
}



