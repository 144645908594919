$navigator-background:$NAVIGATOR_BACKGROUND;
$navigator-border:$NAVIGATOR_BORDER;

$navigator-offset-top:$TOPNAV_HEIGHT;
$navigator-width:$SIDENAV_WIDTH;

$navigator-button-border:0px solid transparent !important;
$navigator-button-boxshadow:initial !important;
$navigator-button-background:$NAVIGATOR_BACKGROUND;
$navigator-button-color:$NAVIGATOR_COLOR;
$navigator-button-active-background:$NAVIGATOR_ACTIVE_BACKGROUND;
$navigator-button-active-color:$NAVIGATOR_COLOR;
$navigator-button-hover-background:$NAVIGATOR_ACTIVE_BACKGROUND;
$navigator-button-hover-color:$NAVIGATOR_COLOR;

// https://css-tricks.com/the-extend-concept/
.alt-navigator, 
.navigator { 
  .navigator-icon {
    position: relative;
    img {
      max-height: $TOPNAV_ICON_HEIGHT;
      max-width: $TOPNAV_ICON_HEIGHT;
    }
    display: block;
    width:50px;
    height:50px;
    float: left;
  }
  .navigator-banner {
    float: left;
    width: 131px;
    display: block;
    // overflow: hidden;
    position: relative;
    img{
        max-height: 25px;
        margin-top: 15px;
        position: absolute;
        top: 0px;
        right: 0;
        height: 40px;
    }
  }
  header {
    // background:pink;
    // padding:5px 15px;
    float: left;
    text-align: center;
  }
  main {
    width:100%;
    float:left;
    // background:lightgreen;
    // padding:15px;
    > section {
      width:100%;
      > div {
        width:100%;
      }
      > nav {
        width:100%;
        .btn-group {
          width:100%;
          
        }
      }
    }
  }
  .navigator-icon {
    min-height: 45px !important;
    height: 45px !important;
    position: relative;
    margin-top:5px;
    cursor:pointer;;
    img {
      max-width: 100%;
      width: auto;
      margin: 0; //$TOPNAV_ICON_PADDING;
      max-height: 25px; //$TOPNAV_ICON_HEIGHT;
      height: 25px; //$TOPNAV_ICON_HEIGHT;
      // margin: 5px 0 0;
      // max-height: 25px;
      // height: 25px;
      position: absolute;
      top: 7px;
      left: 10px;
    }
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    float: left;
    padding-top: 5px;
  }
  .btn-group {
      position: absolute;
      right:0;
      width: 100%;
      .btn {
          width: 100%;
          text-align:left;
          padding-left:40px;
          position:relative;
          height:40px;
          border:$navigator-button-border;
          box-shadow:$navigator-button-boxshadow;
          background:$navigator-button-background;
          color:$navigator-button-color;
          &.active {
              background:$navigator-button-active-background;
              color:$navigator-button-active-color;
          }
          &:hover {
              background:$navigator-button-hover-background;
              color:$navigator-button-hover-color;
          }
          i.ra,
          i.fa,
          i.fal,
          i.fas {
              transition-property:width,padding,left,right;
              transition-duration:.35s;
              width:22px;
              text-align:center;
              position:absolute;
              top:14px;
              // right:12px;
              left:9px;
          }
      }
      .btn  {
          > span.ng-hide {
            opacity: 0;
          }
          > span.ng-hide-add {
            transition: all linear 0.2s;
          }
          > span.ng-hide-remove {
          }
          > span.ng-hide-remove {
            transition: all linear 0.3s;
            transition-delay: 0.3s;
          }
      }
  }
}

.display-number {
    position: absolute;
    bottom: 15px;
    font-size: 9px;
    min-width: 14px;
    text-align: center;
    color: white;
    background: red;
    border-radius: 10px;
    height: 15px;
    line-height: 1em;
    box-sizing: border-box;
    padding: 3px;
    left: 6px;
    text-align: center;
}


.toggle-chat {
    // bottom: 0;
    // position: absolute;
    // background: gold;
    width: 50px;
    height: 50px;
    text-align: center;
    border: 0px transparent;
    background:  transparent;
    img {
      max-width: 30px;
      padding-left: 2px;
    }
}

.topnav-wrapper.navigator-open.chat-open {
  padding-right:504px;
}
.topnav-wrapper.chat-open {
  padding-right:354px;
}

.chat-open section.contains-context.build-background {
    right: 354px;
}
.navigator-open.chat-open section.contains-context.build-background {
    right: 484px;
}

.navigator-open.chat-open .buildbuild {
    right: 484px;
}
.chat-open .buildbuild {
    right: 354px;
}


// .navigator-control {
//     background: white;
//     // color: black;
//     // width: 100%;
//     // display: block;
//     // padding: 5px;
//     // width: 100%;
//     width: 100%;

//   text-align: left;

//   // padding-left: 40px;

//   position: relative;

//   height: 40px;
//     > i {
//       margin-right:5px;
//     }
// }
// .navigator-control.active {
//   border:2px solid #282828;
//   border-left:none;
//   border-right:none;
// }





// /* entire container, keeps perspective */
// .flip-container {
// 	perspective: 1000px;
// }
// 	/* flip the pane when hovered */
// 	.flip-container:hover .flipper, .flip-container.hover .flipper {
// 		transform: rotateY(180deg);
// 	}

// .flip-container, .front, .back {
// 	width: 320px;
// 	height: 480px;
// }

// /* flip speed goes here */
// .flipper {
// 	transition: 0.6s;
// 	transform-style: preserve-3d;

// 	position: relative;
// }

// /* hide back of pane during swap */
// .front, .back {
// 	backface-visibility: hidden;

// 	position: absolute;
// 	top: 0;
// 	left: 0;
// }

// /* front pane, placed above back */
// .front {
// 	z-index: 2;
// 	/* for firefox 31 */
// 	transform: rotateY(0deg);
// }

// /* back, initially hidden pane */
// .back {
// 	transform: rotateY(180deg);
// }


.navigator-icon {
  img {

  }

}
.navigator-open {


  .navigator-banner {

    img {
      display: block;

    }
  }
}



  .navigator-banner {

    img {
      display: none;
    }
  }