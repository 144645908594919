body {
  overflow-y: hidden;
  overflow-x: hidden;
}



/*
<header class="section-header">
    <h2 class="section-title">
        <span class="badge"><i class="fal fa-fw fa-wrench"></i></span>
        <span class="badge-h3">Maintainance</span>
        <span class="badge-h4">Manage your system variables and assets here</span>
    </h2>
</header>

$DASH_BG:$fademoss;
$DASH_H2:#d3d3cc !important;
$DASH_H4:#22190d !important;
$DASH_OFFSET:-30px;
$DASH_ICON:#8b8282;
$DASH_ICON_RADIUS:#8b8282;

$sectionHeader = angular('.section-header');
$sectionTitle = $sectionHeader.find('.section-title');
$sectionHeaderBadge = $sectionTitle.find('.badge');
$sectionHeaderBadgeI = $sectionHeaderBadge.find('i');
$sectionHeaderBadgeH3 = $sectionHeaderBadge.find('.badge-h3');
$sectionHeaderBadgeH4 = $sectionHeaderBadge.find('.badge-h4');

var formData: {
  "sectionHeader":$sectionHeader.css('background-color'),
  "sectionTitle":$sectionTitle.css('color'),
  "sectionHeaderBadge":sectionHeaderBadge.css('background-color'),
  "sectionHeaderBadgeI":$sectionHeaderBadgeI.css('color')
  "sectionHeaderBadgeH3":$sectionHeaderBadgeH3.css('color'),
  "sectionHeaderBadgeH4":$sectionHeaderBadgeH4.css('color'),
}
$DASH_BG:"sectionHeader";
$DASH_H2:#d3d3cc !important;
$DASH_H4:#22190d !important;
$DASH_OFFSET:-30px;
$DASH_ICON:#8b8282;
$DASH_ICON_RADIUS:#8b8282;
$DASH_H2:#d3d3cc !important;
$DASH_H4:#22190d !important;
// $DASH_OFFSET:-30px;
$DASH_ICON:#8b8282;
$DASH_ICON_RADIUS:#8b8282;

var formDataFixture{
  "sectionHeader":#d3d3cc,
  "sectionHeaderBadge":sectionHeaderBadge,
  "sectionHeaderBadgeIColr":#8b8282,
  "sectionHeaderBadgeH3":"#d3d3cc",
  "sectionHeaderBadgeH4":"#22190d",
}

*/

.section-header-wrapper,
.section-content-wrapper {
  float:left;
  width:100%;
}

.section-header {
  .section-title {
    height:30px;
    box-sizing:border-box;
    color: $CORPORATE_WHITE;
    position: relative;
    margin-bottom: 0;
    .badge {
      border-radius:$DASH_ICON_RADIUS;
      background:$DASH_H2;
      padding: 8px;
      i {
        color:$DASH_ICON;
        font-size: 16px;
      }
      float:left;
      position: absolute;
      left:0;
      top:0;
    }
    .badge-h3 {
        color:$DASH_H2;
        float: left;
        display: block;
        font-size: 19px;
        line-height: 1em;
        width: 100%;
        margin-bottom: 2px;
        margin-left: 45px;
    }

    .badge-h4 {
         margin-left: 45px;
        color:$DASH_H4;
        float:left;
          font-size: 12px;
        line-height: 1em;        
    }
  }

}

.heading-h3 {
    color:$DASH_H2;
    float: left;
    display: block;
    font-size: 19px;
    line-height: 1em;
    width: 100%;
    margin-bottom: 5px;
}


#page{


}

.engineengine { // ui-view="engine"
  .topnav-wrapper {


  }
  .sidenav-wrapper {

  }
  .main {
  position: relative;

}

// Animate the main transition service

.main [ui-view="content"] {
  position: absolute;
  left: 0;
  right: 0;
    -webkit-transition:all .3s ease-in-out;
    -moz-transition:all .3s ease-in-out;
    -o-transition:all .3s ease-in-out;
    transition:all .3s ease-in-out;
}
[ui-view="content"].ng-enter {
  opacity: 0;
  -webkit-transform:scale3d(0.5, 0.5, 0.5);
  -moz-transform:scale3d(0.5, 0.5, 0.5);
  transform:scale3d(0.5, 0.5, 0.5);
}

[ui-view="content"].ng-enter-active {
  opacity: 1;
  -webkit-transform:scale3d(1, 1, 1);
  -moz-transform:scale3d(1, 1, 1);
  transform:scale3d(1, 1, 1);
}

[ui-view="content"].ng-leave {
  opacity: 1; 
  -webkit-transform:translate3d(0, 0, 0);
  -moz-transform:translate3d(0, 0, 0);
  transform:translate3d(0, 0, 0);
}

[ui-view="content"].ng-leave-active {
  opacity: 0;
  -webkit-transform:translate3d(0, 30px, 0);
  -moz-transform:translate3d(0, 30px, 0);
  transform:translate3d(0, 30px, 0);
}
  #ui-container {
    top: 0;
    height: 100vh;
    width: 100%;
    transition-property: width,padding,left,right;
    transition-duration: .35s; // animate in the main view
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    &.show-background{
      background-image:$BACKGROUND_PATTERN;
      background-size:$BACKGROUND_SIZE;
    }
    // Main App Display
    #page {
      margin-top: $DASH_OFFSET;
      width:100%;
      min-height: 100%;
      position: relative;
      max-width: 100vw !important;
      // height:calc(100vh - #{$nav_horizontal-height});
      // overflow:hidden;
      // position:absolute;
      // top:$nav_horizontal-height;
      box-sizing:border-box;
      opacity: 1;
      // background:red;
      transition: all .5s;
      &.not-loaded {
        // background:blue;
        opacity:0;
        // transform: scale(.8);
      }
      .main { // ui-view goes here

      }
      .modal {
        position: absolute;
        left: 50px;
        padding-right: 50px;
      }
    }
  }
}

.build-background {
  position: fixed;
  min-height: calc(104vh - 50px);
  max-height: calc(100vh - 50px);
  overflow-y: scroll;
  top: 45px;
  bottom:0;
  left: 50px;
  right: 50px;
  border: 0px solid #efefef;
  z-index: 56790; // fix for split screen effect
  width:auto;
  transition: all .5s;

  .modal-content {
    margin:40px auto 0;
    border:1px solid transparent !important;
    box-shadow:initial !important;
  }
}


.buildbuild {
  position: fixed;
  min-height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
  overflow-y: scroll;
  top: 45px;
  bottom:0;
  left: 50px;
  right: 50px;
  border: 0px solid #efefef;
  // padding:15px;
  z-index: 56790; // fix for split screen effect
  width:auto;
  
    // .section-add {
    //   background: white;
    //   border: 1px solid #c3c3c3;
    //     box-shadow: 4px 4px 0px #5a5a5a;
    //       max-width: 600px;
    //     margin: 0 auto;
    //     padding:15px;
    //     .btn-add {
    //       float: right;
    //     }
    // }
  .modal-content {
    // max-width: 600px;
    margin:40px auto 0;
    // background:transparent !important;
    border:1px solid transparent !important;
    box-shadow:initial !important;
  }
}


.loading-modal.buildbuild::before {
  opacity:0;
}
.buildbuild::before{
    transition:all .5s;
   content: "";
   background: inherit; 
   position: absolute;
   left: 0;
   right: 0;
   top: 0; 
   bottom: 0;
   background:#ffffff82;
   background-image:$buildbuild-bg;
   // background-image:$buildbuild-bg;
   // background-image:url('../images/patterns/carbon-fibre.png');
}
.navigator-open .buildbuild {
  right: 200px;
  // left:200px;
}
.sidebar-open .buildbuild {
  left:200px;
}
.buildbuild.hide {
  display:none !important;
}

.navigator-open#ui-container {
  // padding-right: 150px;
}

.navigator-open .navigator{
    width:200px !important;

    .navigator-control > span {
      display:inline;
    }
    .display-number {
      // left:inherit;
      // right:3px;
      display:none;
    }
}

.navigator {
    .navigator-control > span {
      display:none
    }
    .display-number {
      left:inherit;
      right:3px;
    }
  position:fixed; 
  height:100vh; 
  right:0; 
  top:0px;
  z-index:800000;
  background:$NAVIGATOR_BG;
  height:100vh;
  // position: fixed;
  transition-property: width,padding,left,right,margin;
    width: 50px !important;

  transition-duration: .35s;
}



.alert.alert-danger {
  position: fixed;
  top: 10px;
  left: 10px;
  max-width:200px;
}



.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid transparent !important;
    float: left;
    width:100%;
}


/////////////////////////////////////////

.btn.thinking {
    position: relative;
    &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 55;
    height: 100%;
    width: 100%;
    background: #706d6db8;
    background-image: url(/rmi-loader.gif);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 16px;
    }
}


.box.thinking {
    position: relative;
    &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 55;
    height: 100%;
    width: 100%;
    background: #706d6df0;
    background-image: url(/rmi-loader.gif);
    background-size:50px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0;
    }
}

.route-loading-wrapper {
  position: fixed;
  width: auto;
  top: 45px;
  bottom: 0;
  left: 50px;
  right: 0;
  z-index: 9999;
  background:$BACKGROUND-SYS;
}
.sidebar-open {
  .route-loading-wrapper {
    left: 250px;
  }
}

.full-page-action {
  padding:25px;
  background:$BACKGROUND-SYS !important;
  min-height: 100vh;
    overflow-y: scroll;
    z-index: 90000;
    position: relative;
    &.panel {
      border-radius: 0 !important;
    }
    .panel-body > .panel {
      border-radius:8px 8px 0 0;
    }
  .panel > .panel-heading {

     h2.section-title {
      height: 30px;
      color:$DASHBOARD_SECTION_TITLE;
      font-size:25px;
      font-weight:bold;
      height:auto;
      margin:0;
      padding:0
     }
     h4 {
      font-size: 14px;
      color:$DASHBOARD_SECTION_SUBTITLE;
      margin:0;
      padding:0
     }
    h4.panel-title {
        color:$PANEL_HEADER_COLOR;
    }
  }
  .section-header {
    padding-bottom:30px;
    position:relative; 
    background:transparent !important;
    padding:10px 0 0 0px;
    width: 100%;
    margin-bottom:20px;
    h2.section-title {
      color:$DASHBOARD_SECTION_TITLE;
      font-size:25px;
      font-weight:bold;
      margin:0;
      padding:0
    }
    h4 {
      font-size: 14px;
      color:$DASHBOARD_SECTION_SUBTITLE;
      margin:0;
      padding:0
    }
  }
  .form-section .form-label {
    padding-left:0px;
  }
  
}

section.contains-context.build-background {

    right: 50px;
}

.sidebar-open section.contains-context.build-background {

  left:220px !important;
}

.navigator-open section.contains-context.build-background {
    right: 200px;
    transition-property: width,padding,left,right,margin;
    transition-duration: .35s;
}


.main.main-waaagh {
    position: relative;

  &.report-view-loading::before {
    content:'';
    width:100%;
    height:400px;
    background: url('/rmi-loader.gif') no-repeat;
    display: block;
    position: absolute;
    background-position: center;
    top:30px;
        transition: all .5s;
  }
}


.extra-wrapper{
  background: #444;
  position: absolute;
  z-index: 10000000;
  right: -304px;
  width:305px;
  top: 0;
  bottom: 0;
  height: 100vh;
  transition-property: width,padding,left,right,margin;
  transition-duration: .35s;
} 


.supplier_spend_tracker_report,
.invest_csv,
.total_revenue_tracker,
.revenue_tracker_overview {
  .popover-overflow {
    overflow-x:auto;
  }
}