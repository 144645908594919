.heart{color:red};

// .tabs-below > .nav-tabs > li > a {
//     margin-top: 0;
//     margin-bottom: 4px;
//     border-radius: 0 0 2px 2px;
// }

// .nav-tabs > li > a {
//     margin-top: 4px;
//     padding-top: 6px;
//     padding-bottom: 6px;
//     line-height: 2.0825;
//     border: 1px solid #ddd;
//     border-radius: 4px 4px 0 0;
//     background-color: #eeeeee;
// }

// .nav > li > a {
//     position: relative;
//     display: block;
//     padding: 10px 15px;
//     color: #555555;
// }

// .tabs-below > .nav-tabs > .active > a, .tabs-below > .nav-tabs > .active > a:hover, .tabs-below > .nav-tabs > .active > a:focus {
//     margin-bottom: 0;
//     border-color: transparent #ddd #ddd #ddd;
// }

// .tab-content {
// 	background:orange !important;
// }

.tab-content {
    // overflow: auto;
    overflow: visible;
    padding: 0px;
    border-style: solid;
    border-color: #ddd;
    border-width: 0 1px 1px 1px;
}
