.landing {
	// backround:
}

// https://itnext.io/sharing-variables-between-js-and-sass-using-webpack-sass-loader-713f51fa7fa0
.mission-statement {
	p {}
	h5 {}
	ol {}
	ol li {}
	.fas {
		&.fa-quote-left{}
		&.fa-quote-right{}
	}
}

// #############################################


$ACTUAL_HEADER_BG:$ACTUAL_HEADER_BG; 
$CORPORATE_HEADER_BG:$CORPORATE_HEADER_BG; 
$CORPORATE_BRAND_COLOR:$CORPORATE_BRAND_COLOR; 
$CORPORATE_ACTION_COLOR:$CORPORATE_ACTION_COLOR; 
$FILTER_BUDDY_BACKGROUND:$FILTER_BUDDY_BACKGROUND;
$FILTER_BUDDY_BORDER_TOP:$FILTER_BUDDY_BORDER_TOP;
$FILTER_BUDDY_BORDER_BOTTOM:$FILTER_BUDDY_BORDER_BOTTOM;
$LAYDOWN_BG:$LAYDOWN_BG;
$PANEL_SPLITTER_BACKGROUND:$PANEL_SPLITTER_BACKGROUND;

$PANELHEADERBG:$PANELHEADERBG;
$PANELHEADERFONT:$PANELHEADERFONT;

$MAIN_BG:MAIN_BG;


// #############################################

body {
	font-family: $DEFAULT_FONT, sans-serif !important;
}
h1,h2,h3 {
	font-family: $DEFAULT_FONT, sans-serif !important;
	font-weight:700;
}
h4,h5,h6,p,li,a,select,textarea,input,button {
	font-family: $DEFAULT_FONT, sans-serif !important;
	font-weight:400;

}






// https://gist.github.com/jcamenisch/943639#file-sunburst-pattern-css
#page {

}

.engineengine #ui-container {
  background-image:initial !important;//$MAIN_BG !important;
  // background-image:$MAIN_BG !important;
  // background-size: 200vw 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

// .section-header .section-title .badge-h3 {
//     color:$DASH_H2 !important;
// }
// .section-header .section-title .badge-h4 {
//   color:$DASH_H4 !important;
// }
// .dashboard-layout .section-header h2.section-title {
//   color:$DASH_H2 !important;
// }
// .dashboard-layout .section-header h4 {
//   color:$DASH_H4 !important;
// }
// .panel-heading-controls .nav-tabs > li.active a {
//   background:$DASH_H4 !important;
//   border:1px solid $DASH_H4 !important;
//   border-bottom: 0px solid transparent !important;

// }
// .panel-heading-controls .nav-tabs > li a {
//   border-bottom: 0px solid transparent !important;
//   background:$CORPORATE_ACTION_COLOR !important;
// }

// .nav-tabs { // reset important
//     border-bottom: 0px solid #dddddd;
// }

$CORPORATE_BG:#ecf0f5; // very light green
$CORPORATE_DARKER_BG:$CORPORATE_BG;//#abb1b7; // darker green
$CORPORATE_ROW_HIGHLIGHT:cyan; // darker green
// $CORPORATE_GREY_LIGHT:#c3c3c3;
// $CORPORATE_GREY_DARK:#a5a0a0;
$CORPORATE_GREY_LIGHT:#f3f4f4;
$CORPORATE_GREY_DARK:#737171;




// $HEINEKENGREEN:$NOWBOTICABLACK;
// $HEINEKENACCENT:#444644;
// $HEINEKENGREEN:#00541c;
// $HEINEKENLIGHT:#f3f3f3;


$CORPORATE_BLACK:#444;
$CORPORATE_WHITE:#fefefe;
///////////////////////////////

$BRAND-COLOR:$CORPORATE_BG;
$BRAND-COLOR:$CORPORATE_BRAND_COLOR;
$BRAND-COLOR-COMPLIMENTARY:darkgrey;
$BRAND-HIGHLIGHT:#f5edf4; //gold;
$BRAND-SHADE:#f5edf4; //gold;


$MAIN-COLOR:white;
$MAIN-COLOR-COMPLIMENTARY:darkgrey;
$MAIN-COLOR-HIGHLIGHT:white;
$MAIN-COLOR-SHADE:white;

$ANCILLARY-COLOR:#f9f6f9; //white / pink;
$ANCILLARY-COLOR-COMPLIMENTARY:black;
$ANCILLARY-COLOR-HIGHLIGHT:lightgreen;
$ANCILLARY-COLOR-SHADE:#e1e6e1;

$DARK-COLOR:blue;
$DARK-COLOR-COMPLIMENTARY:yellow;
$DARK-COLOR-HIGHLIGHT:lightblue;
$DARK-COLOR-SHADE:darkblue;

$TEXT-COLOR:#ffffff;
$TEXT-COLOR-SUBDUED:#818181;
$TEXT-COLOR-HIGHLIGHT:#3b3236;

$DARKTEXT:#282828;
$LIGHTTEXT:#e4dddd;

$ON-COLOR:#97bb94;
$OFF-COLOR:#876363;


$BACKGROUND-COLOR:$CORPORATE_BG;
$BACKGROUND_PATTERN:initial;
$BACKGROUND_SIZE:initial;


::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: $CORPORATE_GREY_LIGHT; 
    -webkit-box-shadow: inset 0 0 0px transparent; 
    shadow: inset 0 0 0px transparent; 
    border-radius: 0px;
}

::-webkit-scrollbar-thumb {
    background: $CORPORATE_GREY_DARK; 
    -webkit-box-shadow: inset 0 0 0px transparent; 
    box-shadow: inset 0 0 0px transparent; 
    border-radius: 0px;
}


// .st0 {
//     fill: #953d31 !important;
// }
// 71

// You could set your svg as a mask. That way setting a background-color would act as your fill color.

// HTML

// <div class="logo"></div>
// CSS

// .logo {
//     background-color: red;
//     -webkit-mask: url(logo.svg) no-repeat center;
//     mask: url(logo.svg) no-repeat center;
// }

.context-toggle.widescreen {
  width:200px;
  a img {
    max-width: 100%;    
    padding: 5px;
  }
}

.topnav-icon-image.avatar-preview {
  margin-left:10px !important;
}

.sidenav-wrapper {
  // position: relative; // this breaks the background colorl unsure why
}

#mainnav-container .navbar-header {
    /* display: none !important; */
    width: 50px;
    box-sizing: border-box;
    background: #6f2f2f;
    height: 50px;
    margin-top: -50px;
    position: relative;
    z-index: 99999999999999;
}


// CONFIG

$TOPNAV_HEIGHT: 50px;

$SIDENAV_WIDTH:220px;

$TOPNAV_ICON_PADDING:50px;
$TOPNAV_ICON_HEIGHT:50px;


// Topnav
$TOPNAV_BACKGROUND:$ACTUAL_HEADER_BG;
// $TOPNAV_BACKGROUND:$CORPORATE_HEADER_BG;
$TOPNAV_COLOR:$BRAND-COLOR-COMPLIMENTARY;
$TOPNAV_BORDER:0 px transparent;

$SIDENAV_BACKGROUND:$CORPORATE_BLACK; //$CORPORATE_HEADER_BG url('../scss/images/patterns/xv.png');
$SIDENAV_COLOR:$BRAND-COLOR-COMPLIMENTARY;
$SIDENAV_BORDER:0 px transparent;
$SIDENAV_ACTIVE_BACKGROUND:$CORPORATE_GREY_LIGHT;

$NAVIGATOR_BACKGROUND:$CORPORATE_BLACK;
$NAVIGATOR_COLOR:$BRAND-COLOR-COMPLIMENTARY;
$NAVIGATOR_BORDER:0 px transparent;
$NAVIGATOR_ACTIVE-BACKGROUND:$CORPORATE_GREY_LIGHT;

$FONT_FAMILY: $DEFAULT_FONT, 'Arial', sans-serif;
$FONT_COLOR_DEFAULT: orange;

// $PANEL_HEADER_BGCOLOR:$HEINEKENMIDDARK;
// $PANEL_HEADER_BGIMAGE:url('/ecailles.png');
// $PANEL_HEADER_BGIMAGE:'';
// $PANEL_HEADER_COLOR:#ffffff;
// $ROW_HIGHLIGHT_COLOR:#f3f17580;
// $ROW_HIGHLIGHT_COLOR:#f3f17580;

//////////////////////////////////
/*           PANEL             */
//////////////////////////////////

$panel-background-color:$MAIN-COLOR;
$panel-border-color:$MAIN-COLOR;

$panel-margin-bottom: 10px;
$panel-border: 1px solid $panel-border-color;
$panel-border-radius: 8px 8px 1px 1px;
$panel-border-bottom-color: $panel-background-color;
$panel-box-shaddow: 0 1px 1px rgba(0, 0, 0, 0.05);
$panel-border-radius: 8px;

/*           PANEL INFO HEADING            */
$panel-header-background:$PANELHEADERBG;
$panel-header-font-color:$PANELHEADERFONT;
$panel-header-border-border-color:$PANELHEADERBG;
$panel-heading-min-height:23px;
$panel-header-fontsize:20px;
$panel-header-border-radius:8px 8px 0 0;

/*           PANEL INFO BODY              */
$PANEL_BODY_BORDER:0px solid $MAIN-COLOR;
$PANEL_BODY_BACKGROUND:$MAIN-COLOR; // url('/nature.png');

/*           PANEL TITLE              */
$panel-title-color:$CORPORATE_WHITE;
$panel-title-font-weight:500;
$panel-title-font-size:13px;
$panel-title-text-transform:uppercase;
$panel-title-line-height:1em;
$panel-title-letter-spacing:initial;
$panel-title-icon-color:$CORPORATE_WHITE;
$panel-title-icon-display:inline;
$panel-title-link-color:$CORPORATE_WHITE;
$panel-title-link-focus-color:blue;
$panel-title-link-hover-color:blue;
$panel-title-link-hover-text-decoration:none;
$panel-titleDOTinfo-panel-heading-link_color:$CORPORATE_WHITE;


/*           PANEL FOOTER            */
$panel-footer-padding: 5px 15px;
$panel-footer-background-color: $CORPORATE_WHITE;
$panel-footer-border-top: 1px solid $CORPORATE_WHITE;;
$panel-footer-background:$CORPORATE_WHITE;
$panel-footer-border-bottom-right-radius: 16px;
$panel-footer-border-bottom-left-radius: 16px;

//////////////////////////////////
/*           MODALS       */
//////////////////////////////////

// $MODAL_HEADER_BGCOLOR:$CORPORATE_BRAND_COLOR;
$MODAL_HEADER_BGCOLOR:$CORPORATE_HEADER_BG;
$MODAL_HEADER_BORDER:1px solid $CORPORATE_BRAND_COLOR;
$MODAL_HEADER_BORDER_RADIUS:8px 8px 0 0;
$MODAL_HEADER_FONTSIZE:14px;
$MODAL_HEADER_COLOR:$CORPORATE_WHITE;
$MODAL_CONTENT_BGCOLOR:$CORPORATE_WHITE;
$MODAL_HEADER_TEXT_TRANSFORM:initial;

//////////////////////////////////
/*  PANEL SPLITTER CONTROL      */
//////////////////////////////////

$panel-splitter-control-handle-border: 0px solid $CORPORATE_WHITE !important;
$panel-splitter-control-handle-border-top: 1px solid #444 !important;
$panel-splitter-control-handle-border-bottom: 1px solid #444 !important;
$panel-splitter-control-handle-background: #444 !important;
$panel-splitter-control-handle-graphic:url('/divider-bar.png');
//////////////////////////////////
/*  PANEL NAV TABS              */
//////////////////////////////////

$nv-padding: 0;
$nv-margin: 0;
$nv-float:right;
$nv-li-padding:0;
$nv-li-margin:0;
$nv-li-a-cursor:pointer;
$nv-li-a-background:$CORPORATE_GREY_DARK;
$nv-li-a-border:1px solid $CORPORATE_GREY_DARK;
$nv-li-a-border-radius:8px 8px 0 0;
$nv-li-a-padding:0 10px;
$nv-li-a-margin:0 0 0 10px;
$nv-li-a-color:$CORPORATE_WHITE;

$nv-li-a-active-background:$CORPORATE_BRAND_COLOR;
$nv-li-a-active-border:1px solid $CORPORATE_BRAND_COLOR;
$nv-li-a-active-border-radius:8px 8px 0 0;
$nv-li-a-active-color:$CORPORATE_WHITE;



//////////////////////////////////
/*       CONTEXT PANE           */
//////////////////////////////////

// $buildbuild-bg:initial;
$buildbuild-bg:none;
$buildbuild-bg-color:#636e6fb5;

//////////////////////////////////
/*           DRAG TARGETS       */
//////////////////////////////////

$DRAG_TARGET_BG_COLOR: red;
// $DRAG_TARGET_BG_PATTERN: ;
$DRAG_TARGET_COLOR: purple;
$DRAG_TARGET_FONT: 22px;
$DRAG_TARGET_PADDING: 8px;
$DRAG_TARGET_MARGIN: 13px;
$DRAG_TARGET_BORDER_RAD: 6px;

//////////////////////////////////
/*     FORM ELEMENTS            */
//////////////////////////////////

$FORM_ELEMENT_COLOR:cyan;
$base-INPUT-PLACEHOLDER-COLOR:$CORPORATE_BLACK;
$base-INPUT-HEIGHT:35px; //40px;
$base-INPUT-BORDER:1px solid $CORPORATE_BLACK !important;
$base-INPUT-COLOR:$CORPORATE_BLACK !important;
$base-INPUT-BACKGROUND:$CORPORATE_WHITE !important;
$base-INPUT-BORDER-RADIUS:16px;
// $base-INPUT-PADDING-INSET_LABEL: 8px 0 0 10px;
$base-INPUT-PADDING: 4px 8px 4px 10px; //$base-INPUT-PADDING: 4px 4px 4px 10px; //0;

//////////////////////////////////
/*       TOGGLE SWITCH          */
//////////////////////////////////


$toggle-switch-label-color:$CORPORATE_GREY_DARK;
$toggle-switch-checked-color:#97bb94;
$toggle-switch-unchecked-color:#876363;

//////////////////////////////////
/*       CHECKBOX               */
//////////////////////////////////

$checkbox-label-color:$CORPORATE_GREY_DARK;


//////////////////////////////////
/*       RADIO                  */
//////////////////////////////////

$radio-label-color:$FORM_ELEMENT_COLOR;


//////////////////////////////////
/*       SELECT                 */
//////////////////////////////////

$select-label-color:$FORM_ELEMENT_COLOR;


.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }



//////////////////////////////////
/*       DATATABLE                 */
//////////////////////////////////

$THEAD_BG:$CORPORATE_WHITE;
$THEAD_BORDER:0px solid transparent;
$THEAD_COLOR:$CORPORATE_BRAND_COLOR;

$ng-table-pager_padding:5px 20px;
$ng-table-pager_min-height: 40px !important;
$ng-table-pager_background: transparent !important;
$ng-table-pager_border: none !important;

$TR_ODD:0000010;
$TR_EVEN:0000030;

$pulse_background:#1a5978;
$pulse-committed_background:green;
$pulse-planned_background:orange;


$main-panel-padding:30px;

//////////////////////////////////
/*  PANEL SPLITTER CONTROL      */
//////////////////////////////////
.panel-info .panel-body  {
	padding:0px $main-panel-padding 0px !important;
	// background:transparent !important; 
}

.panel-heading-controls {
	right:$main-panel-padding !important;
	.nav-tabs.nav-justified > li a{
		margin-left:10px !important;
	}
}

.main > .panel {
  // background:transparent !important;
  > .panel-heading {
  	padding:0px $main-panel-padding 10px !important;
    // background:transparent !important; 
    .panel-title {
      font-weight: 700;
    	min-height:54px !important;
    	// color:$CORPORATE_BRAND_COLOR !important;
	    a {
	    	// color:$CORPORATE_BRAND_COLOR !important;
	    }
	    i {
		    display: block;
		    width: 29px;
		    float: left;
		    margin-right: 5px;

	    	// background: $CORPORATE_BRAND_COLOR !important;
   			color: white;
    		padding: 5px;
    		border-radius: 5px;
	    }
	}
	.panel-info.panel-body {
  		padding:0px $main-panel-padding !important;
		// background:transparent !important; 
		padding:0px $main-panel-padding 10px !important;
	}
  }
}



.panel-info .panel-body.inset-panel-body, .panel-body.inset-panel-body {
    background: $CORPORATE_DARKER_BG !important;
    border-top: 1px solid $CORPORATE_DARKER_BG !important;
    border-bottom: 1px solid $CORPORATE_DARKER_BG !important;
    padding-top: 20px !important;
	  padding-bottom: 20px !important;
}

.laydown-datatable {
	.panel-info.panel.body {
    padding:0px 0px !important;
	}
}

.main > .panel {
    overflow: visible;
    .panel-body {
        overflow: visible;
        .tab-content {
            overflow: visible;
        }
    }
}



.laydown-datatable .tab-content {
  padding-bottom:30px;
}


//////////////////////////////////
/*       BTN SWORD              */
//////////////////////////////////


$BTN_SWORD-color:$CORPORATE_GREY_DARK;
$BTN_SWORD-padding:10px;
$BTN_SWORD-text-align:center;
$BTN_SWORD-border-radius:7px;
$BTN_SWORD-font-size:14px;
$BTN_SWORD-background:$CORPORATE_WHITE;
$BTN_SWORD-border:1px solid $CORPORATE_BRAND_COLOR;
$BTN_SWORD-box-shadow:initial;
$BTN_SWORD-line-height: 1.1em;

$BTN_SWORD-color-hover:$CORPORATE_WHITE;
$BTN_SWORD-background-hover:$CORPORATE_BRAND_COLOR;
$BTN_SWORD-border-hover:$CORPORATE_BRAND_COLOR;

.btn-sword {
  padding: 15px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 100%;
  width: 100%;
  box-sizing: border-box;
  min-height: 33px;
  i {
    margin:0 auto 
  }
  span {
    width: 100%;
    display: block;
  }
}

.btn-sword {
  // <<<<<<< HEAD
  //   color:$BTN_SWORD-color;
  //   padding:$BTN_SWORD-padding;
  //   text-align:$BTN_SWORD-text-align;
  //   border-radius:$BTN_SWORD-border-radius;
  //   font-size:$BTN_SWORD-font-size;
  //   background:$BTN_SWORD-background;
  //   border:$BTN_SWORD-border;
  //   box-shadow:$BTN_SWORD-box-shadow;
  //   transition:background .2s;
  //   width: 100%;

  //   // &.small-icons
  //   // &.medium-icons
  //   // &.large-icons
    
  //   box-sizing: border-box;
  //   word-wrap: break-word;
  //   line-height:$BTN_SWORD-line-height;
  //   // margin: 20px 15px 10px 15px;
  //   // margin: 20px 0px 10px 0px;
  //   margin: 10px 0px 10px 0px;
  //   &:hover {
  //     transform:scale(1.1);
  //     color:$BTN_SWORD-color-hover;
  //     background:$BTN_SWORD-background-hover;
  //     border:$BTN_SWORD-border-hover;
  //   }
  //   i {
  //       margin: 0 auto 10px;
  //   }
  //   &.loading {
  //     transform:scale(.87);
  //     border:none;
  //   }
  //   span {
  //     @include respond-above(sm){
  //       font-size: 14px;
  //       color: #737171;
      
  // =======
    color:$BTN_SWORD-color;
    padding:$BTN_SWORD-padding;
    text-align:$BTN_SWORD-text-align;
    border-radius:$BTN_SWORD-border-radius;
    font-size:$BTN_SWORD-font-size;
    background:$BTN_SWORD-background;
    border:$BTN_SWORD-border;
    box-shadow:$BTN_SWORD-box-shadow;
    transition:background .2s;
    width: 100%;
    min-height: 78px;
    box-sizing: border-box;
    word-wrap: break-word;
    line-height:$BTN_SWORD-line-height;
    // margin: 20px 15px 10px 15px; // causes issue with add new popover

    &:hover {
      // transform:scale(1.1);
      color:$BTN_SWORD-color-hover;
      background:$BTN_SWORD-background-hover;
      border-color:$BTN_SWORD-border-hover;
    }

    i {
        margin: 0 auto 10px;
      // >>>>>>> master-permissions
    }
    @include respond-above(md){
      font-size: 14px;
      color: #737171;
    }
}

@include respond-above(sm){
  .btn-sword {
      min-height: 87px;
      max-height: 87px;
  }
}
@include respond-above(md){
  .btn-sword {
      min-height: 87px;
      max-height: 87px;
      
  }
}

#mainnav-container .btn-group .btn.active,
#mainnav-container .btn-group .btn:hover {
      background: #d6cbcb26;
      color: #fefefe;
          border-radius: 3px 0 0 3px;
}

#ui-container {
  .navigator .btn-group .btn.active,
  .navigator .btn-group .btn:hover {
      background: #d6cbcb26;
      color: #fefefe;
          border-radius: 3px 0 0 3px;
  }
  .navigator .btn-group .btn.active.inactive {
    background:transparent !important;
  }
  .navigator .btn-group {
    padding: 0 0 0 5px;
  }
}

#mainnav-container .btn-group .btn.active.inactive {
  background:transparent !important;
}






.panel-info .panel-body.inset-panel-body, .panel-body.inset-panel-body {
    background:$FILTER_BUDDY_BACKGROUND;
    border-top:$FILTER_BUDDY_BORDER_TOP;
    border-bottom:$FILTER_BUDDY_BORDER_BOTTOM;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}
.panel-heading-controls .nav-tabs.nav-justified > li a {
    border-bottom: 2px solid #dfd6d6ab;
} 




.add-form {
  .btn-sword {
    min-height: 56px;
  }
  .box {
    padding: 40px;
  }
}





.fixed-table-head .table {
  margin-bottom:0px !important;
} // radial-gradient(black, transparent)