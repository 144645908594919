/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 25, 2023 */



@font-face {
    font-family: 'montserrat';
    src: url('montserrat-bold-webfont.eot');
    src: url('montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-bold-webfont.woff2') format('woff2'),
         url('montserrat-bold-webfont.woff') format('woff'),
         url('montserrat-bold-webfont.ttf') format('truetype'),
         url('montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'montserrat';
    src: url('montserrat-italic-webfont.eot');
    src: url('montserrat-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-italic-webfont.woff2') format('woff2'),
         url('montserrat-italic-webfont.woff') format('woff'),
         url('montserrat-italic-webfont.ttf') format('truetype'),
         url('montserrat-italic-webfont.svg#montserratitalic') format('svg');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'montserrat';
    src: url('montserrat-regular-webfont.eot');
    src: url('montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-regular-webfont.woff2') format('woff2'),
         url('montserrat-regular-webfont.woff') format('woff'),
         url('montserrat-regular-webfont.ttf') format('truetype'),
         url('montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserrat';
    src: url('montserrat-bolditalic-webfont.eot');
    src: url('montserrat-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('montserrat-bolditalic-webfont.woff2') format('woff2'),
         url('montserrat-bolditalic-webfont.woff') format('woff'),
         url('montserrat-bolditalic-webfont.ttf') format('truetype'),
         url('montserrat-bolditalic-webfont.svg#montserratbold_italic') format('svg');
    font-weight: bold;
    font-style: italic;

}