
.itzakinda {
   > span {
      display: block;
   }
}

.main.engine_play {
	padding: 50px;
	h1 {
		color:lightgreen;
	}
}

.dashboard-area {
	    margin-top: 50px;
}

.download-block {
	// background:$LINK_DISABLED;
	// background-image:$LINK_DISABLED_TEXTURE;
	// padding:20px;
	// border:1px solid #c3c3c3;
	// border-radius:8px;
	// max-height: 200px;
	position: relative;
	// border-bottom: 3px dashed;
	img {
		max-width: 100%;
		// max-height: 110px;
		max-height: 43px;
		float: left
	}
	.label {
		color:#282829;
		text-transform: uppercase;
		position: absolute;
		// background-image:$LINK_DISABLED_TEXTURE;
	  //    background:$LINK_DISABLED;
	    left: 0;
	    border-radius: 0 !important;
	    bottom: 4px;
	    width: 100%;
      display: block;float: left;
		  background: black;
		            padding: 4px;
	}
}
.dl-block {
	// float:left;
	// display:block;
	// width:110px;
}
.dl-title {
	// width: 86px;
	// display: block;
	// word-break: break-word;
	// color: white;
	// line-height: 1em;
	// text-align: left;
	// float: left;
}


.flatten-out {
	.panel {
		margin-bottom:10px;
	}
	.col-md-6 {
		padding-left:0 !important;
		padding-right:0 !important;
	}
}

.masonry { 
  display: flex;
  flex-flow: row wrap;
  margin-left: -8px; /* Adjustment for the gutter */
  width: 100%;
}

.masonry-brick {
  flex: auto;
  height: 250px;
  min-width: 150px;
  margin: 0 8px 8px 0; /* Some gutter */
  .phlyf {
  	color:white;
  }
}

.masonry-brick:nth-child(4n+1) {
  width: 250px;
}
.masonry-brick:nth-child(4n+1):nth-child(4n+2) {
  width: 325px;
}
.masonry-brick:nth-child(4n+1):nth-child(4n+3) {
  width: 180px;
}
.masonry-brick:nth-child(4n+1):nth-child(4n+4) {
  width: 380px;
}

// .orkz-is-da-best {
//     position: relative;
//     transition:opacity .5s;
//     &.loading {
//         background:transparent !important;

//         > .panel-heading {
//             opacity:0;

//         }
//         > section {
//             > .panel-body {
//                 opacity:0;
//             }
//         }
//         > .panel-footer {
//             opacity: 0;
//         }
//         &::before {
//             position: absolute;
//             content:'';
//             width:100%;
//             height:100%;
//             top:0;
//             background-repeat: no-repeat;
//             background-size: 95px;
//             background-position: center 40%;
//             background-image:url(/xponential-design.gif);
//         }
//     }
// }

/* Have to set height explicity on ui-view 
to prevent collapsing during animation*/
.hellowell[ui-view]{
 min-height: 365px; 
}

.ui-view-container {
  position: relative;
}

// .hellowell[ui-view].ng-enter, [ui-view].ng-leave {
// .hellowell[ui-view].ng-enter, 
.hellowell[ui-view].ng-enter {
  position: absolute;
  left: 0;
  right: 0;
  -webkit-transition:all .5s ease-in-out;
    -moz-transition:all .5s ease-in-out;
    -o-transition:all .5s ease-in-out;
    transition:all .5s ease-in-out;
}

.hellowell[ui-view].ng-enter {
  opacity: 0;
  -webkit-transform:scale3d(0.5, 0.5, 0.5);
  -moz-transform:scale3d(0.5, 0.5, 0.5);
  transform:scale3d(0.5, 0.5, 0.5);
}

.hellowell[ui-view].ng-enter-active {
  opacity: 1;
  -webkit-transform:scale3d(1, 1, 1);
  -moz-transform:scale3d(1, 1, 1);
  transform:scale3d(1, 1, 1);
}

.hellowell[ui-view].ng-leave {
  opacity: 1; 
  -webkit-transform:translate3d(0, 0, 0);
  -moz-transform:translate3d(0, 0, 0);
  transform:translate3d(0, 0, 0);
}

.hellowell[ui-view].ng-leave-active {
  opacity: 0;
  -webkit-transform:translate3d(100px, 0, 0);
  -moz-transform:translate3d(100px, 0, 0);
  transform:translate3d(100px, 0, 0);
}





// .table-striped tbody tr:hover {
//     color: #fefefe;
//     background: #cc092f;
// }

// .table-striped tbody tr:nth-child(even) {
//     background-color: #c5c5c53d;
//     border-top-color: #c5c5c53d;
//     border-bottom-color: #c5c5c53d;
// }
// .table-striped tbody tr:hover {
//     background: #cc092f !important;
// }
// .table-striped tbody tr:hover {
//     cursor: pointer;
// }



/////////////////////////////// avatar stuffs



//https://docs.angularjs.org/api/ng/directive/ngSwitch
//Chris is an artist, artists gonna art
.animate-switch-container {
  // position:relative;
  // background:white;
  // height:200px;
  // overflow-x: hidden;
  // overflow-y: scroll;
}

.animate-switch {
  // padding:10px;
  .form-label {
    display: none;
  }
}



.animate-switch.ng-animate {
  // transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;

  // position:absolute;
  // top:0;
  // left:0;
  // right:0;
  // bottom:0;
}

.animate-switch.ng-leave.ng-leave-active,
.animate-switch.ng-enter {
    // top:-50px;
    // opacity: 0.5;
}
.animate-switch.ng-leave {
}
.animate-switch.ng-leave,
.animate-switch.ng-enter.ng-enter-active {
    // top:0;
    // opacity: 1;
}

// .animate-switch-container {
.avatar-form {
    .form-section {
      .form-label {
        display: none;
      }
      .form-items {
        width:100% !important;
        max-width: 100% !important;
      }
    }
    // .form-label {
      // width:100%;
    // }
    .form-group .input-group .chosen-container {
        visibility: hidden;
        height:0 !important;
        background: red;
        min-height: 0 !important;
        overflow: hidden;;
    }
    .form-group::after {
        display: none !important;
    }


}
.hellowell {
  .tab-content {
    .pageType-campaign {
      margin-top:40px;
      width:100%!important;
    }
  }
}

.avatar-form {

  .tab-content {

    margin-top: 0px;
  }

  .btn-sword {

    width: 30px !important;
    // color: #737171;
    padding: 8px;
    text-align: center;
    border-radius: 7px;
    font-size: 14px;
    background: #fefefe;
    border: 1px solid #3a3613;
    box-shadow: initial;
    transition: background .2s;
    width: 100%;
    // min-height: 78px;
    min-height: 52px;
    max-height: 52px;
    box-sizing: border-box;
    word-wrap: break-word;
    line-height: 1.1em;
    border-radius: 0;

    color: white;
    background:#13313a;
    border-bottom: 2px solid #6f7873 !important;
  }
}

.tab-datatable {
  .tr.take-measurements:first-of-type {
  display:none;
  }
}


.choice-grid {
    list-style: none;
    background: gold;
    padding: 0;
    li {
        float: left;
        border-radius: 8px;
        border: 5px solid #c3c3c3;
        // border: 5px solid #c3c3c3;
        margin: 10px;
        overflow: hidden;
        &.choosed {
          border: 5px solid #444;
          // border:1px solid green !important;
        }
    }
}



.user-roles-form {
  .xs-form-center {

    .form-section .form-label {
      border-top: 1px solid #444;
      border-left: 1px solid #444;
      border-right: 1px solid #444;
      border-radius: 16px 16px 0 0;
      width:100% !important;
      color:#e93cac !important;
    }
    .form-section .form-items{
      .form-group .input-group .form-control {
        border-radius: 0 0 16px 16px !important;
      }
      // background: red !important;
      width:100% !important;
    }
  }

  .xs-form-left {
    .form-section .form-label {
      // background: green !important;
      width:100% !important;
      text-align:left;
    }
    .form-section .form-items{
      // background: gold !important;
      width:100% !important;
    }
  }


  .xs-form-horizon-left {
    .form-section .form-label {
      text-align:left;
      width:40% !important;
    }
    .form-section .form-items{
      width:60% !important;
    }
  }

  .pageType-campaign .btn.btn-default.navigator-control {

  }

}

.user-roles-container {

  .hellowell .tab-content .pageType-campaign.user-roles-form {
    margin-top:20px !important;

  }
  .hellowell .tab-content .pageType-campaign {
    position: relative;
    min-height: 150px;
  }


  // div[ng-include],
  .lost_budget-viewAnimation {
    float:left;
    // background-color: yellow;
  }

}

.lost-company-container {
  .panel {
    background:$BACKGROUND-SYS;
  }
}

.user-roles-container .lost_budget-viewAnimation {
    float: left;
    width: 100%;
}
.tab-content {
  float: left;
  width: 100%;
  // background: gold;
}

.lost-company-container {
  padding: 0 20px 0 20px;
  &.contains-navigator {
      padding: 0 80px 0 20px;
  }
}


  // #ui-container .form-group
.bs-color-picker-wrapper {
  .form-control {
        padding-left: 32px !important;
        padding-right: 10px !important;
    }
    .show-color  {
      display: block;
      position: absolute;
      top: 6px;
      left: 8px;
      width: 22px;
      height: 22px;
    }
    // &
    .input-group::before {
      position: absolute;
      z-index: 99;
      content: "\f1fb";
      width: 32px;
      left: 0px;
      min-height: 32px;
      height: 35px;
      padding: 4px 10px 4px 13px;
      border-radius: 16px 0 0 16px;
      font-size: 12px;
      font-family: 'Font Awesome 5 Pro';
      border-right: 1px solid;
  }
}


.full-page-action .panel > .panel-heading h4.panel-title {
  text-align:left;  
}


.panel-heading-controls .nav-tabs {
  // width:400px;
}

.no-padding-panel {
  .pageType-campaign .panel-info .panel-body {
    padding-left:0 !important;
    padding-right:0 !important;
  }
}

#ui-container .pageType-campaign .media-touchpoint-basket .panel-info  .panel-body {
  padding:0 !important;
}

// .special-delivery .tab-content {
//     min-height:400px;
// }


.validate-if-required {
      // background: red !important;
    // right: 0 !important;
    // top: 0 !important;
    // height: 35px !important;
    // border-radius: 0 16px 16px 0 !important;
    // border-left: 1px solid #c3c3c3!important;
    // color: white;
}

// .input-group-search {
//   padding-left:40px
// }
// .input-group-search::before {

//     content:'\f002';
//     height:40px;
//     width:40px;
//     display: block;
//     top: 0 !important;
//     height: 35px !important;
//     border-radius: 0 16px 16px 0 !important;
//     border-left: 1px solid #c3c3c3!important;
//     left: 35px !important;
//     position: absolute;
// }


.input-group.input-group-search .form-control {
    text-align: left;
    // background-image: url(/calendar-alt-solid.png) !important; // https://www.sarasoueidan.com/blog/icon-fonts-to-svg/
    background-image: url(/search-solid.png) !important;
    background-repeat: no-repeat !important;
    background-size: 12px !important;
    background-position: right 15px center !important;
}

.btn-sword {
    // background: radial-gradient(black, transparent);
  .data-field-icon {
    display: block !important;
    float: left !important;
    /* background: red; */
    width: 40px !important;
    width: 40px !important;
    font-size: 23px !important;
    padding: 8px 0 0 0 !important;
    border-radius:3px;
    border:1px solid #c3c3c3;
  }
  .file-name {
    width: 92px;
    display: block;
    float: left;
    text-align: left;
        margin-left: 10px;
        &.next-to-picture {
          width:43%;
        }
  }
}

#ui-container .pageType-campaign .media-grid-panel .panel-info .panel-body {
  padding:16px 0px 0px !important;
}

.panel-has-navigator {
  .panel-body-collapser {
    margin-right:45px;
  }
}
.media-grid-panel {
  transition:all 1s;
  opacity:0;
}
.hide-while-loading {
  opacity:1;
}

#ui-container .pageType-campaign .btn-group.btn-group-vertical .btn.btn-default {
  background:transparent;
}

// main main-waaagh user-roles-container ng-isolate-scope report-view-loading
// table table-striped double-scroll-table datatable-table
// spacer-row-for-gantt ng-scope
.user-roles-container .table.datatable-table {
  .spacer-row-for-gantt th {
  }
}

.user-roles-container {
  // .table-striped thead tr th:first-of-type {
  .table-striped thead tr:first-of-type th {
    // height:0px !important;
    // height:2px !important;
    // background: gold;
    span {
      // height:0px !important;
      // height:2px !important;
      // background: blue;
    }
  }
}

.user-roles-form {
  .form-group .validate-if-required {
    left: -24px !important;
    top: -11px !important;
    bottom: initial;
    width: 23px !important;
    height: 23px !important;
    // background: #aba582 !important;
    // border-radius: 40% !important;
    // box-shadow: -3px -3px inset #444444ad, 3px 3px inset #dddddda;
    // box-shadow: -2px -2px inset #444444, 3px 3px inset #dddddd;
    .fal {
      font-size: 12px !important;
      position: absolute !important;
      // text-shadow: 1px 1px inset #dddddda, -1px -1px inset #dddddda #444444ad;
      // text-shadow: 1px 1px inset #dddddd;
      top: 6px !important;
      left: 5px !important;
      // color: #fefefe;
    }
  }
}

.form-role-details{
  border: 1px solid black; 
  border-radius: 16px; 
  font-size: 13px !important;
  font-family: 'Open Sans', sans-serif !important;
}



///////////////// settings overlay

.animate-profile-file-type {
  animation: fadeInProfile .5s;

}

@keyframes fadeInProfile {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.contains-context.buildbuild .modal-content {
    border-radius: 16px 16px 16px 16px !important;
}

.contains-context.buildbuild .modal-content .modal-body
{
  border-radius: 0 0 16px 16px !important;
  &.modal-admin-section {
    background: #d6cbcb26 !important;
  }
}
.animate-profile-file-type {
  > button {
    background:transparent; 
    border:1px solid transparent; 
  }
}
.modal-body.modal-admin-section .fx-container {
    margin-left: 15px;
    float: right;
    margin-right: 15px;
    margin-bottom: 5px;
}
.modal-admin-section {
  .edit-form-controls {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    text-align: center !important;
    margin-top: 10px;
    margin-bottom: 0;
    float: left;
    padding: 0 0 28px;
  }
  nav {
    background: #444;height:400px;
    .btn-group {
        width:100%;
        a.active {
          border-radius: 0 !important;
          background: #d6cbcb26;
          border:1px solid transparent;
          span {
            color:darkgrey;
          }
          i {
            color:darkgrey;
          }
        }
        a {
          &:hover {
            text-decoration: none;
          }
          text-align: left;
          background:#444;
          border:1px solid #444;
          padding-left: 12px;
          i {
            font-size: 16px;
            color:darkgrey;
            text-align: left;
          }
          span {
            font-size: 16px;
            color:darkgrey;
            text-align: left;
          }
      }
    }
  }
}


.profile-picture {
  padding-top: 20px;
    float: left;
    width: 100%;
    min-height: 244px;
}
.profile-picture,
.profile-nicename {

  .validate-if-required {
    i {
      color:transparent;
    }
  }
  .form-section {
    float: left;
    width: 100%;
    max-width: 100%;
    background: transparent !important;
    // background: orange;
    .form-items {
      max-width: 100%;
      width: 100% !important;
      // background: green;
      float: left;
      .form-group {
        float: left;
        // background: red;
        // .input-group {
        //   max-width:250px !important;
        //   text-align: center;
        //   margin: 0 auto;
        //   background: blue;
        // }
      }
    }
    .input-group {
        max-width: 300px  !important;
        width: 100%  !important;
        display: block  !important;
        margin: 0 auto  !important;
        text-align: center !important;
        input {
          text-align: center;
        }
    }
    .form-label {
      float: left;
      width:100% !important;
      max-width:100% !important;
      text-align: center; 
      padding:0;
      label {
        font-size: 18px;
    margin-bottom: 5px !important;
      }
    }
  }
}