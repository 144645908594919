
.gantt-datatable-row {
  &.datatable-entry-active {
    background-color: green;

  }

  td {
    span {
    }
  }
}

.gantt-chart-row {
  &.datatable-pulse-active {
    background-color: red;
  }

  td {
    span {
    }
  }
}


[ng-table-pagination] {
  // tab-data-table
  min-height: 50px;
}

// .syncscroll-left-page .table-striped tbody tr td:nth-child(3) > span{
#datatable-page .table-striped tbody tr td > span {
  // padding: 0 4px 0;
  display: block;
  width: 89px;
  word-break: break-word;
  display: block;
  white-space: normal;
  text-align: left;
}

.double-line {
  padding-top:0px !important;
  overflow: hidden !important;
}
.supplier-spend-tracker-report,
.media-revenue-report,
.total-revenue-report {
  .double-line span {
        padding: 1px 0px 3px 0px !important; // fix for narrower rows on jbp work
  }
}


.table-striped tbody tr td:nth-child(1) > span {
  // min-width: 205px;
}

.ng-table-filters {
  height: 51px;
  overflow: hidden;
}

.ng-table th.filter:nth-child(1) .input-filter {
  // width:80%;
}

.ng-table-filters > th:nth-child(1) {
  // min-width: 205px;
  position: relative;
  overflow: hidden;
}

/* .ng-table-filters .filter > {
position: absolute;
top: 50%;
left: 50%;
  transform:  translateX(-50%) translateY(-50%) rotate(-90deg);
}*/
.filter > .filter-cell {
  // position:absolute;
  // margin-top: 4px;
  // left:1;
  // top:0;
}

// table {
//     border-collapse: separate !important;
// }

// .ng-table {
//   margin-bottom: 44px;
// }


// .ng-table-pager { /* this centers the widget in the overflow container */
//   padding: 5px 20px;
//   border-top: 1px solid #dddddd;
//   background: white !important;
//   min-height: 40px !important;
//   left: 0;
//   bottom: 15px;
//   width: 100%;
//   position:absolute;
// }

// .ng-table-counts.btn-group {
//   .btn {
//     background: transparent;
//       border: 1px solid gray;
//       border-radius: 3px;
//       padding: 5px 10px;
//       color: white !important;
//       box-shadow: 0px -2px 0px #232422 inset;
//     border-radius:0;
//     &:first-child{
//       border-radius:10px 0 0 10px;
//     }
//     &:last-child{
//       border-radius:0 10px 10px 0;

//     }
//     &.active {
//         box-shadow: 0px 2px 0px #a3a99d inset;

//     }
//   }
// }


// .laydown-datatable {
//    .tab-content {
//         overflow: auto;
//         padding: 0px;
//         border-style: solid;
//         border-width: 0 1px 1px 1px;
//         border:none;
//     }
//     .panel-splitter-control {
//         padding: 0px 0;
//         width: 100%;
//         float: left;
//         height: 0;
//     }
// }

// .table {
//     width: 100%;
//     max-width: initial !important;
//     margin-bottom: 23px;

// }

// #ui-container {
//   .table-striped tfoot,
//   .table-striped thead {
//     background: $THEAD_BG !important;
//     border:none !important;
//     tr th {
//       color:$THEAD_COLOR;
//     }
// }


// .table-striped > tbody > tr:nth-child(odd) {
//   background-color: $TR_ODD !important;
// }
// .table-striped > tbody > tr:nth-child(even) {
//   background-color: $TR_EVEN !important;
// }
// .table > tbody > tr > td, .table > tfoot > tr > td {
//     padding: 5px;
//     line-height: 1.666;
//     vertical-align: top;
//     border-top: none;
// }
// .ng-table th {
//     text-align: left !important;
// }
// .ng-table-filters {
//     display:none;
// }
// .ng-table-pager {
//   padding:$ng-table-pager_padding;
//   min-height:$ng-table-pager_min-height;
//   background:$ng-table-pager_background;
//   border: $ng-table-pager_border;
//   .btn {
//     padding: 5px 11px !important;
//     font-size: 11px !important;
//   }
// }

// .ng-table-pagination {
//   margin-bottom:0;
// }
// // .pagination > .active > a, 
// .pagination > .active > span, 
// .pagination > .active > a:hover {
//     background:#555555;
// }
// .pagination > li > a, .pagination > li > span {
//   line-height: 1.666;
//   font-size: 11px;
// }
// table-layout: fixed; width: 100%"
// white-space:nowrap;
// word-break:break-all;


.table-striped tbody tr td {
  font-size: 12px;
  line-height: 1 !important;

}


.table-striped {
  width: 100%;
  border-collapse: collapse;

  thead {
    border-bottom: 1px solid white;

    tr {
      th {
        padding: 8px 0;
        border-bottom: 1px solid transparent !important;
        border-top: 1px solid transparent !important;
        border-right: 1px solid transparent !important;
        border-left: 1px solid transparent !important;
        // border-color:transparent !important;
        // padding-left:0 !important;
        // TODO THEME
        color: $CORPORATE_BRAND_COLOR;
        font-size: 13px;
        padding-top: 0px;
        padding-bottom: 0px;
        // padding:2px;
        &.gantt-chart-row td:first-of-type span {
          // padding-left: 20px;
        }

        &:first-of-type {
          // padding-left: 10px !important;
        }

        &:last-of-type {
          // padding-right: 10px !important;
        }

        span {
          white-space: nowrap;
          // padding-right: 10px;
        }
      }

      td {
        > span {
          padding: 0 4px 0;
          display: block;
          width: 69px;
          word-break: break-word;
          display: block;
          white-space: normal;
        }
      }
    }
  }

  &.tab-gantt-table {
    tr th:first-of-type span {
      padding-left: 20px !important;
    }

  }

  tbody {
    tr {
      td.highlight_arrow {
        i {
          display: none;
        }
      }
      a {
        color:var(--engine-default-text-color)
        
      }
      &.active-row {
        // color: var(--engine-tab-active-color) !important;
        //  background: var(--engine-tab-active-background)  !important;
        td.highlight_arrow {
          i {
            display: block;
            color: var(--engine-tab-active-color) !important;
          }

          background: var(--engine-tab-active-background);
          a {
            color:var(--engine-tab-active-color);
          }
        }
        &:hover {
          color: var(--engine-tab-active-color);
          background: var(--engine-tab-active-background);
        }

        &.hovering {
          color: var(--engine-tab-active-color);
          background: var(--engine-tab-active-background);

         }

      }

      -webkit-transition: .2s !important;
      height: 40px;
      color: $CORPORATE_BLACK;
      opacity: 1;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;

      &.ng-enter {
        -webkit-transition: 1s;
        transition: 1s;
        opacity: 0;
      }

      &.ng-enter-active {
        opacity: 1;
      }

      &:nth-child(even) {
        background-color: $CORPORATE_WHITE;
        border-top-color: $CORPORATE_WHITE !important;
        border-bottom-color: $CORPORATE_WHITE !important;
      }

      &:nth-child(odd) {
        background-color: #c5c5c53d; //$CORPORATE_GREY_LIGHT + 2e;
        border-top-color: #c5c5c53d; // $CORPORATE_GREY_LIGHT + 2e !important;
        border-bottom-color: #c5c5c53d; // $CORPORATE_GREY_LIGHT + 2e !important;
      }

      td {

        height: 42px;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        padding: 8px 72px 8px 0;
        font-size: 12px;
        line-height: 1 !important;
        white-space: nowrap;

        > span {
          white-space: nowrap;
          height: 25px;
          width: 100%;
          background: transparent;
          display: block;
          // padding:8px 0px 3px;
          padding: 8px 0px 3px 0px;
          box-sizing: border-box;
        }

        &:first-of-type {
          padding-left: 20px;
        }

        &:last-of-type {
          padding-right: 20px;
        }

        .td-how-to-guide{
          padding: 8px 8px 8px 0;
        }
      }

      &.active-row td {

      }

      &.gantt-chart-row,
      &.gantt-datatable-row {
        cursor: pointer;
      }

      &.gantt-chart-row td:first-of-type span {
        padding-left: 10px;
      }

      &.gantt-chart-row td span {
        min-width: 30px;
        width: 100%;
        float: right;

      }

      &:hover {
        color: var(--engine-action-button-color);
        background: var(--engine-action-button-background);
      }

      &.hovering {
        color: var(--engine-action-button-color);
        background: var(--engine-action-button-background);
      }


      // &.active-row {
      //   background:$CORPORATE_BRAND_COLOR+3d;
      //   color:$CORPORATE_BLACK;
      //   position: relative;
      //   td,
      //   td > span {
      //     color:$CORPORATE_BLACK;
      //   }
      //   // &::before{
      //   // position: absolute;
      //   // left:0;
      //   // top:0;
      //   // width:10px;
      //   // height:100%;
      //   // content:'';
      //   // }
      // }

    }
  }

  .actions-column {
    text-align: center;
    text-align: center;
    padding: 3px 0;

    a {
      display: inline-block;
    }

    img,
    > img {
      width: 23px;
    }
  }

  .status-column {
    i {
      &.status_1 {
        color: green;
      }

      &.status_0 {
        color: red;
      }
    }
  }

  .avatar-preview {
    position: relative;
    height: 35px;
    width: 35px;
    margin: 0 auto;
  }
}

.ng-table-header {
  text-align: left;
  // margin-left: -2px;
  float:left;
}


.main > .panel.laydown-datatable .panel-heading {
  // background: gold;
}

.main > .panel.laydown-datatable .panel-body {
  background: red;
}

#tab-tree .fixed-table-head,
#tab-datatable .fixed-table-head {
  background: $CORPORATE_WHITE;
  box-shadow: 0px 2px 2px #8c8a8aad;
  position: fixed;
  top: -45px;
  opacity: 0;
  // display:none;
  z-index: 5680;

  &.showing {
    top: 25px;
    // display:block;
    opacity: 1;
    z-index: 6000;
  }
}

#datatable-page {
  position: relative;
  z-index: 99;
}

#datatable-page-top {
  background: white;
}

.main-tree-section,
.main-gantt-section,
.main-datatable-section {
  position: relative;

  &.showing {
    z-index: 5690;
  }
}

#tab-gantt .fixed-table-head {
  background: $CORPORATE_WHITE;
  box-shadow: 0px 2px 2px #8c8a8aad;
  position: fixed;
  top: -50px;
  z-index: 5680;
  // z-index: 7000;
  opacity: 0;
  // display:none;
  &.showing {
    top: 25px;
    // display:block;
    opacity: 1;
    z-index: 6000;
  }
}


#datatable-page-top {
  table {
    margin-bottom: 0 !important;
  }
}

/* margin-bottom: 23px; */


.table-striped tbody tr {
  &.highlight {
    background: $CORPORATE_BRAND_COLOR;

    td {
      color: $CORPORATE_WHITE !important;
    }
  }
}

.table-striped tbody tr.active-row {
  // background: #{$CORPORATE_BRAND_COLOR}4D;
  // color: white !important;

  td {
    position: relative;

    > span {
      // color: #444;
      // font-weight: 700;
    }
  }

  & td:first-child:before {
    height: 42px;
    width: 10px;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    background: $header-background;
  }

  & td:last-child:after {
    height: 42px;
    width: 10px;
    height: 100%;
    content: '';
    position: absolute;
    right: 0;
    top: 0px;
    background: $header-background;
  }
}
.table-striped tbody tr.dynamic-row {
  // background: $CORPORATE_HEADER_BG;
}

.table-striped.tab-data-table tr td:last-child:after,
.table-striped.tab-gantt-table tr td:first-child:before {
  display: none
}

.audit-trail-list {

  // .as-sortable-placeholder {
  //   background:orange ; border:6px solid green;
  // }

  .as-sortable-placeholder,
  .as-sortable-item {
    // background:blue;
    // border:4px solid black;
    // border-radius:10px;
    padding: 25px;
    float: left;
    height: 50px
  }

  .as-sortable-item-handle {
    height: 50px;
    transition: all 1s;
    padding: 11px 25px;
  }

  .as-sortable-placeholder {
    background: $CORPORATE_GREY_DARK;
    margin-top: 2px;
    margin-bottom: 2px;
    border-top: 2px dashed $CORPORATE_GREY_LIGHT;
    border-bottom: 2px dashed $CORPORATE_GREY_LIGHT;
  }

  .as-sortable-dragging {
    border: 2px $CORPORATE_BRAND_COLOR;
    background: $CORPORATE_GREY_LIGHT;
  }
}


// .as-sortable-item {
//   background:gold; border:6px solid blue;

// } 

// .as-sortable-item {
//     border: solid 1px darkgrey;
//     background-color: #fff;
// }
// .as-sortable-item, 
// .as-sortable-placeholder {
//     min-height: 20px;
//     margin: 1px 2px 5px 1px;
//     border-radius: 10px;
// }

// https://github.com/Venturocket/angular-word-cloud

.datatable-table thead {
  // position: relative;
  .ng-table-filters {
    // display: none;
    // position: absolute;
    // width:100%;
    // top:0;
    // left:0;

  }

  .ng-table-sort-header {

  }
}

.table-header-filter > span {
  // padding-left: 2px
}

.ng-table th.sortable.sort-desc,
.ng-table th.sortable.sort-asc {
  background-color: initial !important;
  border-bottom: 1px solid #dfdfe2 !important;
}

.input-filter.form-control {

  height: 35px;
  border: 1px solid $CORPORATE_BLACK !important;
  background: $CORPORATE_WHITE !important;
  color: $CORPORATE_BLACK !important;
  border-radius: 8px !important;
  padding: 4px 4px 4px 10px;
  box-sizing: border-box;

}

.title[data-title-text="Title"] {

}


.tfoot-add-to-empty-table {
  width: 100%;
  padding: 40px;
  box-sizing: border-box;
  text-align: center;
  display: block;

  a {
    background: $CORPORATE_GREY_LIGHT;
    font-size: 35px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    padding: 0px 5px 5px;
    display: inline-block;

    i {
      font-size: 35px;
      color: $CORPORATE_GREY_DARK;
    }
  }
}

.doubleScroll-scroll-wrapper {
  // margin-top:10px;
  margin-bottom: 10px;
}

.double-scroll-scroll {
  background: #c3c3c3;
  border-radius: 8px;
}


.table-scroll thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-scroll tbody {
  max-height: 150px;
  overflow-y: auto;
  display: block;
  width: 100%;
  table-layout: fixed;
}

.table-scroll tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.table-scroll td {
  height: 47px; // needed in order to keep rows from collapsing
}


/// potentially depricated
// .page-link {
//   background:#555555;
// }
// .page-link:first-of-type,
// .page-link:last-of-type{
//   background:transparent;
// }


// .page-item.active .page-link {
//     background:#555555;
// }
// .first-row.hidden {
//     display:table-row !important;
// }
// .first-row.hidden i {
//     display: none;
// }
// .first-row.hidden span {
//     /*background-color:#eaefea !important;*/
// }
// .first-row.hidden + tr .channel i {
//     color:#758697;
// }

.ng-table-counts {
  button {
  }
}

.ng-table-counts {
  .btn {
    font-size: 12px;
  }
}


.gantt-pagination-footer {
  margin-top: 23px;
  float: left;
  width: 100%;
}

.main-gantt-section,
.gantt-table-wrapper,
#tab-datatable,
#datatable-page,
.datatable-table {
  background:var(--engine-datatable-background);
}

.ng-table-pagination {
  li {
    border: 0 solid transparent !important;
    background: transparent;
    color: purple !important;
    text-align: center;
    width: 35px;
    height: 28px;
    float: left;
    &:last-of-type {
      width: 30px;
    }
    &.active {
      a {
        border-radius: 25px;
        height: 28px;
        background: var(--engine-default-button-background) !important;
        color: var(--engine-default-button-color) !important;
        padding-top: 7px;
      }
    }
    a {
        padding-top: 7px;
        height: 28px;
        border-radius: 25px;
      border: 0 solid transparent !important;
      // background: transparent !important;
      // color: $CORPORATE_GREY_DARK !important;
      background: var(--engine-action-button-background);
      color: var(--engine-action-button-color);
      font-size: 9px;
    }
  }
}


.laydown-datatable .tab-content {
  // overflow: auto;
}


.dashboard-wrapper { //<<<<<<< BASE3-925

  #datatable-page .table-striped tbody tr td > span {
    // padding: 0 4px 0;
    display: block;
    word-break: normal;
    white-space: nowrap;
  }

  .table-striped tbody tr td {
    font-size: 16px;

  }

  .panel-body p:last-child {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.3em;
  }
  .table-striped thead tr th{
    font-size: 16px;
  }
}


.ng-table-pagination.pagination {
  margin-top:0 !important;
  margin-bottom:0 !important;
  margin-left: 23px;
}


/////testt////
th {

  &:nth-child(odd) {
    .ng-table-header {
      // background:#ff830021;
    }
  }
  &:nth-child(even) {
    .ng-table-header {
      // background: #0000ff0d;
    }
  
  }
}

.table-striped.table {
  margin-bottom:0;
}

.trth-cell-span {
  right:0;
  bottom:0;
  white-space: initial;
  display: block;
  word-break: break-word;
  float: left;
  display: block
  // line-height:1.6em;
}

.drill-toggle {
  left: -10px;
  top: 0;
}

//.gantt-table-wrapper table tbody tr td.active-period {
//  background: #FFFFE0 !important;
//}

// .table-striped {

//   &.tab-gantt-table
//   tbody {
//     tr {
//       &:hover {
//         color: $icelandWhite;
//         background: $HEINEKENBLACK;
//       }
//       &.hovering {
//         color: $icelandWhite;
//         background: $HEINEKENBLACK !important;
//       }
//     }
//   }

// }

.align-left-by-default {
  text-align: left;
}


// .trth-cell-span { // the 
//   line-height: 1.1em;
// }

.data-field.make-red {
  color: #f00;
}