/* #################################################
\\\\\\   LicenseTracker SASSYCSS   \\\\\\
##################################################*/
.license_tracker-container {
	background:#444;
}

// .new-workflow-asset-page {
.start-here {
  .form-section label {

    margin-top: 5px;
  }
  .input-group.calendar {

    // max-width: 122px !important; 
    max-width: 264px !important; 
  }

  .hero-section {
    // background: #f6f4f4;
    // padding: 20px;
    border-radius: 16px;
    margin-top:10px;
    margin-bottom:30px;
    text-align: left;
    padding-bottom: 5px;
    float: left;
    margin: 0 auto;
    width:722px !important;
    margin-bottom: 5px;
  }
  .form-section .form-label {
    padding-left: 00px;
    float: left;
    width: 290px;
    float: left;
    padding-right:10px;
    box-sizing: border-box;
    text-align: right;
    width:290px !important
  } 
  .subcategory-widget {
    width:100%;
    // background: #f6f4f4;
    // padding: 20px;
    float: left;
    border-radius: 16px;
    margin-top:10px;
    margin-bottom:30px;
    padding-bottom: 5px;
    margin-bottom: 5px;

    .rep-header {
      text-align: center;

    }

    .form-group {
      text-align: left;
    }
    .form-section .form-label {
      // text-align: right;
      width:163px;
    }
    .form-section .form-items {
          max-width: 256px;
    }
    .prettycheckbox {
      // float: right !important;
          float: none !important;
    }
  }
  .without-boxes {
    width:100%;
    // background: #f6f4f4;
    // padding: 20px;
    float: left;
    border-radius: 16px;
    margin-top:10px;
    margin-bottom:30px;
    text-align: left;
    padding-bottom: 5px;
    margin-bottom: 5px;
    .wb-target .form-section .form-label {
      margin-bottom:10px;
      width: 179px;
      text-align: right !important;
    }
    .wb-target .form-section .form-items {
      max-width: 340px;
    }
    div:nth-of-type(2),
    div:nth-of-type(3) {
      .form-section .form-label {
        width:100%;
        margin-bottom:10px;
        width: 279px;
        text-align: right !important;
      }
    }
    .avoid-row {
       width:100% !important;
       padding-left: 15px;
      .ar-left {
          width:565px;
          float:left;
          .form-section.light {
            // width:500px !important; 
          }
          .form-label {
            // background: cyan;
            // width:450px ;
          }
          .form-items {
            // width:134px !important;
            // background: magenta;
          }
       }
       .ar-right {
          width:379px !important; 
          float:left;  
          .form-section.light {
          }
          .form-label {
            width: 40px !important;
          }
          .form-items {
          }
       }
    }
  }
  .with-boxes {
    .col-sm-12 {
      width:100%; //BASE3-1408 make it vertical
    }
    width:100%;
    // background: #f6f4f4;
    // padding: 20px;
    float: left;
    border-radius: 16px;
    margin-top:10px;
    margin-bottom:30px;
    text-align: left;
    padding-bottom: 5px;
    margin-bottom: 5px;
    // width: 48%;
    // width: 470px;
    float: right;
    .form-section .form-label {
        text-align: right !important;
        max-width: 100%;
        // width: 70% !important;
        width: inherit; //BASE3-1408 make it vertical
        font-weight: bold;
        float: left;
    }
    .form-section .form-items {
        width: 30% !important;
        min-height: 50px;
    }
    .input-group.calendar {
        float: right;
    }
    .prettycheckbox {
      margin:0 auto;
      margin-top: 2px;
      float: left !important;
    }
  }
  .form-section .form-items {
      width: 246px !important;
  }
}

.a-little-more-time {
  > div:first-of-type {
    width:572px;
    padding-left:15px;
    float: left;
        height: 40px;
    // form-section .form-label {

    // }
  }
}

:root {
  --ideal-viewport-width: 1600;
}

@function scaleValue($value) {
  @return calc(
          #{$value} * (clamp(350px, 100vw, 3840px) / var(--ideal-viewport-width))
  );
}

// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
//   list-style: none;
// }

.custom-pixel-widths {
  font-size:16px;
  // font-size: #{scaleValue(18)} !important;
  // width: 30%;
  // flex-direction: row;
  align-items: center;
}


.sub-category-list-wrapper {
  max-height: 241px;
  overflow-y: scroll;
  padding: 15px;
}

 .row.use-custom-pixel-widths {
   float:left;
   width:100%;
   float: left;
   .custom-pixel-widths {
        display: block;
        float: left;
        font-size:16px;
        padding-right: 10px;
        box-sizing: border-box;
      &:nth-child(1){
        width: 124px;
        // width:scaleValue(100px);
        > p:not(.rep-header) {
          text-align: right;
        }
      }
      &:nth-child(2){
        // width:scaleValue(100px);
        width: 188px;
        > p:not(.rep-header) {
          text-align: right;
        }
      
      }
      &:nth-child(3){
        width:102px;
        > p:not(.rep-header) {
          text-align: center;
        }
        // width:scaleValue(100px);
      }
      &:nth-child(4){
        // width:scaleValue(100px);
        width:102px;
        > p:not(.rep-header) {
          text-align: center;
        }
      }
      &:nth-child(5){
        // width:scaleValue(100px);
        width:102px;
        > p:not(.rep-header) {
          text-align: center;
        }
      }
      &:nth-child(5){
        width:102px;
        > p:not(.rep-header) {
          text-align: center;
        }
      }
      &:nth-child(6){
        width:102px;
        > p:not(.rep-header) {
          text-align: center;
        }
      }
      &:nth-child(7){
        width:102px;
        > p:not(.rep-header) {
          text-align: center;
        }
      }
      &:nth-child(8){
        width:40px;
      }
      &:nth-child(9){
        width:80px;
      
      }
   }
 }


 div[ngf-drop] {
  position: relative;
  &:hover {
    border:2px dashed green !important;
  }
  // &::before {
  //   content:'Drop file here';
  //   position: absolute;
  //   background: orange;
  //   width:110px;
  //   height:40px;
  // }
 }

 .form-locked {
  &::after {
    content:'';
    width:100%;
    height:100%;
    position: absolute;
    background: #c3c3c38c;
    background-image:url("/base-loader.gif");
    background-repeat:no-repeat;
    background-position: center center;
    top:0;
    left:0;
    right:0;
    bottom:0;
  }
 }


.contract-dt-override {
  .doubleScroll-scroll-wrapper {
    height:0 !important;
  }
}