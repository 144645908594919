.panel.panel-info {
  // background-color: #b8d591 !important;
}

$buildbuild-bg:$POPOVER_BG;//url('version2-157x157.png');// $POPOVER_BG;
// .panel.panel-info[heading="avatari"] 
.modal-admin-section {
	$br:16px 16px 10px 10px !important;
	.panel.panel-info {

		background-color: $BACKGROUND-SYS !important;
		border-radius:$br;
	}
	.panel > .panel-heading .panel-title {

	}
	.panel.panel-info .panel-body {
		background:#f1f1f1 !important;
	}
	.panel >  .panel-footer {
		display:block;
		background:#444;
		border-radius:0 0 10px 10px;
	}

	.panel .btn {
		margin:10px !important;

	}
	
}

.branding {
	float:left;
}
.audit-trail-list-item {

	float: left;
    // margin: 10px 0 20px 0; causes issue with rest of code
}


.fx-container {
    display: flex; /* or inline-flex */
	// flex-direction: row | row-reverse | column | column-reverse;
	flex-direction: row;
	// flex-wrap: nowrap | wrap | wrap-reverse;
	flex-wrap: wrap ;
	// flex-flow: column wrap;
	// justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;
	// justify-content: space-around;
	justify-content: center;
	.fx-item:first-of-type {
		flex-grow: 2; /* default 0 */
		order: 1; /* default is 0 */
	}
	.fx-item:last-of-type {
		flex-grow: 4; /* default 0 */
		order: 2; /* default is 0 */
	}
}
