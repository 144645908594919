// .itzakinda {
//    > span {

//    	display: block;
//    }

// }

// .control-mini {
// 	height:30px;
// 	display: block;
// 	background: red;
// 	border:1px solid blue;
// 	width:100%;
// 	float:left;
// 	display: block;
//     color: white;
//     background: red;
//     width: 100%;
//     border: 0;
// 	button {
// 		width:100%;
// 		color:white;
// 		background:brown;
// 		border:0px;
// 	}
// 	p {
// 		width:100%;
// 		text-align: center;
// 		color:white;
// 		padding-top:5px;
// 		border:0px;
// 		background:brown;
// 	}
// }
// .display-mini {
// 	position: absolute;
// 	top: 0;
// 	background: #a52a2acc;
// 	border:3px solid brown;
// 	color: white;
// 	width: 100%;
// 	padding: 4px;
// 	span {
// 		font-size:8px;
// 	}
// }

// .panel.laydown-datatable {
// 	background-color:transparent !important;
// 	.tab-content {
// 		background-color:transparent !important;
// 		overflow: hidden;
// 		border-color:transparent !important;
// 	}
// }


// @media (prefers-color-scheme: dark) {

// }


//  .shaanrajput-card {
// 	width: 200px; 
// 	overflow: hidden;
// 	background-color: #FFFFFF;
   
// 	border-radius: 15px;
// 	box-shadow: 0 2px 4px rgba(0,0,0,0.2);
// 	text-align: center;
// 	-webkit-transition: .1s ease-in-out;
// 	transition: .1s ease-in-out;
// 	&:first-of-type{
// 		margin: 25px auto;
// 	}
 
// 	&:hover{
// 		// margin-top: -10px;
// 		box-shadow: 0 4px 8px rgba(0,0,0,0.2);
// 	}
// 	.banner{
// 		height: 100px;
// 		width: 100%;
// 		padding-top: 30px;
// 		background-color: #FAFAFA;
// 		background-size: cover;
// 		background-position: center;
// 	}

// 	.avatar{
// 		    overflow: hidden;
// 		height: 100px;
// 		width: 100px;
// 		margin: auto;
// 		background-size: cover;
// 		background-position: center;
// 		background-color: #F1F1F1;
// 		border-radius: 100%;
// 	}
	 
// 	h3, a, i{
// 		font-family: 'Roboto', sans-serif;
// 		font-weight: lighter;
// 		-webkit-transition: .1s ease-in-out;
// 		transition: .1s ease-in-out;
// 	}
// 		h3{
// 			margin-top: 45px;
// 			margin-bottom: 5px;
// 			font-size: 18px;
// 			color: #212121;
// 		}
// 		a {
// 			display: block;
// 			padding: 5px 0px;
// 			font-size: 14px;
// 			color: #9E9E9E;
// 			text-decoration: none;
// 		}
// 			a:hover{
// 			background-color: #FAFAFA;
// 			color: inherit;
// 			}
// 	ul {
// 		margin-top: 10px;
// 		padding: 15px 0px;
// 		background-color: #FAFAFA;
// 	}
// 		ul a{
// 			display: inline;
// 			margin-right: 10px;
// 		}
// 		ul a i:hover{
// 			transform: scale(1.5);
// 			color: #2ab1ce;
// 			}
// }

.nowbotdarkmode {
	.panel-body {
	    background: black !important;
	}

}


.mini-card {
	float: left;
    width: 100%;
    margin-top:20px;
    margin-bottom:10px;
	background:#c3c3c3;
	img {
		max-width: 100%;
	}
}

.another-brick_stop {
  			.btn {
  				padding: 8px 12px;
  			}
 	.panel-body .edit-form .row{
  		//display:flex;
   		.col-md-4 {
  			//flex-basis: max-content;
   		
		}
	}
}