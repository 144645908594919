// Shopper Marketing Campaign Builder
// Fill in the fields Below to build your campaign

// Macro
// Micro


// Assign Status - In Planning
// Dates Start Date End Date
// Comments - Enter comments here

// Assign brands 

// Brand
// Media Type
// Allocating t
// Available
// Forecast

.supplier-portal-preview {
	.shuffle-preview {
		border:5px solid orange;
		&.squad-leader {
			border:5px red solid;
		}
	}
	overflow:hidden;
}

.shuffle-preview {
	display:bloc;
	min-width:1px;
	min-height:1px;
	display:block;
	img {
		max-height:100%;
	}
}

.shuffle-box {
	min-height:80px;

}
.supplier-portal-preview {
	 height: 100px;
  width: 100%;
  float: left;
  border: 1px dotted black;
  display: block;
  padding: 5px;
  box-sizing: border-box;
  margin-bottom:10px;
}
.drag-box-closed {
	.drag-box-closed-internals {
		display:none;
	}
}
.box-toggle-header {
	width: calc(100% - 30px);
  position: absolute;
  left: 30px;
  top: 0;
  height: 40px;
  background-color: var(--engine-panel-header-background) !important;

  h4 {
  	color: var(--engine-panel-header-color) !important;
  	margin-top: 7px;
  }
  a {
		right: 0px;
		  top: -2px;
		  position: absolute;
		  color: var(--engine-panel-header-color) !important;
		  margin-right: 0;
		  margin-top: 7px;
		  font-size: 20px;
		  width: 100%;
		  text-align: right;
		  cursor: pointer;
		  padding-right: 10px;
  }
}


@media (min-width: 992px) and (max-width: 1436px) {
	.col-md-3.available-block {
		width:50%;
	}
}

// test override 

.the-glue-of-society {
	> div {
		// float: left; // exemplary person
	}
	// padding: 60px 20px;
	> .row {
		padding: 60px 20px;
	}

	.maintenance {
	
		// ng-repeatactive
	
		tbody tr {
	
			// .table-striped > tbody > tr:nth-child(odd)
	
			&.keeping-us-together {
	
				// inhale grappa

			}
		}
	}
}

.shuffle-box {
  float: left;
  // background: #c7afaf; dulle redishpink
  background: #c3c3c3;
  padding: 10px 10px 10px 50px;
  margin-bottom:10px;
  position: relative;
  border-radius:16px;
  width:100%;
  position:relative;padding-right:70px !important;
  padding-bottom: 40px;
  &::before {
  	cursor: grab;
    font-family: 'Font Awesome 5 Pro';
    position: absolute;
    content:'\f074';
    display: block;
    height:100%;
    width:30px;
    background: #c3c3c3;
    left: 0;
    top: 0;
    border-right: 1px solid #888;
    padding: 37px 6px;
  }
  &:hover:before {
    background:white;
    color:#888;
  }
}
.shuffle-box .navigator-open .navigator {
	width:50px !important;
}

.form-section .form-items .form-group::after {
	visibility: hidden;
}
.btn.btn-submit, .btn.btn-primary {
    // background: #cc092f;
    // box-shadow: 0px -2px 0px transparent inset;
    // border: 1px solid #cc092fA6;
    // color: #fefefe;
    // border-radius: 0 !important;
}

.dashboard-item-editor {
	.btn.btn-submit.btn-mini.pull-left {
		width:100% !important;
	}
}

							.block-enabled {
								border:dashed green 3px !important;
							}
							.block-disabled {
								border:dashed red 3px !important;
							}

//Dashboard Sassy CSS file

#ui-container .pageType-campaign .another-brick_stop {
	.panel .panel-body {
		// padding:16px 0px 0px !important;
	}
}
.another-brick_stop {
    // margin-bottom: 30px;
	
}

.the-wall {
	width: 100%;
}
.row-holder, .item, .brick-col, .another-brick {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
.item {
	width: 100%;
}
.row-holder:before, .row-holder:after {
	display: table;
	content: " ";
	clear: both;
}
.brick-col {
	float: left;
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}
.another-brick {
	margin-bottom: 20px;
	.panel .panel-body {
		padding:16px 16px 0px !important;
	}
}
//  @media screen and (min-width: 2900px) {
// .brick-col {
// 	width: 8.3333%
// }
// }
//  @media screen and (max-width: 2900px) {
// .brick-col {
// 	width: 9.09%
// }
// .brick-col:nth-child(12) {
// 	display: none
// }
// }
//  @media screen and (max-width: 2600px) {
// .brick-col {
// 	width: 10%
// }
// .brick-col:nth-child(11) {
// 	display: none
// }
// }
//  @media screen and (max-width: 2300px) {
// .brick-col {
// 	width: 33.333%
// }
// .brick-col:nth-child(10) {
// 	display: none
// }
// }
//  @media screen and (max-width: 2000px) {
// .brick-col {
// 	width: 33.333%
// }
// .brick-col:nth-child(9) {
// 	display: none
// }
// }
 @media screen and (max-width: 2800px) {
.brick-col {
  // width: 33.33%
	width: 50%
}
.brick-col:nth-child(8) {
	display: none
}
}
 @media screen and (max-width: 1600px) {
.brick-col {
	width: 50%
}
.brick-col:nth-child(7) {
	display: none
}
}
 @media screen and (max-width: 1400px) {
.brick-col {
	width: 50%
}
.brick-col:nth-child(6) {
	display: none
}
}
 @media screen and (max-width: 1200px) {
.brick-col {
	width: 50%
}
.brick-col:nth-child(5) {
	display: none
}
}
 @media screen and (max-width: 960px) {
.brick-col {
	width: 50%;
}
.brick-col:nth-child(4) {
	display: none
}
}
 @media screen and (max-width: 800px) {
.brick-col {
	width: 50%;
}
.brick-col:nth-child(3) {
	display: none
}
}
 @media screen and (max-width: 480px) {
.brick-col {
	width: 100%;
}
.brick-col:nth-child(2) {
	display: none
}
}



.dashboard-option {
	background:$CORPORATE_WHITE;
	float:left;
	&.as-sortable-item{
	}
	.thumbnail {
		float:left;
		width:50%;
		// background:pink;
	}
	.label{

	}
}

.curate-dashboard {
	display: block;
	.sortable-row {
		width:100%;
		height:100px;
		background: white;
	    // border-top: 1px solid #332e2e;
	    // border-bottom: 1px solid #736f6f;
	}
	.as-sortable-item {
		width:100%;
		height:100px;
		// background:blue;

		.as-sortable-item-handle {
			padding:7px;
			i {
				color:green;
			}
			label {
				text-transform: uppercase;
			}
		}
	}
	.as-sortable-placeholder {
		background:purple !important;
		.label {
			color: pink !important;
		}
	}
	.as-sortable-item {
		width: 100%;
	    height: 100px;
	    padding: 10px;
	    border-bottom: 2px solid black;
	    overflow: hidden;
	    margin-bottom: 10px;
	    // border-radius: 8px 8px 6px 6px;
	    // border: 2px solid black;
      border-bottom: 1px solid #e4eae6;
	    // border-bottom: 3px solid black;
	}
	.as-sortable-dragging {
		background:blue !important;
		// transform: scale(2, .5);
		.as-sortable-item-handle .label {
			color:red;
		}
		border: 1px dotted #000 !important;
	}
	.as-sortable-item-handle {
		height: 50px;
		// background:orange;
		.label {
			color:green;
		}
		transition:all 1s;
	}

  .accordion dt > a {
    padding: 10px;
    display: block;
    float: left;
    /* color: red; */
    width: 100%;
    border-bottom: 1px solid #f1f6f2;
    color: #444;
  }

}

.dashboard-preview {
	.as-sortable-placeholder {
		position: relative;
		border:6px dashed green;
		background:purple !important;
		.label {
			color: pink !important;
		}
	}

	.as-sortable-item {
		max-width:200px;
		margin-bottom:10px;
			position: relative;
	}
	.as-sortable-item-handle {
		position: relative;
		img {
			max-width: 100%;
		}
		.label {
			position: absolute;
		    background: #ffffffe0;
		    left: 0;
		    bottom: 4px;
		    width: 100%;
		        display: block;
    background: darkgreen;
        z-index: 333;
    width:100%;
    position: absolute;
    top: 0;
        left: 0;
    height: 50px;
		}
	}

}

.dash-bubble {
    position: relative;
    display: block;
    height: 150px;
    //background: $CORPORATE_WHITE;
    margin: 0 auto;
    border-radius: 75px;
    width: 150px;
    i {
      position: absolute;
          font-size: 50px;
    top: 25px;
      left: 45px;
      color: var(--engine-action-button-background);
    }
    span {
      color: var(--engine-action-button-background);
	    max-width: 100px;
          // top: 80px;
	    // position: absolute;
	    // left: 10px;
	    // right: 10px;
	    text-align: center;

	    // top: 80px;
	    /* position: absolute; */
	    // left: 10px;
	    // right: 10px;
	    text-align: center;
	    display: block;
	    /* float: left; */
	    padding-top: 85px;
	    // background: red;
	        margin: 0 auto;

    }
    margin-bottom: 30px;
}

.dashboard-lede {
  text-align:center;width:100%;
    width: 80%;
    margin: 30px auto;
    font-size:16px;
  b {
    color:#6d6c68 !important;
  }
}

.dashboard-action {
  text-align: center;
  background:$CORPORATE_GREY_LIGHT;
  display: block;
  float: left;
  padding: 20px 30px;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 5px;
  // min-height: 150px;
  label {
    font-weight: 400;
    font-size:18px;
    color:$CORPORATE_BLACK;
    width: 100%;
    float:left;
    display:block;
  }
  .btn {
    margin-top:10px;
  }
}

// @media (min-width: 992px)
// .col-md-5 {
//     width: 41.66666667%;
// }

// @media (min-width: 992px){

// 	 .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, 
// 	 .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .
// 	 col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .
// 	 col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .
// 	 col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .
// 	 col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, 
// 	 .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, 
// 	 .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, 
// 	 .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .
// 	 col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
// 	    min-height: 1px;
// 	    padding-left: 5px;
// 	    padding-right: 5px;
// 	}

// }


    			.dashboard-widget.quick-options .panel-heading {
                    background: #00541c66 !important;
                    border-color: #00541c !important;
                }
                .dashboard-widget.quick-options .panel-heading h4 {
                    color:#4a4747 !important;
                }
                .dashboard-widget.quick-options .panel-heading .dash-bubble {
                    background:white !important;
                }
    			.dashboard-widget.quick-options .panel{
                    background:transparent !important;
                    -webkit-box-shadow: 0 1px 1px transparent !important;
                }
    			.dashboard-widget.quick-options .panel-body{
                    background:transparent !important;
                    padding:0 !important;
                }

		.dashboard-widget.quick-options {}
		.dashboard-widget.quick-options .panel{background:transparent !important;}
		.dashboard-widget.quick-options .panel-body{background:transparent !important;padding:0 !important;}








.ex-moved{
border:5px dashed red !important;
}
.ex-over{
border:5px dashed orange !important;

}
.ex-moved{
border:5px dashed green !important;

}
.ex-over{
border:5px dashed magenta !important;
}

.block-name {
  width: 100%;
	background: red;
	position: absolute;
	left: 0;
	bottom: 0;
	display:block;
	color: white;
}

/////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////

#ui-container .pageType-campaign .panel-info .panel-body {
  padding: 16px 16px 0px !important;
  // background: #cbc7c0 !important;
  border-radius: 0 0 0 0;
}


// #ui-container 
// .another-brick_stop {
//   .panel.panel-info {
//     background: transparent !important;
//     border: 0px solid transparent !important;
//     .panel-heading {
//       display: none;
//     }
//     .panel-body  {
//       padding: 0 !important;
//       background: transparent !important;
//     }
//   }
// }


.dashboard-preview .as-sortable-item {
  max-width: 200px;
  margin: 10px;
  position: relative;
  float: left;
}

// .dashboard-layout.pageType-campaign {
//   .panel > .panel-heading {
//     border-radius: 0;
//   }
//   .panel.panel-info {
//       margin-bottom:0;
//       border-radius: 0px 0px 0px 0px !important;
// 			border-bottom: 2px solid white !important;
//       position: relative;
//       &::before {
//       position: absolute;
//       width:80%;
//       left:10%;
//       height:30px;
//       background: #cbc7c0 !important;
//       content:''; 
//     }
//     .panel-body {
//       background: #cbc7c0 !important;
//       border-radius: 0 !important;

//     }
//   }
// }

.as-sortable-item {
  min-height: 50px;
  float: left;
  width:100%;
}
.dashboard-preview .as-sortable-item-handle img {
  max-width: 100%;
  max-height: 159px;
}
// .dashboard-preview.as-sortable-item,
.dashboard-preview .as-sortable-item {
	    width: calc(100% - 20px) !important;
  max-width: initial !important;
  float: left;
  // background: orange;
  min-height: 50px;
}
.dashboard-preview .as-sortable-item-body {
	   padding: 20px;
    padding-top: 70px;
}

.dashboard-preview  {
	.report-block {
		padding:20px;
	}
}
		p.report-name {
			text-align: left;
		}

.dashboard-preview .as-sortable-item-handle {
	width: 100%;
  position: relative;
  // background: blue;
  float: left;
   min-height: 50px;
  .label {
  	// background: #0064004f !important;
  	background: var(--engine-panel-header-background) !important;
  	h5,
  	a {
  		color: var(--engine-panel-header-color) !important;

  	}
  	p.report-name {
  		text-align: left;
  	}
  }
  .as-sortable-item-body {
  	float: left;
  	width:100%;
      padding-top: 60px;
    background: white;
  }
}
.gu-mirror.gu-unselectable {
  .dashboard-preview .as-sortable-item-handle {
    overflow: hidden;
    border:6px dashed gold;
    max-width: 200px;
    img {
      max-width: 100%;
    }
  }
}
.gu-mirror {
   .as-sortable-item-handle {
    overflow: hidden;
    border:6px dashed black;
    max-width: 200px;
    img {
      max-width: 100%;
    }
  }
  // background: red;
  // overflow: hidden;
  // max-width: 200px;
}

.btn-sword.for-dashboard-builder {
  margin:0px 0px 5px 0px !important; 
  background-size: contain !important;
  background-repeat: no-repeat !important;
  cursor: -webkit-grab !important;
}

.bg-custom {
  .form-section .form-items .form-group::after {
    display: none !important;
  }
  .form-group .input-group .form-control {
    border-radius: 0px !important;
  }
}

.preview-supplier-portal {
 border: 3px dotted #cbc7c0;
margin: 0 !important;
overflow: hidden;
background: #cbc7c052;
max-width: 100%;
min-width: 100%;
  float:left;
  .shuffle-preview {
    img {
      max-height:100px
    }
  }
}

.dashboard-item-editor {
	padding:30px;
	.form-section {
		background: transparent !important;
		.form-label {
			padding-left:0;
		}
	}
}


#ui-container .dashboard-layout {

	.col-md-6:first-child,
	.col-md-6:first-of-type {
	  padding-right: 5px !important;

	  padding-right: 5px;
	}
	.col-md-6:last-child,
	.col-md-6:last-of-type {
	  padding-left: 5px !important;

	  padding-left: 5px;
	}

	.col-md-6:first-child,
	.col-md-6:first-of-type {
	  padding-right: 5px !important;

	  padding-right: 5px;
	}
	.col-md-6:last-child,
	.col-md-6:last-of-type {
	  padding-left: 5px !important;

	  padding-left: 5px;
	}
	.dashboard-widget {
			.col-md-6:first-child,
			.col-md-6:first-of-type {
			  padding-right: 15px !important;

			  padding-right: 15px;
			}
			.col-md-6:last-child,
			.col-md-6:last-of-type {
			  padding-left: 15px !important;

			  padding-left: 15px;
			}

			.col-md-6:first-child,
			.col-md-6:first-of-type {
			  padding-right: 15px !important;

			  padding-right: 15px;
			}
			.col-md-6:last-child,
			.col-md-6:last-of-type {
			  padding-left: 15px !important;

			  padding-left: 15px;
			}
	}

}	
#ui-container .pageType-campaign .another-brick_stop .panel.panel-info {

	border-bottom: 0px solid transparent !important;
}
.modal-body table {
	margin-top: 20px;
}
.modal-body table th:first-of-type,
#ui-container .pageType-campaign .another-brick_stop .maintenance table th:first-of-type {
	padding-left:17px;
}

#ui-container .pageType-campaign .another-brick_stop.no_panel_style .panel.panel-info {
	background-color: transparent !important;
	border-bottom: 0px solid transparent !important;
	.panel-heading {
		display: none;
	}
	.panel-body {
		padding:0 !important;
		background:transparent !important;
		background-color:transparent !important;
		border-radius:0 !important;
	}
}

.dashboard-edit-navigator {
	position:absolute;
	background:#444;
	top:0;
	height:100%;
	right:0px;
	width:50px !important;
	overflow:hidden;
	.form-label {
		padding-left:0;
		width:100% !important;
	}
	.form-items {
		 width:100% !important;
	}
}

.sortable-row {
  background: #cbc7c0 !important;
  width: 100%;
  float: left;
  text-align:center;
  border: 3px dashed transparent;
}

.btn-sword.for-dashboard-builder {
		border: 3px solid #282828;
}
.btn-sword.for-dashboard-builder {
	// border: 3px dashed transparent;
	transition: border .5s ease-out;
	&:hover {
		border: 3px dashed #282828;
	}
}

.sortable-row {
		transition: border .5s ease-out;
}
.dashboard-tile-hover {
	.sortable-row {
		border: 3px dashed #282828;
	}
}

.supplier-portal-box-footer {
	position: absolute;
  bottom: 2px;
	background:white;padding:3px;
	border:1px solid black; width:100%;float:left;float: left;
	width: 93%;
  float: left;
  float: left;
  left: 32px;
	p {
		display:block;float:left;font-size:12px;margin-bottom: 0;
	}

	.prettycheckbox {
		a {
			float:left !important;
		}
		label {
			margin-top: 0;
		}
	}
}

.dashboard-edit-item-footer {
	background:white;padding:3px;
	border:1px solid black; width:100%;float:left;position: absolute;float: left;
	p {
		display:block;float:left;font-size:12px;margin-bottom: 0;
	}
}
.dashboard-preview {
	.dashboard-edit-item-footer {
		bottom: -63px;
	}
	.col-md-12 {
		.as-sortable-item {
			min-height: 50px;
  		margin-bottom: 40px;

		}
		.dashboard-edit-item-footer {
			height:30px; bottom: -39px;
		}
	}
	.col-md-6 {
		.as-sortable-item {
			min-height: 50px;
  		margin-bottom: 60px;
			
		}
		.dashboard-edit-item-footer {
			height:60px; bottom: -63px;
		}
	}
}


.dashboard-insight-block {
	overflow-y: hidden;
	> div {
    padding:0 !important;
    margin-top:0 !important;
	}
}